import React, { useEffect, useState } from "react";
import { FormLoadingOverlay, FormRow, PrimaryButton, SecondaryButton } from "../../../Components";
import Input, { DateInput, SearchDropDown, SectionHeading } from "../../../Components/controls/Input";
import { RiArrowLeftCircleLine, RiArrowRightCircleLine, RiCloseLine, RiInputField, RiUserSettingsLine } from "@remixicon/react";
import { REGEX } from "../../../Constants";
import useValidation, { ValidationConfig } from "../../../Hooks/useValidation";
import { handleChange } from "../../../Utils";
import { DealerInterface, IUser, IUserInput } from "../../../Constants/Interfaces";
import useAPI from "../../../Hooks/useAPI";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { from, useQuery } from "@apollo/client";
import Queries from "../../../GraphQl/Queries";

type Props = {};

interface DealerInputInterface {
    user: {
        userId: string,
        email?: string | null,
        mobile: string,
        name: string,
        roleId: number,
        dateCreated?: string,
        isDeleted?: boolean,
        isVerifiedEmail?: boolean,
        isVerifiedMobileNumber?: boolean,
        isUserDeleted?:boolean,
    };
    dealerId?: string;
    area?: string
    organizationName?: string
    gstin?: string
    isDealerActive?: boolean
    isDealerDeleted?: boolean
    dateCreated?: string

}

const initialForm: DealerInputInterface = {
    user: {
        userId: "",
        email: "",
        mobile: "",
        name: "",
        roleId: 4,
        isVerifiedEmail: false,
        isVerifiedMobileNumber: false,
        isUserDeleted:false,
    },
    dealerId: "",
    area: "",
    organizationName: "",
    gstin: "",
    isDealerActive: true,
    isDealerDeleted: false 
}
const DealerForm = (props: Props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form, setForm] = useState<DealerInputInterface>({ ...initialForm })
    const ValidationRules: ValidationConfig[] = [
        {
            input_path: "user.name",
            label_name: "User Name",
            input_required: true,
            regex: REGEX.Name.regex,
            error_message: "Enter valid User Name",
        },
        {
            input_path: "user.mobile",
            label_name: "Mobile No.",
            input_required: true,
            regex: REGEX.Mobile.regex,
            error_message: "Enter valid Mobile No.",
        },
        {
            input_path: "user.email",
            label_name: "Email Address",
            input_required: true,
            regex: REGEX.Email.regex,
            error_message: "Enter Valid Email Address",
        },
        {
            input_path: "user.roleId",
            label_name: "User Role",
            input_required: true,
            regex: REGEX.Integers.regex,
            error_message: "Select Valid User Role",
        },
        {
            input_path: "gstin",
            label_name: "Dealer GSTIN",
            input_required: false,
            regex: REGEX.GSTIN.regex,
            error_message: "Enter valid GSTIN",
        },
        {
            input_path: "organizationName",
            label_name: "Dealer organization name",
            input_required: false,
            regex: REGEX.Name.regex,
            error_message: "Enter valid organization name",
        },
        {
            input_path: "area",
            label_name: "Dealer Area",
            input_required: false,
            regex: REGEX.Name.regex,
            error_message: "Enter valid area",
        },
    ];
    //Roles Query 
    const Roles = useQuery(Queries.GET_ALL_ROLES)
    //User Query
    const getDealer = useAPI<{ data: DealerInterface, success: boolean, message: string }>({
        method: "GET",
        url: `/dealers/${id}`,
        onCompleted: ({ data, success, message }) => {
            if (!success) {
                toast.error(message)
            }else{
                setForm({
                    user: {
                        userId: data.user.id || "",
                        email:   data.user.email || "",
                        mobile:  data.user.mobile || "",
                        name:   data.user.name || "",
                        roleId: 4,
                        isVerifiedEmail:   data.user.isVerifiedEmail || false,
                        isVerifiedMobileNumber:   data.user.isVerifiedMobileNumber || false,
                    },
                    dealerId: String(data.id || ""),
                    area:  data.area,
                    organizationName:  data.organizationName,
                    gstin:  data.gstin,
                    isDealerActive:  data.isActive,
                    isDealerDeleted:  data.isDeleted
                })
            }
        },
        onError: (err) => {
            toast.error(err)
        }
    })
    //User Mutation
    const { execute, loading } = useAPI<{ success: boolean, message: string }>({
        method: id ? "PUT" :"POST",
        url: id ? `/dealers/${id}`: "/admin/dealers",
        data: form,
        onCompleted: (data) => {
            if (data.success) {
                toast.success("Dealer Created Successfully")
                navigate("/dealers");
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error("Failed to create new dealer");
            console.log("Add Dealer", err);
        }
    });
    const handleSelectRole = (value: any) => { }
    const handleResetForm = () => {
        setForm({ ...initialForm })
    }
    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (triggerValidation()) {
            execute()
            navigate('/dealers')
        }

    }
    const handleBackButton = () => {
        navigate(-1);
    };

    const { triggerValidation, validationMessages } =
        useValidation(form, ValidationRules);

    const onChange = handleChange(form, setForm);

    useEffect(() => {
        if (id) {
            getDealer.execute();
        }
    }, [])
    return (
        <form className="relative flex flex-col gap-3 p-8 rounded-xl bg-white w-11/12 mx-auto mt-14 sm:mt-20 lg:mt-24">
            <FormLoadingOverlay loading={loading} />
            <div className=" flex items-center space-x-2 ">
            <button onClick={handleBackButton} className=' hover:text-secondary'>
                <RiArrowLeftCircleLine />
            </button>
            <h3>{id? "Update Dealer": "Add Dealer"}</h3>
            </div>
            <SectionHeading heading="" />
            <FormRow gap={4}>
                <Input
                    name="user.name"
                    label="Name"
                    placeholder="Enter User's Name"
                    onChange={onChange("string")}
                    validation={validationMessages["name"]}
                    showRequired
                    inputSize="half"
                    value={form.user["name"] || ""}
                />
                <Input
                    name="user.mobile"
                    label="Mobile no."
                    placeholder="Enter Mobile no."
                    onChange={onChange()}
                    validation={validationMessages.mobile}
                    showRequired
                    inputSize="half"
                    value={form.user["mobile"]}
                />
                <Input
                    name="user.email"
                    label="Email"
                    placeholder="Enter Email Address"
                    onChange={onChange()}
                    validation={validationMessages.email}
                    inputSize="half"
                    value={form.user["email"] || ""}
                />
                <>
                    <Input
                        name="area"
                        label="Area"
                        placeholder="Enter Mobile no."
                        onChange={onChange()}
                        validation={validationMessages.area}
                        inputSize="half"
                        value={form["area"] || ""}
                    />
                    <Input
                        name="organizationName"
                        label="Organization Name"
                        placeholder="Enter Email Address"
                        onChange={onChange()}
                        validation={validationMessages.organizationName}
                        inputSize="half"
                        value={form["organizationName"] || ""}
                    />
                    <Input
                        name="gstin"
                        label="GSTIN"
                        placeholder="Enter Email Address"
                        onChange={onChange()}
                        validation={validationMessages.gstin}
                        inputSize="half"
                        value={form["gstin"] || ""}
                    />
                </>
                <SearchDropDown
                    Icon={<RiUserSettingsLine />}
                    label="Role"
                    name="user.roleId"
                    placeholder="Select Role"
                    inputSize="half"
                    renderResultItem={({ name }) => <>{name}</>}
                    dataList={Roles?.data?.getRoles?.roles || []}
                    searchCondition={(query: string) => (data: any) => {
                        return data.name.toLowerCase().includes(query.toLowerCase());
                    }}
                    callback={handleSelectRole}
                    validation={validationMessages["roleId"]}
                    valueField={"name"}
                    showRequired
                    value={Roles?.data?.getRoles?.roles?.find((role: any) => Number(form.user["roleId"]) === Number(role.id))?.name || ""}
                    defaultValue={Roles?.data?.getRoles?.roles?.find((role: any) => Number(form.user["roleId"]) === Number(role.id))?.name || ""}

                />
            </FormRow>
            <div className="flex justify-end items-center gap-4">
                <SecondaryButton icon={<RiCloseLine />} title="Reset" callback={handleResetForm} />
                <PrimaryButton
                    icon={<RiArrowRightCircleLine />}
                    title="Submit"
                    callback={handleSubmit}
                />
            </div>
        </form>
    );
};

export default DealerForm;
