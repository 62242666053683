import React from 'react'
import Skeleton from 'react-loading-skeleton';

type Props = {
    label: string;
    value: string;
    icon: React.ReactNode;
    color: string;
    loading?:boolean;

}


const DashCard = ({label,value,icon,color,loading=false}: Props) => {
  return (
    <div className='px-3 py-4 gap-4 flex items-center justify-start w-full bg-foreground rounded-2xl '>
        <span className={`p-3 rounded-xl text-lg`} style={{backgroundColor:color+"30",color:color}}>
            {icon}
        </span>
        <div className='w-full flex flex-col'>
            <span className='text-2xl w-full font-bold text-black'>{loading?<Skeleton width={"60%"} height={25}/>:value}</span>
            <span className='text-sm font-semibold text-disabled'>{label}</span>
        </div>
    </div>
  )
}
export default DashCard