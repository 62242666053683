
import React, { useEffect, useMemo, useState } from 'react'
import useAPI from '../../../Hooks/useAPI'
import { useNavigate, useParams } from 'react-router-dom'
import { DealerInterface, Organization, Page, PlanOrderInterface, PlansInterface, RewardTrackerInterface, subscriptionInterface } from '../../../Constants/Interfaces'
import { RiArrowLeftCircleLine, RiBuilding2Line, RiDeleteBin2Line, RiEdit2Line, RiEditLine, RiMailLine, RiMapLine, RiMapPinLine, RiPhoneLine, RiUserLine } from '@remixicon/react'
import { FilterBar, Pagination, SecondaryLinkButton, StatusTag } from '../../../Components'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { useTable } from '../../../Hooks'
import ActionButton from '../../../Components/ActionButton'
import moment from 'moment'

interface planOrderWithCouponCodeInterface extends PlanOrderInterface {
    couponCode: {
        code: string
    }
}

interface RewardWithPlanOrderAndCouponCodeIntercae extends RewardTrackerInterface {
    planOrder?: planOrderWithCouponCodeInterface,
}

const DealerDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { data, execute, loading } = useAPI<{ data: DealerInterface, success: boolean, message: string }>({
        method: "GET",
        url: `/dealers/${id}`,
        onCompleted: ({ data, success, message }) => {
            if (!success) {
                toast.error(message)
            }
        },
        onError: (err) => {
            toast.error(err)
        }
    })

    const rewardsTacker = useAPI<{ message: string, success: boolean, data: RewardWithPlanOrderAndCouponCodeIntercae[], totalRecords: number }>({
        method: "GET",
        url: `/rewards/dealer/${data?.data.id}`,
        onCompleted: ({ message, success, data, totalRecords }) => {
            if (success) {
                setPage({ ...page, totalRecords: totalRecords })
            } else {
                toast.error(message)
            }
        },
        onError: (err: any) => {
            toast.error(err)
        }
    })

    const columns = [
        {
            header: "ID",
            accessor: "id",
        },
        {
            header: "Plan Order Id",
            accessor: "orderId",
            render: ({ planOrder }: { planOrder: PlanOrderInterface }) => String(planOrder.orderId)
        },
        {
            header: "Reward",
            accessor: "reward",
        },
        {
            header: "Bonus",
            accessor: "bonus",
        },
        {
            header: "Coupon Code",
            accessor: "planOrder.couponCode.code",
            render: ({ planOrder }: { planOrder: planOrderWithCouponCodeInterface }) => String(planOrder.couponCode.code)
        },
        {
            header: "Amount",
            accessor: "planOrder.amount",
            render: ({ planOrder }: { planOrder: PlanOrderInterface }) => String(planOrder.amount || 0)
        },
    ];

    const { Table } = useTable({
        columns,
        onRowClick: (data) => {
            navigate(`/rewards/details/${data.id}/${id}`);
        },
    });

    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Page>({
        totalRecords: 0,
        skip: 0,
        take: 10,
    });

    const {
        area,
        organizationName,
    } = data?.data || {}

    const handleBackButton = () => {
        navigate(-1);
    };

    useEffect((() => {
        (async () => {
            await execute()
            await rewardsTacker.execute()
        })()
    }), [query, page.skip, page.take])


    const {
        name,
        email,
        mobile,
    } = data?.data.user || {}

    console.log(data)

    return (
        <div className=' w-11/12 mx-auto rounded-md flex flex-col space-y-5' >
            <div className='w-full grid grid-cols-1 lg:grid-cols-4  gap-5'>
                <div className=' w-full bg-foreground lg:col-span-2 rounded-md flex flex-col'>
                    <div className=' w-11/12 mx-auto mt-2'>
                        <button onClick={handleBackButton} className=' hover:text-secondary'>
                            <RiArrowLeftCircleLine />
                        </button>
                        {/* Org Name And Update Button */}
                        <div className=' grid grid-cols-2'>
                            <div className=' flex items-center space-x-2'>
                                <span className=' text-secondary'><RiUserLine /></span>
                                <span className='  sm:text-xl md:text-2xl'>{name}</span>
                            </div>
                            <div className=' flex justify-end'>
                                <SecondaryLinkButton
                                    to={`/dealers/update/${id}`}
                                    title="Edit"
                                    icon={<RiEdit2Line />}
                                />
                            </div>
                        </div>
                        {/* Org Contact Deatils */}
                        <div className=' flex flex-col gap-2 my-2'>
                            <a
                                href="mailto:"
                                className=" w-fit text-sm text-disabled hover:underline flex items-center gap-2"
                            >
                                <span className="text-secondary">
                                    <RiMailLine />
                                </span>
                                <span className="">
                                    {email || (
                                        <Skeleton
                                            width={"80%"}
                                            className="md:min-w-[8rem]"
                                            height={10}
                                        ></Skeleton>
                                    )}
                                </span>
                            </a>
                            <a
                                href="mailto:"
                                className="w-fit text-sm text-disabled hover:underline flex items-center gap-2"
                            >
                                <span className="text-secondary">
                                    <RiPhoneLine />
                                </span>
                                <span className="">
                                    {mobile || (
                                        <Skeleton
                                            width={"80%"}
                                            className="md:min-w-[8rem]"
                                            height={10}
                                        ></Skeleton>
                                    )}
                                </span>
                            </a>
                            {/* <div className=' flex flex-row items-center space-x-2'>
                                <span className="text-secondary"><RiMapPinLine /></span>
                                <span className=' text-disabled break-all'>
                                    {address?.addressLine + ',' + address?.city + ',' + address?.state + ',' + address?.pincode}
                                </span>
                            </div> */}

                        </div>
                    </div>
                </div>
                <div className=' bg-foreground text-[#000] rounded-md'>
                    Pending
                </div>
                <div className=' bg-foreground text-[#000] rounded-md'>
                    Pending
                </div>
            </div>
            <FilterBar
                exportHandler={() => { }}
                filter={<></>}
                placeholder="Search Order id"
                searchHandler={(e) => setQuery(e)}
                debounceTime={500}
                sortByList={[
                    { label: "Name", value: "name" },
                    { label: "Mobile", value: "brand" },
                    { label: "Email", value: "mrp" },
                    { label: "Verified", value: "mobileVerified" },
                ]}
                sortingHandler={({ name, order }) => {
                    //setSort({ name, orderby: order });
                }}
                resetPagination={() => {
                    //setPage({ ...page, skip: 0 })
                }}
                loading={loading}
                refetch={fetch}
            />
            <div className="w-full bg-foreground rounded-md">
                <Table rows={[]} loading={loading} />
            </div>
            <div className="bg-foreground w-full flex flex-wrap p-2 rounded-md">
                <Pagination page={page} onPageChange={setPage} />
            </div>
        </div>
    )
}
export default DealerDetails
