import { gql } from "@apollo/client";

const Queries = {
  GET_USERS: (fields: String) => gql`
    query GetUsers {
      getUsers {
        response {
          status
          message
          success
        }
        ${fields}
      }
    }
  `,
  GET_ALL_ROLES: gql`
    query GetRoles {
      getRoles {
        response {
          status
          message
          success
        }
        roles {
          id
          name
          isActive
          isDeleted
        }
      }
    }
  `,
  GET_UNITS: gql`
    query GetUnits {
      getUnits {
        response {
          status
          message
          success
        }
        units {
          id
          name
          shortHand
        }
      }
    }
  `,
  GET_INVOICE_NO: gql`
    query GetInvoice {
      getInvoice {
        response {
          status
          message
          success
        }
        invoice {
          id
          invoiceNumber
          isUsed
        }
      }
    }
  `,
  SEARCH_INVENTORY: (fields: String) => gql`
    query SearchInventory(
      
      $sort: sortinput
      $query: String
      $skip: Int
      $take: Int
    ) {
      searchInventory(
        
        sort: $sort
        query: $query
        skip: $skip
        take: $take
      ) {
        response {
          status
          message
          success
        }
        totalRecords
        ${fields}
      }
    }
  `,
  GET_INVENTORY_BY_ID: (fields: string) => gql`
    query GetInventoryById($inventoryId: ID!) {
      getInventoryById(inventoryId: $inventoryId) {
        response {
          status
          message
          success
        }
        ${fields}
        totalStock
      }
    }
  `,
  GET_SHOPS_GODOWNS: gql`
    query ShopsAndGodowns {
      getGodowns {
        response {
          status
          message
          success
        }
        godowns {
          id
          name
          address {
            addressLine
            city
          }
        }
      }
    }
  `,
  GET_BATCHWISE_STOCK_DISTRIBUTION: gql`
    query GetProductByBatch($batchId: ID!, $inventoryId: ID!) {
      getProductByBatch(batchId: $batchId, inventoryId: $inventoryId) {
        response {
          success
          message
          status
        }
        stockDistribution {
          batch {
            id
            name
            purchaseAmount
            saleAmount
            expiryDate
          }
          godownDistribution {
            godownId
            name
            maxStock
            quantity
          }
          shopDistribution {
            quantity
            maxStock
          }
        }
        inventory {
          id
          name
          unit {
            name
            shortHand
          }
        }
      }
    }
  `,
  SEARCH_PURCHASE: (fields: string) => gql`
    query SearchPurchase(
      
      $dateRange: dateRangeInput
      $query: String
      $skip: Int
      $take: Int
    ) {
      searchPurchase(
        
        dateRange: $dateRange
        query: $query
        skip: $skip
        take: $take
      ) {
        response {
          status
          message
          success
        }
        ${fields}
      }
    }
  `,
  SEARCH_VENDORS: (field: String) => gql`
    query SearchVendors( $query: String!, $skip: Int, $take: Int) {
      searchVendors( query: $query, skip: $skip, take: $take) {
        response {
          status
          message
          success
        }
        totalRecords
        ${field}
      }
    }
  `,
  GET_VENDOR_BY_ID: gql`
    query GetVendorById($vendorId: ID!) {
      getVendorById(vendorId: $vendorId) {
        response {
          status
          message
          success
        }
        vendor {
          id
          name
          mobile
          email
          vendorOrganizationName
          vendorOrganizationAddress {
            id
            addressLine
            state
            city
            pincode
          }
          vendorOrganizationMobile
          vendorOrganizationEmailAddress
          gstin
        }
      }
    }
  `,
  SEARCH_SALES: (fields: string) => gql`
    query SearchSales(
      
      $query: String
      $skip: Int
      $take: Int
      $dateRange: dateRangeInput
    ) {
      searchSales(
        
        query: $query
        skip: $skip
        take: $take
        dateRange: $dateRange
      ) {
        response {
          status
          message
          success
        }
        totalRecords
        ${fields}
      }
    }
  `,
  GET_SALES_BY_ID: (fields: string) => gql`
    query GetSaleById($saleId: ID!) {
      getSaleById(saleId: $saleId) {
        response {
          status
          message
          success
        }
        ${fields}
      }
    }
  `,
  GET_CUSTOMER_SALES: (field: string) => gql`
    query GetCustomerSales(
      
      $customerId: ID!
      $sort: sortinput
      $query: String
    ) {
      getCustomerSales(
        
        customerId: $customerId
        sort: $sort
        query: $query
      ) {
        response {
          status
          message
          success
        }
        ${field}
      }
    }
  `,
  SEARCH_DELETED_SALES: (fields: string) => gql`
    query SearchDeletedSales(
      
      $query: String
      $skip: Int
      $take: Int
      $dateRange: dateRangeInput
      $filter: ByIdFilter
    ) {
      searchDeletedSales(
        
        query: $query
        skip: $skip
        take: $take
        dateRange: $dateRange
        filter: $filter
      ) {
        response {
          message
          status
          success
        }
        totalRecords
        ${fields}
      }
    }
  `,
  GET_CUSTOMER_PAYMENTS: (field: string) => gql`
    query GetCustomerPayment(
      $customerId: ID!
      $query: String
      $sort: sortinput
      $skip: Int
      $take: Int
      $date: dateRangeInput
      $filter: paymentModeFilter
    ) {
      getCustomerPayment(
        customerId: $customerId
        query: $query
        sort: $sort
        skip: $skip
        take: $take
        date: $date
        filter: $filter
      ) {
        response {
          status
          message
          success
        }
        customersPayment {
          id
          paymentMode
          paidAmount
          balanceDue
          sales {
            id
            invoiceNumber {
              invoiceNumber
            }
          }
          paymentDate
        }
      }
    }
  `,
  SEARCH_CUSTOMERS: (fields: string) => gql`
    query SearchCustomers(
      
      $query: String!
      $skip: Int
      $take: Int
    ) {
      searchCustomers( query: $query, skip: $skip, take: $take) {
        response {
          status
          message
          success
        }
        totalRecords
        ${fields}
      }
    }
  `,
  GET_CUSTOMER_BY_ID: (field: string) => gql`
    query GetCustomerById($customerId: ID!) {
      getCustomerById(customerId: $customerId) {        
        ${field}
      }
    }
  `,
  GET_CUSTOMER_LEDGER: gql`
    query CustomerLedger(
      $customerId: ID!
      $paymentQuery: String
      $paymentSort: sortinput
      $skip: Int
      $take: Int
      $paymentFilter: filter
      $salesSort: sortinput
      $salesQuery: String
      $salesReturnFilter: SaleReturnFilter
      $salesfilter: dateRangeInputForFilter
    ) {
      getCustomerById(customerId: $customerId) {
        Balance
        customer {
          id
          name
          mobile
          email
          score
          gstin
          billAddress {
            addressLine
            city
            pincode
            state
          }
          dateCreated
          isDeleted
        }
        response {
          status
          message
          success
        }
        totalAdjustAmount
        totalBillAmount
        totalPaidAmount
        totalReturnedSaleAmount
      }
      getCustomerPayment(
        customerId: $customerId
        query: $paymentQuery
        sort: $paymentSort
        skip: $skip
        take: $take
        filter: $paymentFilter
      ) {
        customersPayment {
          id
          paymentMode
          paidAmount
          balanceDue
          sales {
            invoiceNumber {
              invoiceNumber
            }
          }
          paymentDate
          deletedSale {
            id
            invoiceNumber {
              invoiceNumber
            }
          }
          returnSale {
            id
            creditNoteNumber {
              creditNumber
            }
          }
        }
        response {
          status
          message
          success
        }
        totalRecord
      }
      getCustomerSales(
        customerId: $customerId
        sort: $salesSort
        query: $salesQuery
        filter: $salesfilter
      ) {
        response {
          status
          message
          success
        }
        sales {
          id
          invoiceNumber {
            invoiceNumber
          }
          amount
          amountPaid
          adjustedAmount
          isFullyPaid
          dateCreated
          saleReturnAmount
        }
        totalRecords
      }
      searchSaleReturn(filter: $salesReturnFilter) {
        response {
          message
          status
          success
        }
        saleReturns {
          id
          sale {
            id
            amount
            invoiceNumber {
              id
              invoiceNumber
            }
          }
          saleReturnItems {
            id
            price
            quantity
            product {
              id
              name
              batch {
                id
                expiryDate
                name
              }
            }
          }
          amount
          termsAndCondition
          remark
          isDeleted
          dateCreated
          additionalCharges {
            charges
            dateCreated
            name
          }
          totalRefundAmount
          creditNoteNumber {
            creditNumber
          }
        }
        totalRecords
      }
    }
  `,
  GET_VENDOR_PURCHASES: (field: string) => gql`
    query GetVendorPurchases(
      
      $vendorId: ID!
      $sort: sortinput
      $query: String
    ) {
      getVendorPurchases(
        
        vendorId: $vendorId
        sort: $sort
        query: $query
      ) {
        ${field}
        response {
          status
          message
          success
        }
        totalRecords
      }
    }
  `,
  GET_VENDOR_PAYMENTS: (field: string) => gql`
    query GetCustomerPayment(
      $customerId: ID!
      $query: String
      $sort: sortinput
      $skip: Int
      $take: Int
      $date: dateRangeInput
      $filter: paymentModeFilter
    ) {
      getCustomerPayment(
        customerId: $customerId
        query: $query
        sort: $sort
        skip: $skip
        take: $take
        date: $date
        filter: $filter
      ) {
        response {
          status
          message
          success
        }
        customersPayment {
          id
          paymentMode
          paidAmount
          balanceDue
          sales {
            id
            invoiceNumber {
              invoiceNumber
            }
          }
          paymentDate
        }
      }
    }
  `,
  GET_VENDOR_LEDGER: gql`
    query VendorLedger(
      $vendorId: ID!
      $paymentQuery: String
      $paymentSort: sortinput
      $skip: Int
      $take: Int
      $filter: filter
      $purchaseSort: sortinput
      $purchaseQuery: String
      $query: String
      $purchaseReturnFilter: PurchaseReturnFilter
    ) {
      getVendorPayment(
        vendorId: $vendorId
        query: $paymentQuery
        sort: $paymentSort
        skip: $skip
        take: $take
        filter: $filter
      ) {
        response {
          message
          status
          success
        }
        totalRecord
        vendorsPayment {
          id
          balanceDue
          paidAmount
          paymentDate
          paymentMode
          purchase {
            id
          }
          deletedPurchase {
            id
          }
          returnPurchase {
            id
            purchaseReturnNumber {
              purchaseReturnNumber
            }
          }
        }
      }
      getVendorPurchases(
        vendorId: $vendorId
        sort: $purchaseSort
        query: $purchaseQuery
      ) {
        purchases {
          adjustedAmount
          amount
          amountPaid
          dateCreated
          id
          isFullyPaid
          purchaseReturnAmount
        }
        response {
          status
          message
          success
        }
        totalRecords
      }
      getVendorById(vendorId: $vendorId) {
        Balance
        response {
          message
          status
          success
        }
        totalAdjustAmount
        totalBillAmount
        totalPaidAmount
        totalReturnedPurchaseAmount
        vendor {
          name
          email
          mobile
          gstin
          vendorOrganizationAddress {
            addressLine
            city
            pincode
            state
          }
        }
      }
      searchPurchaseReturn(query: $query, filter: $purchaseReturnFilter) {
        response {
          status
          message
          success
        }
        totalRecords
        purchaseReturns {
          id
          amount
          dateCreated
          purchase {
            id
            amount
          }
          purchaseReturnNumber {
            purchaseReturnNumber
          }
          remark
          totalRefundAmount
        }
      }
    }
  `,
  GET_STOCK_HISTORY: gql`
    query GetStocksHistory($filter: stocksHistoryFilter) {
      getStocksHistory(filter: $filter) {
        response {
          status
          message
          success
        }
        stocks {
          name
          quantity
          dateCreated
          invoiceNo {
            id
            invoiceNumber
          }
          inventory {
            name
            unit {
              name
              shortHand
            }
          }
          purchaseId
        }
        totalRecord
      }
    }
  `,
  SEARCH_DISCARDED_STOCK: (fields: string) => gql`
    query SearchDiscardStocks(
      
      $query: String
      $sort: sortinput
      $filter: DiscardProductFilter
    ) {
      searchDiscardedStocks(
        
        query: $query
        sort: $sort
        filter: $filter
      ) {
        response {
          message
          status
          success
        }
        ${fields}
        totalRecords
      }
    }
  `,
  GET_DISCARDED_STOCK: (fields: string) => gql`
    query GetDiscardedStockSById($discardedStockId: ID!) {
      getDiscardedStockSById(discardedStockId: $discardedStockId) {
        response {
          message
          status
          success
        }
        ${fields}
      }
    }
  `,
  GET_DEAD_STOCK: gql`
    query GetDeadStocks($days: Int, $expireIn: Int) {
      getDeadStocks(days: $days, expireIn: $expireIn) {
        response {
          message
          status
          success
        }
        deadStocks {
          inventory {
            id
            name
            brand
          }
          batch {
            id
            name
            expiryDate
          }
          quantity
          lastSold
        }
      }
    }
  `,
  SEARCH_STOCK_MOVEMENT: (field: string) => gql`
    query SearchStockMovement(
      
      $query: String
      $sort: sortinput
      $filter: DiscardProductFilter
    ) {
      searchStockMovement(
        
        query: $query
        sort: $sort
        filter: $filter
      ) {
        response {
          status
          message
          success
        }
        ${field}
        totalRecords
      }
    }
  `,
  GET_STOCK_MOVEMENT: (field: string) => gql`
    query GetStockMovementById($stockMovementId: ID!) {
      getStockMovementById(stockMovementId: $stockMovementId) {
        response {
          message
          status
          success
        }
        ${field}
      }
    }
  `,
  GET_EXPENSE_CATEGORIES: gql`
    query GetExpensecategories {
      getExpensecategories {
        categories {
          id
          category
          isActive
          isDeleted
        }
      }
    }
  `,
  SEARCH_EXPENSES: (field: string) => gql`
    query Query( $filter: ExpenseFilter, $query: String) {
      getTotalExpense {
        totalExpense
      }
      searchExpense( filter: $filter, query: $query) {
        response {
          status
          success
          message
        }
        ${field}
        totalrecord
      }
    }
  `,
  GET_EXPENSE_BY_ID: (field: string) => gql`
    query GetExpenseById($expenseId: ID!) {
      getExpenseById(expenseId: $expenseId) {
        response {
          status
          message
          success
        }
        ${field}
      }
    }
  `,
  SEARCH_SALE_RETURN: (field: string) => gql`
    query SearchSaleReturn(
      
      $sort: sortinput
      $query: String
      $skip: Int
      $take: Int
      $filter: SaleReturnFilter
    ) {
      searchSaleReturn(
        
        sort: $sort
        query: $query
        skip: $skip
        take: $take
        filter: $filter
      ) {
        response {
          message
          status
          success
        }
        ${field}
        totalRecords
      }
    }
  `,
  GET_SALE_RETURN_BY_ID: (field: string) => gql`
    query GetSaleReturnById($returnedSaleId: ID!) {
      getSaleReturnById(returnedSaleId: $returnedSaleId) {
        response {
          status
          message
          success
        }
        ${field}
      }
    }
  `,
  GET_SALE_N_REFUND_BY_ID: gql`
    query getSaleNRefundById($saleId: ID!, $filter: SaleReturnFilter) {
      getSaleById(saleId: $saleId) {
        response {
          message
          status
          success
        }
        sale {
          additionalCharges {
            charges
            name
          }
          adjustAmount
          amount
          billPaidDate
          customer {
            name
            billAddress {
              addressLine
              city
              state
              pincode
            }
          }
          dateCreated
          id
          invoiceNumber {
            invoiceNumber
          }
          paidAmount
          saleItems {
            id
            batch {
              id
              name
            }
            product {
              id
              name
              hsn
              unit {
                shortHand
              }
            }
            inventoryUnit {
              id
              conversionMultiple
              unit {
                id
                shortHand
              }
            }
            quantity
            saleDiscount
            salePrice
            saleTax
          }
        }
      }
      searchSaleReturn(filter: $filter) {
        response {
          message
          status
          success
        }
        saleReturns {
          id
          saleReturnItems {
            batch {
              id
              name
            }
            id
            product {
              id
              name
            }
            quantity
          }
        }
        totalRecords
      }
    }
  `,
  GET_CREDIT_NOTE_NUMBER: gql`
    query GetCreditNoteNumber {
      getCreditNoteNumber {
        creditNoteNumber {
          creditNumber
          id
          isUsed
        }
        response {
          status
          message
          success
        }
      }
    }
  `,
  GET_PURCHASE_RETURN_NUMBER: gql`
    query GetPurchaseReturnNumber {
      getPurchaseReturnNumber {
        response {
          message
          status
          success
        }
        purchaseReturnNumber {
          id
          purchaseReturnNumber
        }
      }
    }
  `,
  GET_PURCHASE_RETURN_BY_ID: (field: String) => gql`
    query PurchaseReturnById($returnedPurchaseId: ID!) {
      purchaseReturnById(returnedPurchaseId: $returnedPurchaseId) {
        response {
          message
          status
          success
        }
        ${field}
      }
    }
  `,
  SEARCH_PURCHASE_RETURN: (field: string) => gql`
    query SearchPurchaseReturn(
      
      $query: String
      $sort: sortinput
      $skip: Int
      $take: Int
      $filter: PurchaseReturnFilter
    ) {
      searchPurchaseReturn(
        
        query: $query
        sort: $sort
        skip: $skip
        take: $take
        filter: $filter
      ) {
        response {
          message
          status
          success
        }
        ${field}
        totalRecords
      }
    }
  `,
  GET_PURCHASE_N_REFUND_BY_ID: gql`
    query Query($purchaseId: ID!, $filter: PurchaseReturnFilter) {
      getPurchaseById(purchaseId: $purchaseId) {
        response {
          status
          message
          success
        }
        purchase {
          id
          adjustAmount
          paidAmount
          dateCreated
          amount
          billAddress {
            id
            addressLine
            state
            city
            pincode
          }
          purchaseItems {
            batch {
              id
              name
            }
            id
            product {
              id
              name
              unit {
                shortHand
              }
            }
            inventoryUnit {
              id
              conversionMultiple
              unit {
                id
                shortHand
              }
            }
            purchasePrice
            purchaseTax
            quantity
          }
          shippingAddress {
            id
            addressLine
            state
            city
            pincode
          }
          user {
            name
            id
          }
          vendor {
            id
            name
            vendorOrganizationAddress {
              addressLine
              city
              state
              pincode
            }
          }
        }
      }
      searchPurchaseReturn(filter: $filter) {
        response {
          status
          message
          success
        }
        totalRecords
        purchaseReturns {
          id
          amount
          additionalCharges {
            name
            charges
          }
          remark
          purchaseReturnItems {
            id
            product {
              id
              name
            }
            batch {
              id
              name
            }
            price
            quantity
            tax
          }
          purchase {
            id
            amount
            adjustAmount
          }
        }
      }
    }
  `,
  GET_PURCHASE_BY_ID: (field: string) => gql`
    query getPurchase($purchaseId: ID!) {
      getPurchaseById(purchaseId: $purchaseId) {
        response {
          status
          message
          success
        }
        ${field}
      }
    }
  `,
  GET_LOW_ON_STOCKS_ITEMS: gql`
    query GetLowStockProducts {
      getLowStockProducts {
        response {
          message
          status
          success
        }
        lowStockProducts {
          inventory {
            id
            brand
            name
            unit {
              shortHand
            }
            reorderPoint
          }
          totalQuantity
        }
      }
    }
  `,
  GET_ITEMS_RANKING_BY_VOLUME: gql`
    query GetTopAndLeastSellingProduct {
      getTopAndLeastSellingProduct {
        response {
          message
          status
          success
        }
        topSoldProducts {
          product {
            id
            name
            brand
            unit {
              shortHand
            }
          }
          soldQuantity
        }
      }
    }
  `,
  GET_ITEMS_RANKING_BY_PROFIT: gql`
    query GetTopAndLeastProfitableProduct {
      getTopAndLeastProfitableProduct {
        response {
          message
          status
          success
        }
        topProfitableProducts {
          product {
            id
            name
            brand
            unit {
              id
              shortHand
            }
          }
          profit
        }
      }
      getTopAndLeastSellingProduct {
        response {
          message
          status
          success
        }
        topSoldProducts {
          product {
            id
            name
          }
          soldQuantity
        }
      }
    }
  `,
  GET_TRANSACTIONS: gql`
    query GetTransactions($filter: filterForTransactions, $query: String) {
      searchTransactions(filter: $filter, query: $query) {
        response {
          status
          message
          success
        }
        transactions {
          id
          customer {
            id
            name
          }
          vendor {
            id
            name
          }
          sales {
            id
            invoiceNumber {
              invoiceNumber
            }
          }
          returnSale {
            id
            creditNoteNumber {
              creditNumber
            }
          }
          deletedSale {
            id
            invoiceNumber {
              invoiceNumber
            }
          }
          purchase {
            id
          }
          returnPurchase {
            id
            purchaseReturnNumber {
              purchaseReturnNumber
            }
          }
          deletedPurchase {
            id
          }

          particular
          chequeNo
          type
          amountPaid
          paymentMode
          date
        }
      }
    }
  `,
  GET_SALE_PURCHASE_HISTORY: gql`
    query GetSaleAndPurchaseHistory($filter: dateRangeInput) {
      getSaleAndPurchaseHistory(filter: $filter) {
        response {
          message
          status
          success
        }
        sales {
          name
          data {
            x
            y
          }
        }
        purchases {
          name
          data {
            x
            y
          }
        }
      }
    }
  `,
  GET_CATEGORY_WISE_EXPENSE: gql`
    query GetExpenseCategoriesWise($filter: dateRangeInputForFilter) {
      getExpenseCategoriesWise(filter: $filter) {
        response {
          message
          status
          success
        }
        expenseSummary {
          category
          categoryId
          expense
        }
      }
    }
  `,
  GET_DASHBOARD_DATA: gql`
    query DashboardData($filter: dateRangeInputForFilter) {
      getExpenseCategoriesWise(filter: $filter) {
        response {
          message
          status
          success
        }
        expenseSummary {
          expense
          categoryId
          category
        }
      }
      getNetAndGrossProfitMargin {
        response {
          message
          status
          success
        }
        grossProfitMargin
        netProfitMargin
      }
      getRevenue {
        revenue
      }
      getReceivableAmount {
        receivable
      }
      getPayableAmount {
        payable
      }
      getTotalExpense {
        totalExpense
      }
      getCostOfGoodSold {
        costOfGood
      }
      getSaleAndPurchaseHistory(filter: $filter) {
        response {
          status
          message
          success
        }
        sales {
          name
          data {
            x
            y
          }
        }
        purchases {
          name
          data {
            x
            y
          }
        }
      }
    }
  `,
  GET_CASHFLOW_REPORT: gql`
    query CashFlowReport(
      $Transactionfilter: filterForTransactions
      $query: String
      $searchExpenseFilter: ExpenseFilter
    ) {
      searchTransactions(filter: $Transactionfilter, query: $query) {
        response {
          status
          message
          success
        }
        transactions {
          id
          customer {
            id
            name
          }
          vendor {
            id
            name
          }
          sales {
            id
            invoiceNumber {
              invoiceNumber
            }
          }
          returnSale {
            id
            creditNoteNumber {
              creditNumber
            }
          }
          deletedSale {
            id
            invoiceNumber {
              invoiceNumber
            }
          }
          purchase {
            id
          }
          returnPurchase {
            id
            purchaseReturnNumber {
              purchaseReturnNumber
            }
          }
          deletedPurchase {
            id
          }

          particular
          chequeNo
          type
          amountPaid
          paymentMode
          date
        }
      }
      searchExpense(filter: $searchExpenseFilter) {
        response {
          status
          message
          success
        }
        expenses {
          id
          amount
          category {
            category
          }
          addedBy {
            name
          }
          description
          paymentMode
          expenseDate
          dateCreated
        }
        totalrecord
      }
    }
  `,
  GET_DAILY_REPORT: gql`
    query DailyReport(
      $expenseFilter: ExpenseFilter
      $dateRange: dateRangeInput
      $stockFilter: dateRangeInputForFilter
      $SaleReturnfilter: SaleReturnFilter
      $PurchaseReturnfilter: PurchaseReturnFilter
    ) {
      searchExpense(filter: $expenseFilter) {
        expenses {
          amount
          category {
            category
          }
          dateCreated
          description
          paymentMode
          expenseDate
        }
      }
      getDailyStockReport(filter: $stockFilter) {
        response {
          status
          message
          success
        }
        stockReport {
          inventory {
            id
            name
            brand
            reorderPoint
            unit {
              shortHand
            }
          }
          stockLeft
          soldQuantity
          purchasedQuantity
          discardedquantity
        }
      }
      searchSales(dateRange: $dateRange) {
        response {
          message
          status
          success
        }
        totalRecords
        sales {
          id
          invoiceNumber {
            invoiceNumber
          }
          amount
          customer {
            name
            id
          }
          isFullyPaid
          adjustAmount
          paidAmount
          dueDate
        }
      }
      searchPurchase(dateRange: $dateRange) {
        response {
          message
          status
          success
        }
        totalRecords
        purchases {
          id
          amount
          adjustAmount
          paidAmount
          vendor {
            name
            id
          }
          isFullyPaid
          dueDate
        }
      }
      searchSaleReturn(filter: $SaleReturnfilter) {
        response {
          status
          message
          success
        }
        saleReturns {
          dateCreated
          amount
          creditNoteNumber {
            creditNumber
          }
          id
          remark
          totalRefundAmount
          sale {
            invoiceNumber {
              invoiceNumber
            }
            id
            amount
          }
        }
        totalRecords
      }
      searchPurchaseReturn(filter: $PurchaseReturnfilter) {
        response {
          status
          message
          success
        }
        purchaseReturns {
          id
          amount
          dateCreated
          purchase {
            id
            amount
          }
          purchaseReturnNumber {
            purchaseReturnNumber
          }
          totalRefundAmount
          remark
        }
        totalRecords
      }
    }
  `,
  GET_STOCK_REPORT: gql`
    query StockReport(
      $dailyStockFilter: dateRangeInputForFilter
      $query: String
      $sort: sortinput
      $movementfilter: DiscardProductFilter
    ) {
      getDailyStockReport(filter: $dailyStockFilter) {
        response {
          message
          status
          success
        }
        stockReport {
          inventory {
            id
            name
            brand
            unit {
              id
              name
              shortHand
              isActive
              isDeleted
            }
          }
          soldQuantity
          purchasedQuantity
          discardedquantity
          stockLeft
        }
      }
      getTopAndLeastSellingProduct {
        response {
          message
          status
          success
        }
        topSoldProducts {
          product {
            id
            name
            brand
            unit {
              shortHand
            }
          }
          soldQuantity
        }
      }
      getLowStockProducts {
        response {
          message
          status
          success
        }
        lowStockProducts {
          inventory {
            id
            brand
            name
            unit {
              shortHand
            }
          }

          totalQuantity
        }
      }
      getTopAndLeastProfitableProduct {
        response {
          message
          status
          success
        }
        topProfitableProducts {
          product {
            id
            name
            brand
            unit {
              id
              shortHand
            }
          }
          profit
        }
      }
      searchStockMovement(query: $query, sort: $sort, filter: $movementfilter) {
        response {
          status
          message
          success
        }
        StocksMovement {
          id
          assignedBy {
            name
          }
          assignedTo {
            user {
              name
            }
          }
          godown {
            id
            name
            dateCreated
            isDeleted
          }
          shop {
            name
          }
          product {
            batch {
              name
            }
            inventory {
              name
              unit {
                shortHand
              }
            }
          }

          quantity
          remark
          dateCreated
        }
        totalRecords
      }
    }
  `,
  GET_CUSTOMER_ANALYSIS: gql`
    query CustomerAnalysis {
      getHighValueCustomer {
        response {
          message
          status
          success
        }
        highValueCustomers {
          aov
          customerName
          customerMobile
          customerEmail
          customerId
        }
      }
      getTopCustomerBySale {
        response {
          message
          status
          success
        }
        topCustomerBySale {
          customerId
          customerName
          customerEmail
          customerMobile
          saleAmount
        }
      }
      getMostLoyalCustomer {
        response {
          message
          status
          success
        }
        mostLoyalCustomer {
          customerEmail
          customerId
          customerMobile
          customerName
          dueAmount
        }
      }
    }
  `,
  GET_COST_OF_INVENTORY: gql`
    query GetCostOfInventory {
      getCostOfInventory {
        response {
          message
          status
          success
        }
        costOfInventories {
          cost
          inventory {
            id
            name
            brand
          }
          stockAvailable
        }
      }
    }
  `,
  SEARCH_EMPLOYEE: gql`
    query SearchEmployees($query: String!, $skip: Int, $take: Int) {
      searchEmployees(query: $query, skip: $skip, take: $take) {
        response {
          status
          message
          success
        }
        totalRecords
        employees {
          id
          user {
            id
            name
            mobile
            isVerifiedMobileNumber
            role {
              name
            }
          }
          dateCreated
          score
          isActive
          isDeleted
        }
      }
    }
  `,
  GET_ALL_PLANS: gql`
    query GetPlans {
      getPlans {
        response {
          message
          status
          success
        }
        plans {
          description
          features
          id
          isVisible
          name
          duration
          isAddon
          planDetails
          price
        }
      }
    }
  `,
  GET_ORGANIZATION_SUBSCRIPTION: gql`
    query GetOrganizationActiveSubscription {
      getOrganizationActiveSubscription {
        response {
          message
          status
          success
        }
        subscription {
          id
          addOn
          dateCreated
          id
          isActive
          plan {
            description
            duration
            features
            id
            isAddon
            isVisible
            name
            planDetails
            price
          }
          planOrder {
            amount
            id
            isPaid
            orderId
            receipt
            tax
            dateCreated
          }
          validity
        }
      }
    }
  `,
  GET_PROFIT_MARGIN: gql`
    query GetNetAndGrossProfitMargin {
      getNetAndGrossProfitMargin {
        response {
          message
          status
          success
        }
        grossProfitMargin
        netProfitMargin
      }
    }
  `,
  GET_ALL_MODULE_PRIVILEGES: gql`
    query GetModulePrivilege {
      getModulePrivilege {
        response {
          message
          status
          success
        }
        modulePrivileges {
          name
          module
          privilege {
            id
            name
            isActive
            route
            module
            isSuperPrivillage
          }
          route
        }
      }
    }
  `,
  GET_EMPLOYEE_DETAILS: gql`
    query GetEmployeeById($employeeId: ID!) {
      getEmployeeById(employeeId: $employeeId) {
        response {
          message
          status
          success
        }
        employee {
          id
          user {
            name
            mobile
            isVerifiedMobileNumber
            isVerifiedEmail
            email
            id
            dateCreated
            role {
              name
            }
          }
          dateCreated
          score
          isActive
          isDeleted
        }
      }
    }
  `,
  SEARCH_DELETED_PURCHASE: (fields: string) => gql`
    query SearchDeletedPurchases(
      $query: String
      $skip: Int
      $take: Int
      $dateRange: dateRangeInput
      $filter: ByIdFilter
    ) {
      searchDeletedPurchases(
        query: $query
        skip: $skip
        take: $take
        dateRange: $dateRange
        filter: $filter
      ) {
        response {
          status
          message
          success
        }
        totalRecords
        ${fields}
      }
    }
  `,
};

export default Queries;
