import { RiCloseLine } from "@remixicon/react";
import React, { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
interface ModalProps {
  showModal: boolean;
  setShowModal: (value:boolean)=>void;
  modalHeading: string;
  modalDescription?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  scrollable?: boolean;
  size?: "large"|"medium"|"small"
}

const ModalSize = {
  large: "w-11/12 md:w-10/12 lg:w-8/12",
  medium: "w-11/12 md:w-8/12 lg:w-6/12",
  small: "w-11/12 md:w-7/12 lg:w-4/12"
}
const Modal: React.FC<ModalProps> = ({
  showModal = false,
  setShowModal = (value: boolean) => null,
  modalHeading,
  modalDescription,
  size="large",
  scrollable= true,
  children,
  footer
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(modalRef, (e) => {
    setShowModal(false);
  });
  function closeHandler(e:React.MouseEvent<HTMLButtonElement>){
    e.preventDefault();
    setShowModal(false);
  }
  return (
    showModal?<div
      className={`fixed inset-0 w-full rounded shadow-md bg-[#23232350] flex items-center z-50`}
    >
      <div
        ref={modalRef}
        className={`${ModalSize[size]} rounded-lg mx-auto min-h-fit bg-white flex flex-col items-center gap-5 py-6 pb-0 `}
      >
        {/*Modal Header */}
        <div className="w-full flex items-start px-6">
          {/*Heading */}
          <div className="w-full flex flex-col items-start justify-start">
            <h1 className="text-2xl sm:text-3xl text-start">{modalHeading}</h1>
            <p className="text-base w-full text-start text-disabled ">
              {modalDescription}
            </p>
          </div>
          {/*Close Modal Button */}
          <button onClick={closeHandler} className="text-lg hover:text-red-400"><RiCloseLine/></button>
        </div>
        {/*Modal Body */}
        <div className={`w-full ${scrollable?"max-h-[65vh] overflow-y-scroll scrollBar":"min-h-fit"}`}>
        {children}
        </div>
        {/*Footer */}
        {footer&&<div className="w-full flex justify-end gap-2">
          {footer}
        </div>}
      </div>
    </div>:<></>
  );
};

export default Modal;
