import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IUser, IUserInput, Organization, PlansInterface, subscriptionInterface } from '../../../Constants/Interfaces'
import useAPI from '../../../Hooks/useAPI'
import { toast } from 'react-toastify'
import { FormLoadingOverlay, PrimaryButton, SecondaryButton } from '../../../Components'
import Input, { DateInput, FormRow, RadioButton, SearchDropDown, SectionHeading, TextArea } from '../../../Components/controls/Input'
import { RiAddLine, RiArrowLeftCircleLine, RiArrowRightCircleLine, RiCloseLine, RiUserLine, RiUserSettingsLine, RiVipDiamondLine } from '@remixicon/react'
import useValidation, { ValidationConfig } from '../../../Hooks/useValidation'
import { REGEX } from '../../../Constants'
import { handleChange } from '../../../Utils'
import { from, useQuery } from '@apollo/client'
import { Calendar } from 'rsuite'
import { Queries } from '../../../GraphQl/Index'


interface SubscriptionInput {
    subscriptionId?: string,
    orgId: string,
    startDate: string,
    endDate?: string,
    isActive?: boolean,
    subscriptionPlanId?: string,
    addOnPlans?: string[]
}

const initialForm: SubscriptionInput = {
    subscriptionId: "",
    orgId: "",
    startDate: "",
    endDate: "",
    isActive: false,
    subscriptionPlanId: "",
    addOnPlans: []
}

interface subscriptionWithOrgInterface extends subscriptionInterface {
    organization: {
        id: string
    },
    subscriptionAddOn: {
        plan: PlansInterface,
        // validity: string,
        // dateCreated: string
    }[]
}

export interface OrganizationResponse {
    message: string;
    success: boolean;
    data: Organization[];
    totalRecords: number;
}

const SubscriptionFrom = () => {
    const { id } = useParams()

    const navigate = useNavigate()
    const [form, setForm] = useState<SubscriptionInput>({ ...initialForm })

    const subcriptionDetails = useAPI<{ data: subscriptionWithOrgInterface, success: boolean, message: string }>({
        method: 'GET',
        url: `admin/subscriptions/${id}`,
        data: from,
        onCompleted: ({ data, success, message }) => {
            if (success) {
                // toast.success(message)
                const addOnPlans: string[] = []
                for (const addon of data.subscriptionAddOn) {
                    addOnPlans.push(String(addon.plan.id))
                }
                setForm({
                    subscriptionId: String(data.id),
                    orgId: data.organization.id,
                    startDate: data.startDate,
                    endDate: data.validity,
                    isActive: data.isActive,
                    subscriptionPlanId: String(data.plan?.id) || "",
                    addOnPlans: [...addOnPlans]
                })
            } else {
                toast.error(message)
            }
        },
        onError: (err) => {
            toast.error(err)
        }
    })

    const addSubscription = useAPI<{ success: boolean, message: string }>({
        method: 'POST',
        url: `admin/subscriptions`,
        data: form,
        onCompleted: (data) => {
            if (data.success) {
                toast.success(`${data.message}`)
                navigate("/subscriptions");
            } else {
                toast.error(`${data.message}`)
            }
        },
        onError: (err) => {
            toast.error(`${err}`)
        }
    })

    const getOrganizations = useAPI<OrganizationResponse>({
        method: "GET",
        url: "/admin/organizations",
        onCompleted: ({ data, success, message }) => {
            if (success) {
                // toast.success(message)
            } else {
                toast.error(message)
            }
        },
        onError: (err) => {
            toast.error(err)
        }
    });

    const Plans = useQuery(Queries.GET_ALL_PLANS)

    const validationRules: ValidationConfig[] = [
        {
            input_path: "orgId",
            label_name: "Organization",
            error_message: "Select valid Organization",
            input_required: true,
            regex: REGEX.ANYTHING.regex,
        },
        {
            input_path: "startDate",
            label_name: "Start Date",
            error_message: "Enter Valid Start Date",
            input_required: false,
            regex: REGEX.ANYTHING.regex,
        },
        {
            input_path: "subscriptionPlanId",
            label_name: "Subscription",
            error_message: "Enter Valid Subscription Plan",
            input_required: false,
            regex: REGEX.ANYTHING.regex,
        },
        {
            input_path: "endDate",
            label_name: "End Date",
            error_message: "Enter Valid End Date",
            input_required: false,
            regex: REGEX.ANYTHING.regex,
        },
    ]

    const handleSelectSubPlan = (index: number, value: string) => {
        const plans = [...form?.addOnPlans || ""]
        plans[index] = value
        setForm({ ...form, addOnPlans: [...plans] })
    }
    const handleSelectOrg = (org: Organization) => {
        setForm({ ...form, orgId: org.id })
    }

    const handleStartDateChange = (field: string) => (e: any) => {
        setForm({ ...form, [field]: e });
    };

    const selectSubscription = (value: any) => {
        setForm({ ...form, subscriptionPlanId: value.id })
    }

    const addPlan = () => {
        const addOnPlans: string[] = [...form?.addOnPlans || ""]
        setForm({ ...form, addOnPlans: [...addOnPlans, ""] })
        console.log(form)
    }

    const handleBackButton = () => {
        navigate(-1);
    };

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (triggerValidation()) {
            addSubscription.execute({
                data:{
                    subscriptionId: form.subscriptionId,
                    orgId: form.orgId,
                    startDate: form.startDate,
                    endDate: form.endDate,
                    isActive: form.isActive,
                    plans: [...form?.addOnPlans || "",form.subscriptionPlanId]
                }
            })
        }
    }
    const resetHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (id) {
            setForm({...initialForm })
            subcriptionDetails.execute()
        } else {
            setForm({ ...initialForm })
        }
    }
    const { triggerValidation, validationMessages } = useValidation(form, validationRules);
    const onChange = handleChange(form, setForm);

    useEffect(() => {
        getOrganizations.execute()
        if (id) {
            subcriptionDetails.execute()
        }
    }, [])

    const plans:string[] = form?.addOnPlans || [] 
    return (
        <form className="relative flex flex-col space-y-3 p-8 rounded-xl bg-white w-11/12 mx-auto mt-14 sm:mt-20 lg:mt-24">
            <FormLoadingOverlay loading={subcriptionDetails.loading} />
            <div className=" flex items-center flex-row space-x-2">
                <button onClick={handleBackButton} className=' hidden sm:block hover:text-secondary'>
                    <RiArrowLeftCircleLine />
                </button>
                <h3>{id ? "Update Subscription" : "Add Subscription"}</h3>
            </div>
            <FormRow gap={4}>
                <SearchDropDown
                    Icon={<RiUserLine />}
                    label="Organization"
                    name="orgId"
                    placeholder="Select Organization"
                    inputSize="quarter"
                    renderResultItem={({ name, mobile }) => <>{mobile + "-" + name}</>}
                    dataList={getOrganizations.data?.data || []}
                    searchCondition={(query: string) => (data: any) => {
                        return data.name.toLowerCase().includes(query.toLowerCase());
                    }}
                    callback={handleSelectOrg}
                    // validation={validationMessages["orgId"]}
                    valueField={"name"}
                    showRequired
                    value={getOrganizations.data?.data.find((orgs: any) => form["orgId"] === String(orgs.id))?.name || ""}
                    defaultValue={getOrganizations.data?.data.find((orgs: any) => form["orgId"] === String(orgs.id))?.name || ""}
                />
                <DateInput
                    format="dd MMM yyyy"
                    stringValue={form.startDate || ""}
                    handleChange={handleStartDateChange("startDate")}
                    label="Start Date"
                />
                <DateInput
                    format="dd MMM yyyy"
                    stringValue={form.endDate || ""}
                    handleChange={handleStartDateChange("endDate")}
                    label="End Date"
                />
            </FormRow>
            <div className=' flex'>
                <SectionHeading heading="Subscription" />
            </div>
            <FormRow>
                <SearchDropDown
                    Icon={<RiVipDiamondLine />}
                    label="Subscription"
                    name='subscriptionPlanId'
                    placeholder="Select Plan"
                    inputSize="quarter"
                    renderResultItem={({ name, isAddon, isVisible }) => {
                        return (
                            <>
                                {name}
                                {isAddon ? "/Addon" : "/Sub"}
                                {isVisible ? "/Visible" : "/Not Visible"}
                            </>
                        )
                    }}
                    dataList={Plans.data?.getPlans?.plans || []}
                    searchCondition={(query: string) => (data: any) => {
                        return data.name.toLowerCase().includes(query.toLowerCase());
                    }}
                    callback={selectSubscription}
                    validation={validationMessages[`subcriptionPlanId`]}
                    valueField={"name"}
                    value={Plans.data?.getPlans?.plans.find((plan: any) => {
                        if (String(form?.subscriptionPlanId) === String(plan.id)) {
                            return plan
                        }
                    })?.name || ""}
                    defaultValue={Plans.data?.getPlans?.plans.find((plan: any) => String(form?.subscriptionPlanId) === String(plan.id))?.name || ""}
                />
            </FormRow>
            <div className=' flex'>
                <SectionHeading heading="Add Addon's" />
                <span className=' text-secondary text-sm' onClick={addPlan}><RiAddLine /></span>
            </div>
            <FormRow gap={4}>
                {plans.map((planId: string, index: number) => {

                    validationRules.push({
                        input_path: `addOnPlans.${index}`,
                        label_name: "Plan ",
                        error_message: "Select Valid Plan",
                        input_required: true,
                        regex: REGEX.Integers.regex,
                    })

                    return (
                        <>
                            <SearchDropDown
                                Icon={<RiVipDiamondLine />}
                                label="Addon"
                                name={`addOnPlans.${index}`}
                                placeholder="Select Addon"
                                inputSize="quarter"
                                renderResultItem={({ name, isAddon, isVisible }) => {
                                    return (
                                        <>
                                            {name}
                                            {isAddon ? "/Addon" : "/Sub"}
                                            {isVisible ? "/Visible" : "/Not Visible"}
                                        </>
                                    )
                                }}
                                dataList={Plans.data?.getPlans?.plans || []}
                                searchCondition={(query: string) => (data: any) => {
                                    return data.name.toLowerCase().includes(query.toLowerCase());
                                }}
                                callback={(value: PlansInterface) => handleSelectSubPlan(index, value.id)}
                                validation={validationMessages[`addOnPlans.${index}`]}
                                valueField={"name"}
                                value={Plans.data?.getPlans?.plans.find((plan: any) => {
                                    if (String(planId) === String(plan.id)) {
                                        console.log(String(planId) === String(plan.id))
                                        return plan
                                    }
                                })?.name || "" }
                                defaultValue={Plans.data?.getPlans?.plans.find((plan: any) => planId === String(plan.id))?.name || ""}
                            />
                        </>
                    )
                })}
            </FormRow>
            <div className="flex justify-end items-center gap-4">
                <SecondaryButton icon={<RiCloseLine />} title="Reset"
                    callback={resetHandle}
                />
                <PrimaryButton
                    icon={<RiArrowRightCircleLine />}
                    title="Submit"
                    callback={handleSubmit}
                />
            </div>
        </form>
    )
}

export default SubscriptionFrom;