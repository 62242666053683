import React, { useEffect, useMemo, useState } from 'react'
import useAPI from '../../../Hooks/useAPI'
import { useNavigate, useParams } from 'react-router-dom'
import { CouponCodeInterface, Organization, Page, PlanOrderInterface, PlansInterface, subscriptionInterface } from '../../../Constants/Interfaces'
import { RiArrowLeftCircleLine, RiBuilding2Line, RiCheckLine, RiCloseLine, RiDeleteBin2Line, RiEdit2Line, RiEditLine, RiMailLine, RiMapLine, RiMapPinLine, RiPhoneLine } from '@remixicon/react'
import { FilterBar, Pagination, SecondaryLinkButton, StatusTag } from '../../../Components'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { useTable } from '../../../Hooks'
import ActionButton from '../../../Components/ActionButton'
import moment from 'moment'
import { SectionHeading } from '../../../Components/controls/Input'

interface PlanOrderWithSubscriptionInterface extends PlanOrderInterface {
  subscription?: {
    id: number;
  }
}

const CouponCodeDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, execute, loading } = useAPI<{ data: CouponCodeInterface, success: boolean, message: string }>({
    method: "GET",
    url: `admin/coupons/${id}`,
    onCompleted: ({ data, success, message }) => {
      if (!success) {
        toast.error(message)
      }
    },
    onError: (err) => {
      toast.error(err)
    }
  })
  const planOrders = useAPI<{ data: PlanOrderWithSubscriptionInterface[], success: boolean, message: string, totalRecords: number }>({
    method: "GET",
    url: 'admin/orders',
    onCompleted: ({ data, success, message, totalRecords }) => {
      if (!success) {
        toast.error(message)
      }
      setPage({ ...page, totalRecords: totalRecords })
    },
    onError: (err) => {
      toast.error(err)
    }
  })

  const columns = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Order ID",
      accessor: "orderId",
    },
    {
      header: " Amount",
      accessor: "amount",
      render: ({ amount }: PlanOrderWithSubscriptionInterface) => amount ? String(amount) : 0
    },
    {
      header: "Tax",
      accessor: "tax",
      render: ({ tax }: PlanOrderWithSubscriptionInterface) => tax ? String(tax) : 0
    },
    {
      header: "Discount",
      accessor: "discount",
      render: ({ discount }: PlanOrderWithSubscriptionInterface) => discount ? String(discount) : 0
    },
    {
      header: "Subscription",
      accessor: "subscription",
      render: ({ subscription }: PlanOrderWithSubscriptionInterface) =>
      (
        <StatusTag
          label={
            subscription ? "Purchased" : "Not Purchased"
          }
          status={
            subscription ? "success" : "warning"
          }
        />
      ),
    },
    {
      header: "Payment",
      accessor: "isPaid",
      render: ({ isPaid }: PlanOrderWithSubscriptionInterface) => (
        <StatusTag
          label={
            isPaid ? "Done" : "Not Yet"
          }
          status={
            isPaid ? "success" : "warning"
          }
        />
      ),
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/organizations/update/${data.id}`)
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  //setShowDeleteModal(data.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const { Table } = useTable({
    columns,
    onRowClick: (data) => {
      navigate(`/orders/details/${data.id}/${id}`);
    },
  });

  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Page>({
    totalRecords: 0,
    skip: 0,
    take: 10,
  });

  const {
    createdBy,
    code,
    discountPer,
    discountFlat,
    description,
    upto,
    minOrderAmount,
    isActive,
    dateCreated,
    validTill,
    useLimit,
    plan
  } = data?.data || {}

  const planDetails: { feature: string, available: boolean }[] = []
  for (const i in plan?.planDetails) {
    const available: boolean = plan?.planDetails[i] || false
    planDetails.push({
      feature: i,
      available: available
    })
  }
  const handleBackButton = () => {
    navigate(-1);
  };

  useEffect((() => {
    (async () => {
      await execute()
      await planOrders.execute({
        url: `/admin/orders/?codeId=${id}&query=${query}&skip=${page.skip}&take=${page.take}&orgId=${id}`,
      })
    })()
  }), [query, page.skip, page.take])


  return (
    <div className='w-11/12 mx-auto rounded-xl bg-foreground  p-10 lg:p-5 flex flex-col space-y-5'>
      {/* Plan Name*/}
      <div className=' w-full text-center sm:grid sm:grid-cols-3'>
        <button onClick={() => navigate(-1)} className=' hidden sm:block hover:text-secondary'>
          <RiArrowLeftCircleLine />
        </button>
        <div className=' flex justify-center items-center space-x-2'>
          <span className='text-3xl text-primary font-bold'>{"Coupon"}</span>
          <button role='button' className=' sm:hidden text-xm text-disabled hover:text-secondary'><RiEdit2Line /></button>
        </div>
        <span className=' hidden sm:flex w-full justify-end'>
          <SecondaryLinkButton
            to={`/coupons/update/${id}`}
            title="Edit"
            icon={<RiEdit2Line />}
          />
        </span>
      </div>
      <span className=' break-all'>{description}</span>
      {/* plan Deatils*/}
      <div className='flex flex-col space-y-1'>
        <SectionHeading heading="Coupon Code Details" />
        <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
          <div className=' flex flex-row sm:sm:space-x-1'>
            <span className=' text-disabled  '>Code:</span>
            <span className='  '>{code}</span>
          </div>
          <div className=' flex flex-row sm:space-x-1'>
            <span className=' text-disabled  '>Created By:</span>
            <span className='  '>{createdBy?.name}</span>
          </div>
          <div className=' flex flex-row sm:space-x-1'>
            <span className=' text-disabled  '>Discount Per:</span>
            <span className='  '>{String(discountPer)}</span>
          </div>
          <div className=' flex flex-row sm:space-x-1'>
            <span className=' text-disabled  '>Discount Flat:</span>
            <span className='  '>{discountFlat}</span>
          </div>
          <div className=' flex flex-row sm:space-x-1'>
            <span className=' text-disabled  '>Maximum Order Amount:</span>
            <span className='  '>{upto}</span>
          </div>
          <div className=' flex flex-row sm:space-x-1'>
            <span className=' text-disabled  '>Minimum Order Amount:</span>
            <span className='  '>{minOrderAmount}</span>
          </div>
          <div className=' flex flex-row sm:space-x-1'>
            <span className=' text-disabled  '>Usage Limit:</span>
            <span className='  '>{Number(discountPer) >= -1 ? "Unlimited" : String(useLimit)}</span>
          </div>
          <div className=' flex flex-row sm:space-x-1'>
            <span className=' text-disabled  '>Validity:</span>
            <span className='  '>{moment(validTill).format('MMMM Do YYYY, h:mm:ss a')}</span>
          </div>
        </div>
      </div>
      {/* Description*/}
      {plan?.id ?
        <>
          <div className=' flex flex-col space-y-1'>
            <SectionHeading heading="Plan Description" />
            <span className=' break-all'>{plan?.description}</span>
          </div>
          <div className=' flex flex-col space-y-1'>
            <SectionHeading heading="Plan Features" />
            <div className='grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'>
              {
                planDetails.map(({ feature, available }: { feature: string, available: boolean }, index) => {
                  return (
                    <div key={index} className=' flex flex-row space-x-5' >
                      {available ?
                        <>
                          <span className=' text-green-600 '>
                            <RiCheckLine />
                          </span>
                        </>
                        :
                        <>
                          <span className=' text-red-700 '>
                            <RiCloseLine />
                          </span>
                        </>
                      }
                      <span className=' '>
                        {feature}
                      </span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </>
        : null}
      <div className=' flex flex-col space-y-1'>
        <SectionHeading heading="Plan Orders" />
        <Table height="80vh" loading={loading} rows={planOrders.data?.data || []} />
        <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
          <Pagination page={page} onPageChange={setPage} />
        </div>
      </div>
    </div >
  )
}
export default CouponCodeDetails
