import {ReactComponent as Empty} from "../Assets/illustrations/Empty.svg"
import {ReactComponent as EmptyStore} from "../Assets/illustrations/empty_stores.svg"
import {ReactComponent as File} from "../Assets/icons/file.svg"
import {ReactComponent as DailyReport} from "../Assets/illustrations/daily_Report.svg"
import {ReactComponent as StockReport} from "../Assets/illustrations/stock_report.svg"
import {ReactComponent as YoyReport} from "../Assets/illustrations/yoy_report.svg"
import {ReactComponent as CashFlowReport} from "../Assets/illustrations/cashflow_report.svg"

import {ReactComponent as GSTR1Illustratiton} from "../Assets/illustrations/GSTR1.svg"
import {ReactComponent as GSTR2Illustratiton} from "../Assets/illustrations/GSTR2.svg"
import {ReactComponent as GSTR3BIllustratiton} from "../Assets/illustrations/GSTR3B.svg"
import {ReactComponent as VendorReports} from "../Assets/illustrations/Vendor Reports.svg"
import {ReactComponent as CustomerReports} from "../Assets/illustrations/Customer Report.svg"
const ImagePaths = {
    slide1 : require("../Assets/images/slide1.png"),
    slide2 : require("../Assets/images/slide2.png"),
    slide3 : require("../Assets/images/slide3.png"),
    slide4 : require("../Assets/images/slide4.png"),
    logo: require("../Assets/images/logo.png"),
    create: require("../Assets/illustrations/create.png"),
    Empty,
    EmptyStore,
    File,
    DailyReport,
    StockReport,
    CashFlowReport,
    YoyReport,
    GSTR1Illustratiton,
    GSTR2Illustratiton,
    GSTR3BIllustratiton,
    VendorReports,
    CustomerReports,
    Error1: require("../Assets/lottie/Error_Cat.json"),
    Choice: require("../Assets/lottie/Choice.json"),
    Warning: require("../Assets/lottie/warning.json"),
    EmptyBox: require("../Assets/lottie/empty.json"),
    EmptyFolder: require("../Assets/lottie/EmptyFolder.json"),
    DocumentCreated: require("../Assets/lottie/DocumentCreated.json"),
    DiamondPurple: require("../Assets/lottie/DiamondPurple.json"),
    DiamondBlue: require("../Assets/lottie/DiamondBlue.json"),
    addOnIcon: require("../Assets/lottie/addon.json"),
    HourGlassLoader: require("../Assets/lottie/HourGlassLoader.json"),
    ErrorCross: require("../Assets/lottie/ErrorCross.json"),
    Success1: require("../Assets/lottie/Success1.json"),
    Success2: require("../Assets/lottie/Success2.json"),
    Reauth: require("../Assets/lottie/ReAuth.json"),

};


export default ImagePaths;
