import React, { FC } from "react";
import { Link, LinkProps } from "react-router-dom";

interface ButtonProps {
  title?: string;
  callback?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
}

const PrimaryButton: FC<ButtonProps> = ({
  title = "title",
  callback,
  icon = <></>,
}) => {
  return (
    <button
      onClick={callback}
      className="flex gap-1 h-fit min-w-fit rounded-lg bg-primary px-4 py-2 text-center text-sm text-foreground outline-none ring-indigo-300  hover:bg-secondary focus-visible:ring active:bg-indigo-700 transition-all duration-200"
    >
      <span>{icon}</span>
      <span>{title}</span>
    </button>
  );
};

const SecondaryButton: FC<ButtonProps> = ({
  title = "title",
  callback,
  icon = <></>,
}) => {
  return (
    <button
      onClick={callback}
      className="flex gap-2 h-fit text-disabled bg-foreground border border-disabled py-2 pl-2 pr-4 focus:outline-none rounded-lg text-sm font-semibold hover:text-secondary hover:border-secondary transition-all"
    >
      <span>{icon}</span>
      <span className="whitespace-nowrap">{title}</span>
    </button>
  );
};

const DangerButton: FC<ButtonProps> = ({
  title = "title",
  callback,
  icon = <></>,
}) => {
  return (
    <button
      onClick={callback}
      className="flex gap-1 h-fit rounded-lg bg-red-400 px-4 py-2 text-center text-sm text-foreground outline-none ring-red-300  hover:bg-red-500 focus-visible:ring active:bg-red-700 transition-all duration-200"
    >
      <span>{icon}</span>
      <span>{title}</span>
    </button>
  );
};

interface PrimaryLinkButtonProps extends LinkProps {
  title?: string;
  icon?: React.ReactNode;
}

const PrimaryLinkButton: FC<PrimaryLinkButtonProps> = ({
  title = "title",
  icon = <></>,
  ...props
}) => {
  return (
    <Link
      className="flex gap-1 w-fit h-fit rounded-lg bg-primary  px-4 py-2 text-center text-sm text-foreground outline-none ring-indigo-300  hover:bg-secondary hover:text-white focus-visible:ring active:bg-sky-600 transition-all duration-200 visited:text-white"
      {...props}
    >
      <div className="text-white">
      {icon}
      </div>
      <span>{title}</span>
    </Link>
  );
};

interface SecondaryLinkButtonProps extends LinkProps {
  title?: string;
  icon?: React.ReactNode;
}

const SecondaryLinkButton: FC<SecondaryLinkButtonProps> = ({
  title = "title",
  icon,
  ...props
}) => {
  return (
    <Link
      className="flex gap-1 w-fit h-fit rounded-lg bg-white border-disabled border  px-4 py-2 text-center text-sm text-disabled outline-none ring-indigo-300  hover:border-secondary hover:text-secondary focus-visible:ring transition-all duration-200 visited:text-white:visted"
      {...props}
    >
      {icon}
      <span>{title}</span>
    </Link>
  );
};

export {
  PrimaryButton,
  SecondaryButton,
  PrimaryLinkButton,
  SecondaryLinkButton,
  DangerButton,
};
