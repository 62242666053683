import React, { useEffect, useState } from 'react'
import useAPI from '../../../Hooks/useAPI'
import { useNavigate, useParams } from 'react-router-dom'
import { Page, PlanOrderInterface, PlansInterface, subscriptionInterface } from '../../../Constants/Interfaces'
import { toast } from 'react-toastify'
import { COLORS } from '../../../Constants'
import moment from 'moment'
import { SectionHeading } from '../../../Components/controls/Input'
import { RiCheckLine, RiUser6Line, RiHomeGearLine, RiEdit2Line, RiArrowLeftCircleLine, RiEditLine, RiDeleteBin2Line, RiCloseLine } from '@remixicon/react'
import { DashCard, Pagination, SecondaryLinkButton, StatusTag } from '../../../Components'
import ActionButton from '../../../Components/ActionButton'
import { useTable } from '../../../Hooks'

interface PlanOrderWithCouponCode extends PlanOrderInterface {
  couponCode?: {
    code: string
  }
}

interface IsubscriptionsWithPlanOrder extends subscriptionInterface {
  planOrder?: PlanOrderWithCouponCode,
  subscriptionAddOn: {
    plan: PlansInterface,
    validity: string,
    planOrder?: PlanOrderWithCouponCode,
    dateCreated: string
  }[]
}

const SubscrptionsDetails = () => {
  const { id, orgId } = useParams()
  const navigate = useNavigate()
  const { data, execute, loading } = useAPI<{ data: IsubscriptionsWithPlanOrder, success: boolean, message: string }>({
    method: "GET",
    url: orgId ?  `admin/subscriptions/${id}/${orgId}` : `admin/subscriptions/${id}`,
    onCompleted: ({ data, success, message }) => {
      if (!success) {
        toast.error(message)
      }
    },
    onError: (err: any) => {
      toast.error(err)
    }
  });

  const columns = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Plan Name",
      accessor: "plan.name",
      render: ({ plan }: { plan: PlansInterface }) => String(plan.name)
    },
    {
      header: "OrderId",
      accessor: "planOrder",
      render: ({ amount }: PlanOrderInterface) => String(amount || 0)
    },
    {
      header: "Validity",
      accessor: "validity",
      render: (validity: string) => moment(validity).format('MMMM Do YYYY, h:mm:ss a')
    },
    {
      header: "Duration",
      accessor: "plan.duration",
      render: ({ plan }: { plan: PlansInterface }) => String(plan.duration) + ' Days'
    },
    {
      header: "Price",
      accessor: "planOrder",
      render: ({ amount }: PlanOrderInterface) => String(amount || 0)
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/organizations/update/${data.id}`)
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  //setShowDeleteModal(data.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const { Table } = useTable({
    columns,
    onRowClick: (data) => {
      // if(orgId){
      //   navigate(`/subscriptions/details/${data.id}/${id}`);
      // }else{
      //   navigate(`/subscriptions/details/${data.id}`);
      // }
    },
  });

  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Page>({
    totalRecords: 0,
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    execute()
  }, []);

  const {
    plan,
    validity,
    planOrder,
    startDate,
    dateCreated,
    subscriptionAddOn,
    isActive,
  } = data?.data || {}
  const planDetails: { feature: string, available: boolean }[] = []
  for (const i in plan?.planDetails) {
    const available: boolean = plan?.planDetails[i] || false
    planDetails.push({
      feature: i,
      available: available
    })
  }

  return (
    <div className='w-11/12 mx-auto rounded-xl bg-foreground flex flex-col space-y-5 p-10 lg:p-5'>
        {/* Plan Name*/}
        <div className=' w-full text-center sm:grid sm:grid-cols-3'>
          <button onClick={()=>navigate(-1)} className=' hidden sm:block hover:text-secondary'>
            <RiArrowLeftCircleLine />
          </button>
          <div className=' flex justify-center items-center space-x-2'>
            <span className='text-3xl text-primary font-bold'>{plan?.name}</span>
            <button role='button' onClick={()=>navigate(`/subscriptions/update/${id}`)} className=' sm:hidden text-xm text-disabled hover:text-secondary'><RiEdit2Line /></button>
          </div>
          <span className=' hidden sm:flex w-full justify-end'>
            <SecondaryLinkButton
              to={`/subscriptions/update/${id}`}
              title="Edit"
              icon={<RiEdit2Line />}
            />
          </span>
        </div>
        {/* plan Deatils*/}
        <div className='flex flex-col space-y-1'>
          <SectionHeading heading="Subscription Details" />
          <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
            <div className=' flex flex-row sm:sm:space-x-1'>
              <span className=' text-disabled  '>Duration:</span>
              <span className='  '>{plan?.duration}</span>
            </div>
            <div className=' flex flex-row sm:space-x-1'>
              <span className=' text-disabled  '>visibility:</span>
              <span className='  '>{plan?.isVisible ? "Yes" : "No"}</span>
            </div>
            <div className=' flex flex-row sm:space-x-1'>
              <span className=' text-disabled  '>Plan Price:</span>
              <span className='  '>{plan?.price}</span>
            </div>
            <div className=' flex flex-row sm:space-x-1'>
              <span className=' text-disabled  '>Coupon Code:</span>
              <span className='  '>{planOrder?.couponCode?.code || "Not Applied"}</span>
            </div>
            <div className=' flex flex-row sm:space-x-1'>
              <span className=' text-disabled  '>Active:</span>
              <span className='  '>{isActive ? "Activated" : moment(validity).endOf('day').toDate() > new Date() ? "Not Yet" : "Deativated"}</span>
            </div>
            <div className=' flex flex-row sm:space-x-1'>
              <span className=' text-disabled  '>Start Date:</span>
              <span className='  '>{moment(startDate).format('MMMM Do YYYY, h:mm:ss a')}</span>
            </div>
            <div className=' flex flex-row sm:space-x-1'>
              <span className=' text-disabled  '>End Date:</span>
              <span className='  '>{moment(validity).format('MMMM Do YYYY, h:mm:ss a')}</span>
            </div>
            <div className=' flex flex-row sm:space-x-1'>
              <span className=' text-disabled  '>Order Id:</span>
              <span className='  '>{planOrder?.orderId || "Order Not Created"}</span>
            </div>
          </div>
          <div className='sm:flex'>
            <DashCard
              loading={loading}
              color={COLORS.Yellow}
              icon={<RiHomeGearLine />}
              value={data?.data.addOn.godown || 0}
              label="Godowns"
            />
            <DashCard
              loading={loading}
              color={COLORS.Green}
              icon={<RiUser6Line />}
              value={data?.data.addOn.customer || 0}
              label="Customers"
            />
            <DashCard
              loading={loading}
              color={COLORS.danger}
              icon={<RiUser6Line />}
              value={data?.data.addOn.vendor || 0}
              label="Vendors"
            />
          </div>
        </div>
        {/* Description*/}
        <div className=' flex flex-col space-y-1'>
          <SectionHeading heading="Subscription Description" />
          <span className=' break-all'>{plan?.description}</span>
        </div>
        {/* Features*/}
        <div className=' flex flex-col space-y-1'>
          <SectionHeading heading="Subscription Features" />
          <div className='grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'>
            {
              planDetails.map(({ feature, available }: { feature: string, available: boolean }, index) => {
                return (
                  <div key={index} className=' flex flex-row space-x-5' >
                    {available ?
                      <>
                        <span className=' text-green-600 '>
                          <RiCheckLine />
                        </span>
                      </>
                      :
                      <>
                        <span className=' text-red-700 '>
                          <RiCloseLine />
                        </span>
                      </>
                    }
                    <span className=' '>
                      {feature}
                    </span>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* Extra Addon*/}
        {
          !subscriptionAddOn ? null :
            <>
              <SectionHeading heading="Purchased Addons" />
              <div className="w-full bg-foreground rounded-md">
                <Table rows={subscriptionAddOn || []} height={'70vh'} loading={loading} />
              </div>
              <div className="bg-foreground w-full flex flex-wrap p-2 rounded-md">
                <Pagination page={page} onPageChange={setPage} />
              </div>
            </>
        }
    </div >
  )
}

export default SubscrptionsDetails