import {createSlice} from '@reduxjs/toolkit'
const initialState:stateProps = {
    organizations:[],
    selected: null,
    plan: null,

}
interface stateProps {
    organizations: organizationInterface[]
    selected?: number | null
    plan?: planInterface | null
}
interface organizationInterface{

}
interface planInterface{

}

export const orgSlice = createSlice({
    name:'organization',
    initialState,
    reducers:{
        setOrganizationList : (state,action)=>{
            state.organizations = action.payload
        },
        setSelectedOrganization : (state,action)=>{
            state.selected = action.payload
        },
        resetOrganization: (state)=>{
            state.organizations = initialState.organizations
            state.plan = initialState.plan
            state.selected = initialState.selected
        }

    }
})

export const selectOrganizationsList = (state:any)=> state.organization.organizations;
export const selectOrganization = (state:any)=> state.organization.organizations[state.organization.selected];
export const {setOrganizationList,setSelectedOrganization,resetOrganization} = orgSlice.actions;
export default orgSlice.reducer