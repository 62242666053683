import {createSlice} from '@reduxjs/toolkit'
const initialState:stateProps = {
    units:[]

}
interface stateProps {
    units?: unit[]
}
interface unit{
    id:string;
    name: string;
    shortHand: string
}


export const utilitySlice = createSlice({
    name:'utility',
    initialState,
    reducers:{
        setUnitList : (state,action)=>{
            state.units = action.payload
        },

    }
})

export const selectUnitList = (state:any)=> state.utility.units;
export const selectUnitByID = (state:any,id:string)=> state.utility.units.filter((unit:unit)=>unit.id=== id)[0];
export const {setUnitList,} = utilitySlice.actions;
export default utilitySlice.reducer