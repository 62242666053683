import React, { useEffect, useState } from 'react';

interface CountdownParams {
  expiresAt: string;
  trigger: () => void;
}

export default function Countdown({ expiresAt, trigger = () => {} }: CountdownParams) {
  const [timer, setTimer] = useState('');

  useEffect(() => {
    const countDownDate = parseInt(expiresAt, 10);

    const interval = setInterval(function () {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      const countdown = addZero(minutes) + ':' + addZero(seconds);

      if (distance < 0) {
        clearInterval(interval);
        trigger();
      }

      setTimer(countdown);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [expiresAt,trigger]);

  return <>{timer}</>;
}

function addZero(num: number) {
  if (String(num).length < 2) {
    return '0' + String(num);
  } else {
    return String(num);
  }
}
