import {createSlice} from '@reduxjs/toolkit'
interface IntitalState{
    id: string|null;
    token: string|null;
    role: string|null;
    name: string|null;
    mobile:string|null;
    organizations : any[];
    isLoading: boolean;
    error: boolean;
    error_message: string;
    token_expired: boolean;
}
const initialState:IntitalState = {
    id:null,
    token:null,
    role: null,
    mobile: null,
    name: null, 
    organizations:[],
    isLoading: false,
    error: false,
    error_message:"",
    token_expired: false,
}

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        getAuthSuccess : (state,action)=>{
            state.isLoading = false
            state.token = action.payload.token
            state.id = action.payload.id  
            state.role = action.payload.role
            state.name = action.payload.name
            state.mobile = action.payload.mobile
            state.organizations = action.payload.organizations
            state.token_expired = false;
            state.error = false
            state.error_message = ""
        },
        getLogout:(state)=>{
            state.token = initialState.token;
            state.role = initialState.role;
            state.id= initialState.id;
            state.mobile = initialState.mobile;
            state.organizations = initialState.organizations
            state.error = initialState.error;
            state.error_message = initialState.error_message;
            state.isLoading = initialState.isLoading;
            state.token_expired = false;
            localStorage.clear()
        },
        setOrganizations: (state,action)=>{
            state.organizations = action.payload.organizations
        },
        setTokenExpired:(state,action:{payload:boolean})=>{
            state.token_expired = action.payload;
        }

    }
})

export const selectIsLoading = (state:any)=> state.auth.isLoading
export const selectAccessToken = (state:any)=> state.auth.token
export const selectUserName = (state:any) => state.auth.name;
export const selectid = (state:any)=> state.auth.id
export const selectRole = (state:any)=> state.auth.role
export const selectOrganizations = (state:any)=> state.auth.organizations
export const selectIsTokenExpired = (state:any)=> state.auth.token_expired
export const {getAuthSuccess,getLogout,setOrganizations,setTokenExpired} = authSlice.actions;
export default authSlice.reducer