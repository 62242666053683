import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RoutesInterface } from "../Constants/Interfaces";
import { getNavigationRoutes } from "../Constants/Routes";
import { RiMenuLine } from "@remixicon/react";

export default function Navbar() {
  const { pathname } = useLocation();
  const hiddenRoutes = [
    "",
  ];
  return (
    <nav
      className={`printInvisible ${
        hiddenRoutes.includes(pathname.split("/")[1]) ? "hidden" : "flex flex-col"
      } w-fit z-[9999] top-0 fixed pt-6 h-screen items-start justify-start py-3 bg-foreground hover `}
    > 
      <button className="w-full flex justify-start pl-4">
        <RiMenuLine/>
      </button>
      <div className="pt-8 flex flex-col flex-1 group">
      
        {getNavigationRoutes().map((route, i) => (
          <NavItem key={i} {...route} active={pathname.startsWith(route.path)} />
        ))}
      </div>
    </nav>
  );
}
interface NavItemProps extends RoutesInterface {
  active?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  label,
  path,
  icon,
  active = false,
}) => {
  console.log({path,label},  active)
  return (
    <Link
      className={`flex  items-center hover:bg-slate-100 gap-2 text-sm pl-3 pr-3 group-hover:pr-12 py-2 ${
        active ? "text-primary" : "text-slate-500"
      }`}
      to={path}
    >
      <span className={`text-slate-500  p-1 rounded-lg  ${active?"bg-primary text-white":"bg-white"} `}>{icon}</span>
      <span className="hidden group-hover:block transition-all ease-in-out">
        {label}
      </span>
    </Link>
  );
};
