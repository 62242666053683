import { RiDeleteBin2Line, RiEditLine, RiUserAddLine, RiUserLine } from "@remixicon/react";
import {
  FilterBar,
  Pagination,
  PrimaryButton,
  SecondaryLinkButton,
  StatusTag,
} from "../../../Components";
import ActionButton from "../../../Components/ActionButton";
import { useTable } from "../../../Hooks";
import useAPI from "../../../Hooks/useAPI";
import { FormatAmount, GetFormattedDate } from "../../../Utils";
import { IUser, Page } from "../../../Constants/Interfaces";
import { useEffect, useState } from "react";
import { PrimaryLinkButton } from "../../../Components/buttons/Button";
import { useNavigate } from "react-router-dom";

type Props = {};
export interface ApiResponse {
  message: string;
  data: IUser[];
  totalRecords: number;
}

const UserIndex = (props: Props) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Page>({
    totalRecords: 0,
    skip: 0,
    take: 10,
  });
  const { data, error, execute, loading, refetch } = useAPI<ApiResponse>({
    method: "GET",
    url: "/admin/users",
    onCompleted: (data)=>{
      setPage({...page,totalRecords:data.totalRecords})
    }
  });
  const columns = [
    {
      header: "id",
      accessor: "id",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Mobile",
      accessor: "mobile",
    },
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Organizations Count",
      accessor: "org",
      render: ({ organizations }: any) => <>{organizations?.length || 0}</>,
    },
    {
      header: "Status",
      accessor: "active",
      render: ({ isDeleted, isVerifiedMobileNumber }: IUser) => (
        <StatusTag
          label={
            isDeleted
              ? "Deleted"
              : isVerifiedMobileNumber
              ? "Verified"
              : "Unverfied"
          }
          status={
            isDeleted
              ? "danger"
              : isVerifiedMobileNumber
              ? "success"
              : "warning"
          }
        />
      ),
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/user/update/${data.id}`)
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  //setShowDeleteModal(data.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];
  const { Table } = useTable({
    columns,
    onRowClick: (data) => {
      console.log({ data });
      navigate(`/user/details/${data.id}`);
    },
  });
  useEffect(() => {
    execute({
      url: `/admin/users?query=${query}&skip=${page.skip}&take=${page.take}`,
    })
  }, [query,page.skip,page.take]);
  console.log({ data });
  return (
    <div className="flex-col gap-4 w-full flex">
      <FilterBar
        exportHandler={() => {}}
        filter={<></>}
        placeholder="Search User Name, Mobile"
        searchHandler={(e) => setQuery(e)}
        debounceTime={500}
        sortByList={[
          { label: "Name", value: "name" },
          { label: "Mobile", value: "brand" },
          { label: "Email", value: "mrp" },
          { label: "Verified", value: "mobileVerified" },
        ]}
        sortingHandler={({ name, order }) => {
          //setSort({ name, orderby: order });
        }}
        resetPagination={() => {
          //setPage({ ...page, skip: 0 })
        }}
        loading={loading}
        refetch={refetch}
      />
      <Table height="80vh" loading={loading} rows={data?.data || []} />
      <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
        <PrimaryLinkButton
          to={"/user/add"}
          title="Add User"
          icon={<RiUserAddLine size={18} />}
        />
        <Pagination page={page} onPageChange={setPage} />
      </div>
    </div>
  );
};

export default UserIndex;
