import React, { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { TableColumn } from "../Constants/Interfaces";
import { LottieDiv } from "../Components";
import { ImagePaths } from "../Constants";

interface Row {
  [key: string]: any;
}
interface TableComponentProps {
  rows: Row[];
  loading: boolean;
  scrollable?: boolean;
  height?: string;
  error?: boolean; 
  errorMessage?: TableErrorStateMesssage;
}

interface TableErrorStateMesssage {
  heading: string;
  description: string;
}
interface TableProps {
  columns: TableColumn[];
  onRowClick: (rowData: Row, index: number) => void;
}

const useTable = ({
  columns,
  onRowClick = (rowData, index) => {},
}: TableProps) => {
  const Table = ({
    rows,
    loading,
    height = "50vh",
    scrollable = true,
    error = !loading && rows.length===0 ,
    errorMessage = {
      heading: "Oops! No Results Found",
      description:
      "It looks like we couldn't find any records that match your search. Try adjusting your criteria or explore other options."    
    },
  }: TableComponentProps) => {
    const tbodyRef = useRef<HTMLTableSectionElement>(null);
    useEffect(() => {
      const handleRowClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const rowElement = target.closest("tr");
        if (target.closest("button")) {
          return; // Exit early if it's a button or its child
        }
        if (rowElement) {
          const rowIndex = Array.from(
            rowElement.parentNode?.children || []
          ).indexOf(rowElement);
          const rowData = rows[rowIndex];
          onRowClick(rowData, rowIndex);
        }
      };

      const tbodyElement = tbodyRef.current;

      if (tbodyElement) {
        tbodyElement.addEventListener("click", handleRowClick);
      }

      return () => {
        if (tbodyElement) {
          tbodyElement.removeEventListener("click", handleRowClick);
        }
      };
    }, [rows]);

    return (
      <div className="px-2 py-4 min-w-full bg-foreground rounded-lg">
        <div
          className={`w-full ${
            scrollable
              ? `h-[${height}] overflow-y-scroll scrollBar relative`
              : ""
          } `}
        >
          <table className="w-full">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.header}
                    className="pb-4 z-10 px-4 border-b border-background text-slate-500 font-semibold text-left sticky top-0 bg-foreground"
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            {!error ? (
              <tbody className="mt-4" ref={tbodyRef}>
                {loading ? (
                  <SkeletonRows columns={columns} />
                ) : (
                  rows.map((row, index) => (
                    <TableRow
                      key={index}
                      index={index}
                      row={row}
                      columns={columns}
                    />
                  ))
                )}
              </tbody>
            ) : (
              <>
                <td colSpan={columns.length}>
                  <div className="flex flex-col items-center w-fit mx-auto">
                    <LottieDiv
                      className="max-w-[15rem]"
                      lottie={ImagePaths.EmptyFolder}
                      loop={false}
                    />
                    <h5 className="text-center w-full mb-2">
                      {errorMessage.heading}
                    </h5>
                    <p className="w-8/12 text-center">
                      {errorMessage.description}
                    </p>
                  </div>
                </td>
              </>
            )}
          </table>
        </div>
      </div>
    );
  };

  const TableRow = React.memo(
    ({
      row,
      columns,
      index,
    }: {
      row: Row;
      columns: TableColumn[];
      index: number;
    }) => (
      <tr key={"row" + index} className="hover:bg-gray cursor-pointer">
        {columns.map((column, i: number) => (
          <td
            key={column.header}
            className="py-2 px-4 border-b border-background text-left"
          >
            {column.render ? column.render(row, index) : row[column.accessor]}
          </td>
        ))}
      </tr>
    )
  );

  const SkeletonRows = ({ columns }: { columns: TableColumn[] }) => {
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <tr key={index} className="hover:bg-gray cursor-pointer">
            {columns.map((column) => (
              <td
                key={column.header}
                className="py-2 px-4 border-b border-background text-left"
              >
                <Skeleton />
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };
  return { Table };
};

export default useTable;
