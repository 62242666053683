import React, { useEffect, useMemo, useState } from 'react'
import useAPI from '../../../Hooks/useAPI'
import { useNavigate, useParams } from 'react-router-dom'
import { Organization, Page, PlanOrderInterface, PlansInterface, subscriptionInterface } from '../../../Constants/Interfaces'
import { RiArrowLeftCircleLine, RiBuilding2Line, RiDeleteBin2Line, RiEdit2Line, RiEditLine, RiMailLine, RiMapLine, RiMapPinLine, RiPhoneLine } from '@remixicon/react'
import { FilterBar, Pagination, SecondaryLinkButton, StatusTag } from '../../../Components'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { useTable } from '../../../Hooks'
import ActionButton from '../../../Components/ActionButton'
import moment from 'moment'

interface IsubscriptionsWithPlanOrder extends subscriptionInterface {
    planOrder?: PlanOrderInterface,
}

const OrganizationDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { data, execute, loading } = useAPI<{ data: Organization, success: boolean, message: string }>({
        method: "GET",
        url: `admin/organizations/${id}`,
        onCompleted: ({ data, success, message }) => {
            if (!success) {
                toast.error(message)
            }
        },
        onError: (err) => {
            toast.error(err)
        }
    })
    const getSubscriptions = useAPI<{ data: IsubscriptionsWithPlanOrder[], success: boolean, message: string, totalRecords: number }>({
        method: "GET",
        url: 'admin/subscriptions',
        onCompleted: ({ data, success, message, totalRecords }) => {
            if (!success) {
                toast.error(message)
            }
            setPage({ ...page, totalRecords: totalRecords })
        },
        onError: (err) => {
            toast.error(err)
        }
    })

    const columns = [
        {
            header: "ID",
            accessor: "id",
        },
        {
            header: "Plan Name",
            accessor: "plan.name",
            render: ({plan}: {plan:PlansInterface}) => String(plan.name)
        },
        {
            header: " Start",
            accessor: "startDate",
            render: ({startDate}:{startDate:string}) => moment(startDate).format('MMMM Do YYYY, h:mm:ss a')
        },
        {
            header: "End",
            accessor: "validity",
            render: ({validity}:{validity:string}) => moment(validity).format('MMMM Do YYYY, h:mm:ss a')

        },
        {
            header: "Duration",
            accessor: "plan.duration",
            render: ({plan }: {plan:PlansInterface}) => String(plan.duration) + ' Days'
        },
        {
            header: "Amount",
            accessor: "planOrder",
            render: ({ amount }: PlanOrderInterface) => String(amount || 0)
        },
        {
            header: "Status",
            accessor: "active",
            render: ({ isActive }: subscriptionInterface) => (
                <StatusTag
                    label={
                        isActive ? "Active" : "Deactivate"
                    }
                    status={
                        isActive ? "success" : "warning"
                    }
                />
            ),
        },
        {
            header: "Action",
            accessor: "action",
            render: (data: any) => {
                return (
                    <ActionButton
                        align="left"
                        options={[
                            {
                                label: "Update",
                                icon: <RiEditLine />,
                                callback: () => {
                                    navigate(`/organizations/update/${data.id}`)
                                },
                            },
                            {
                                label: "Delete",
                                icon: <RiDeleteBin2Line />,
                                callback: () => {
                                    //setShowDeleteModal(data.id);
                                },
                            },
                        ]}
                    />
                );
            },
        },
    ];

    const { Table } = useTable({
        columns,
        onRowClick: (data) => {
            navigate(`/subscriptions/details/${data.id}/${id}`);
        },
    });

    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Page>({
        totalRecords: 0,
        skip: 0,
        take: 10,
    });

    const {
        name,
        emailAddress,
        mobile,
        address
    } = data?.data || {}

    const handleBackButton = () => {
        navigate(-1);
    };

    useEffect((() => {
        (async () => {
            await execute()
            await getSubscriptions.execute({
                url: `admin/subscriptions/?query=${query}&skip=${page.skip}&take=${page.take}&orgId=${id}`,
            })
        })()
    }), [query, page.skip, page.take])


    return (
        <div className=' w-11/12 mx-auto rounded-md flex flex-col space-y-5' >
            <div className='w-full grid grid-cols-1 lg:grid-cols-4  gap-5'>
                <div className=' w-full bg-foreground lg:col-span-2 rounded-md flex flex-col'>
                    <div className=' w-11/12 mx-auto mt-2'>
                        <button onClick={handleBackButton} className=' hover:text-secondary'>
                            <RiArrowLeftCircleLine />
                        </button>
                        {/* Org Name And Update Button */}
                        <div className=' grid grid-cols-2'>
                            <div className=' flex items-center space-x-2'>
                                <span className=' text-secondary'><RiBuilding2Line /></span>
                                <span className='  sm:text-xl md:text-2xl'>{data?.data.name}</span>
                            </div>
                            <div className=' flex justify-end'>
                                <SecondaryLinkButton
                                    to={`/organizations/update/${id}`}
                                    title="Edit"
                                    icon={<RiEdit2Line />}
                                />
                            </div>
                        </div>
                        {/* Org Contact Deatils */}
                        <div className=' flex flex-col gap-2 my-2'>
                            <a
                                href="mailto:"
                                className=" w-fit text-sm text-disabled hover:underline flex items-center gap-2"
                            >
                                <span className="text-secondary">
                                    <RiMailLine />
                                </span>
                                <span className="">
                                    {emailAddress || (
                                        <Skeleton
                                            width={"80%"}
                                            className="md:min-w-[8rem]"
                                            height={10}
                                        ></Skeleton>
                                    )}
                                </span>
                            </a>
                            <a
                                href="mailto:"
                                className="w-fit text-sm text-disabled hover:underline flex items-center gap-2"
                            >
                                <span className="text-secondary">
                                    <RiPhoneLine />
                                </span>
                                <span className="">
                                    {mobile || (
                                        <Skeleton
                                            width={"80%"}
                                            className="md:min-w-[8rem]"
                                            height={10}
                                        ></Skeleton>
                                    )}
                                </span>
                            </a>
                            <div className=' flex flex-row items-center space-x-2'>
                                <span className="text-secondary"><RiMapPinLine /></span>
                                <span className=' text-disabled break-all'>
                                    {address?.addressLine + ',' + address?.city + ',' + address?.state + ',' + address?.pincode}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className=' bg-foreground text-[#000] rounded-md'>
                    Pending
                </div>
                <div className=' bg-foreground text-[#000] rounded-md'>
                    Pending
                </div>
            </div>
            <FilterBar
                exportHandler={() => { }}
                filter={<></>}
                placeholder="Search Order id,Plan Name,Amount"
                searchHandler={(e) => setQuery(e)}
                debounceTime={500}
                sortByList={[
                    { label: "Name", value: "name" },
                    { label: "Mobile", value: "brand" },
                    { label: "Email", value: "mrp" },
                    { label: "Verified", value: "mobileVerified" },
                ]}
                sortingHandler={({ name, order }) => {
                    //setSort({ name, orderby: order });
                }}
                resetPagination={() => {
                    //setPage({ ...page, skip: 0 })
                }}
                loading={loading}
                refetch={fetch}
            />
            <div className="w-full bg-foreground rounded-md">
                <Table rows={getSubscriptions.data?.data || []} loading={loading} />
            </div>
            <div className="bg-foreground w-full flex flex-wrap p-2 rounded-md">
                <Pagination page={page} onPageChange={setPage} />
            </div>
        </div>
    )
}
export default OrganizationDetails
