import { RiDeleteBin2Line, RiEditLine, RiUserAddLine, RiUserLine } from "@remixicon/react";
import {
    FilterBar,
    Pagination,
    StatusTag,
} from "../../../Components";
import ActionButton from "../../../Components/ActionButton";
import { useTable } from "../../../Hooks";
import useAPI from "../../../Hooks/useAPI";
import { FormatAmount, GetFormattedDate } from "../../../Utils";
import { IUser, Page, PlanOrderInterface } from "../../../Constants/Interfaces";
import { useEffect, useState } from "react";
import { PrimaryLinkButton } from "../../../Components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface PlanOrderWithSubscriptionInterface extends PlanOrderInterface {
    subscription?: {
        id: number;
    }
}


const PlanOrderIndex = () => {

    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Page>({
        totalRecords: 0,
        skip: 0,
        take: 10,
    });
    const { data, error, execute, loading, refetch } = useAPI<{ data: PlanOrderWithSubscriptionInterface[], message: string, success: boolean, totalRecords: number }>({
        method: "GET",
        url: "/admin/orders",
        onCompleted: (data) => {
            setPage({ ...page, totalRecords: data.totalRecords })
        },
        onError:(err:any)=>{
            toast.error(err)
        }
    });
    const columns = [
        {
            header: "ID",
            accessor: "id",
        },
        {
            header: "Order ID",
            accessor: "orderId",
        },
        {
            header: " Amount",
            accessor: "amount",
            render: ({ amount }: {amount:number}) => amount ? String(amount) : 0
        },
        {
            header: "Tax",
            accessor: "tax",
            render: ({ tax }: { tax:number }) => tax ? String(tax) : 0
        },
        {
            header: "Discount",
            accessor: "discount",
            render: ({ discount }: {discount:number}) => discount ? String(discount) : 0
        },
        {
            header: "Subscription",
            accessor: "subscription",
            render: ({ subscription }: PlanOrderWithSubscriptionInterface) =>
            (
                <StatusTag
                    label={
                        subscription?.id ? "Purchased" : "Not Purchased"
                    }
                    status={
                        subscription?.id ? "success" : "warning"
                    }
                />
            ),
        },
        {
            header: "Payment",
            accessor: "isPaid",
            render: ({ isPaid }: PlanOrderWithSubscriptionInterface) => (
                <StatusTag
                    label={
                        isPaid ? "Done" : "Not Yet"
                    }
                    status={
                        isPaid ? "success" : "warning"
                    }
                />
            ),
        },
        {
            header: "Action",
            accessor: "action",
            render: (data: any) => {
                return (
                    <ActionButton
                        align="left"
                        options={[
                            {
                                label: "Update",
                                icon: <RiEditLine />,
                                callback: () => {
                                    navigate(`/organizations/update/${data.id}`)
                                },
                            },
                            {
                                label: "Delete",
                                icon: <RiDeleteBin2Line />,
                                callback: () => {
                                    //setShowDeleteModal(data.id);
                                },
                            },
                        ]}
                    />
                );
            },
        },
    ];
    const { Table } = useTable({
        columns,
        onRowClick: (data) => {
            navigate(`/orders/details/${data.id}`);
        },
    });
    useEffect(() => {
        execute({
            url: `/admin/orders?query=${query}&skip=${page.skip}&take=${page.take}`,
        })
    }, [query, page.skip, page.take]);
    
    return (
        <div className="flex-col gap-4 w-full flex">
            <FilterBar
                exportHandler={() => { }}
                filter={<></>}
                placeholder="Search Order ID,Receipt,Coupon Code,Oragnization Name,Oragnization Mobile, User Name,Mobile No.,"
                searchHandler={(e) => setQuery(e)}
                debounceTime={500}
                sortByList={[
                    { label: "Name", value: "name" },
                    { label: "Mobile", value: "brand" },
                    { label: "Email", value: "mrp" },
                    { label: "Verified", value: "mobileVerified" },
                ]}
                sortingHandler={({ name, order }) => {
                    //setSort({ name, orderby: order });
                }}
                resetPagination={() => {
                    //setPage({ ...page, skip: 0 })
                }}
                loading={loading}
                refetch={refetch}
            />
            <Table height="80vh" loading={loading} rows={data?.data || []} />
            <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
                {/* <PrimaryLinkButton
                    to={"/user/add"}
                    title="Add User"
                    icon={<RiUserAddLine size={18} />}
                /> */}
                <Pagination page={page} onPageChange={setPage} />
            </div>
        </div>
    );
};

export default PlanOrderIndex;