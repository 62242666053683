import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IUser, Page, PlansInterface } from '../../../Constants/Interfaces';
import useAPI from '../../../Hooks/useAPI';
import { FilterBar, Pagination, StatusTag } from '../../../Components';
import ActionButton from '../../../Components/ActionButton';
import { RiDeleteBin2Line, RiEditLine, RiUserAddLine } from '@remixicon/react';
import { useTable } from '../../../Hooks';
import { PrimaryLinkButton } from '../../../Components/buttons/Button';

type Props = {}
export interface ApiResponse {
  message: string;
  data: IUser[];
  totalRecords: number;
}

const PlansIndex = (props: Props) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Page>({
    totalRecords: 0,
    skip: 0,
    take: 10,
  });
  const { data, error, execute, loading, refetch } = useAPI<{data:PlansInterface[],success:boolean,message:string,totalRecords:number}>({
    method: "GET",
    url: "/admin/plans",
    onCompleted: (data)=>{
      setPage({...page,totalRecords:data.totalRecords})
    }
  });
  const columns = [
    {
      header: "id",
      accessor: "id",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Duration",
      accessor: "duration",
      render: ({ duration }: PlansInterface) => duration ? duration : "--"
    },
    {
      header: "Price",
      accessor: "price",
    },
    {
      header: "Addon",
      accessor: "isAddon",
      render: ({ isAddon }: PlansInterface) => (
        <StatusTag
          label={
            isAddon
              ? "Yes"
              : "No"
          }
          status={
            isAddon
              ? "success"
              : "warning"
          }
        />
      ),
    },
    {
      header: "Visibility",
      accessor: "isVisible",
      render: ({ isVisible }: PlansInterface) => (
        <StatusTag
          label={
            isVisible
              ? "Yes"
              : "No"
          }
          status={
            isVisible
              ? "success"
              : "danger"
          }
        />
      ),
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/user/update/${data.id}`)
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  //setShowDeleteModal(data.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];
  const { Table } = useTable({
    columns,
    onRowClick: (data) => {
      console.log({ data });
      navigate(`/plans/details/${data.id}`);
    },
  });
  useEffect(() => {
    execute({
      url: `/admin/plans?query=${query}&skip=${page.skip}&take=${page.take}`,
    })
  }, [query,page.skip,page.take]);
  console.log({ data });
  return (
    <div className="flex-col gap-4 w-full flex">
      <FilterBar
        exportHandler={() => {}}
        filter={<></>}
        placeholder="Search User Name, Mobile"
        searchHandler={(e) => setQuery(e)}
        debounceTime={500}
        sortByList={[
          { label: "Name", value: "name" },
          { label: "Mobile", value: "brand" },
          { label: "Email", value: "mrp" },
          { label: "Verified", value: "mobileVerified" },
        ]}
        sortingHandler={({ name, order }) => {
          //setSort({ name, orderby: order });
        }}
        resetPagination={() => {
          //setPage({ ...page, skip: 0 })
        }}
        loading={loading}
        refetch={refetch}
      />
      <Table height="80vh" loading={loading} rows={data?.data || []} />
      <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
        <PrimaryLinkButton
          to={"/plans/add"}
          title="Add Plan"
          icon={<RiUserAddLine size={18} />}
        />
        <Pagination page={page} onPageChange={setPage} />
      </div>
    </div>
  );
}

export default PlansIndex