import { Input } from "../../Components";
import Carousal from "../../Components/Carousal/Carousal";
import {  PaddingY } from "../../Components/controls/Input";
import { slide } from "../../Constants/Interfaces";
import { useState } from "react";
import { handleChange } from "../../Utils";
import OTPInput from "../../Components/controls/OTP";
import { useMutation } from "@apollo/client";
import Mutations from "../../GraphQl/Mutations";
import Countdown from "../../Utils/Countdown";
import { useDispatch } from "react-redux";
import { getAuthSuccess } from "../../Redux/slices/authState";
import { toast } from "react-toastify";
import useValidation from "../../Hooks/useValidation";
import { ImagePaths, REGEX } from "../../Constants";
import { RiMailLine, RiSmartphoneLine, RiUser2Line } from "@remixicon/react";
interface MobileFormProps {
  onCompleted: (data: any) => void;
  setPage: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface OTPFormProps{
  name: string,
  mobile: string,
  expiresAt: string
}

const Login = () => {
  const [page, setPage] = useState("login");
  const [data, setData] = useState({
    mobile: "",
    otp: "",
    name: "",
    expiresAt:""
  });
  const handlePageChange = (page: string) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setPage(page);
    };
  };
  const slides: slide[] = [
    {
      heading: `Visualize Your Success:`,
      subHeading: `Interactive Data Graphs`,
      img: ImagePaths.slide1,
    },
    {
      heading: "Efficiency at Scale:",
      subHeading: `Bulk Upload & Export Data`,
      img: ImagePaths.slide2,
    },
    {
      heading: "Streamline Inventory Management:",
      subHeading: `Shops & Warehouses`,
      img: ImagePaths.slide3,
    },
    {
      heading: "Stay in Control:",
      subHeading: `Monitor Anytime, Anywhere`,
      img: ImagePaths.slide4,
    },
  ];

  return (
    <div className="w-full flex align-middle justify-center">
      <div className="z-40 max-w-xl w-full h-fit  flex-1 bg-foreground flex flex-col px-4  md:px-12 lg:px-24 py-16 rounded-xl gap-2 lg:gap-8">
        <div className="flex w-full align-middle gap-3 border-b border-b-background pb-4 lg:pb-8">
          <div className="w-32 md:w-64  ">
            <img src={ImagePaths.logo} alt="logo" />
          </div>
          <div className="flex flex-col justify-center">
            <span className="text-disabled">{data?.otp}</span>
          </div>
        </div>

        {page === "otp" && (
          <OTPForm
            name={data.name}
            mobile={data.mobile}
            expiresAt={data.expiresAt}
          />
        )}
        {page === "login" && (
          <MobileForm
            setPage={handlePageChange("signup")}
            onCompleted={(data) => {
              setData(data);
              setPage("otp");
            }}
          />
        )}
      </div>
    </div>
  );
};

function MobileForm({ onCompleted, setPage }: MobileFormProps) {
  const [form, setForm] = useState({
    mobile: "",
  });
  const validationRules = [
    {
      input_path: "mobile",
      label_name: "Mobile No.",
      input_required: true,
      regex: REGEX.Mobile.regex,
      error_message: "Invalid Mobile No.",
    },
  ];
  const { triggerValidation, validationMessages } = useValidation(
    form,
    validationRules
  );

  const [getOTPMutation, { loading }] = useMutation(Mutations.getOTP);
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (triggerValidation()) {
      getOTPMutation({
        variables: { mobile: form.mobile },
        onCompleted({ getOTP }) {
          if (getOTP.response.success) {
            onCompleted(getOTP);
            toast.success(getOTP.response.message, {});
          } else {
            //handle error
            toast.error(getOTP.response.message);
          }
        },
        onError(error) {
          console.log(error);
          toast.error(error.message);
        },
      });
    }
  };
  const onChange = handleChange(form, setForm)
  return (
    <div className="flex-1 w-full flex flex-col gap-8 lg:gap-16">
      <div className="w-full">
        <h1 className="text-3xl lg:text-4xl font-semibold">Welcome Back !</h1>
        <p className="text-sm lg:text-base text-disabled">
          Start managing your Business <br /> faster and better{" "}
        </p>
      </div>
      <form className="flex flex-col gap-8 ">
        <Input
          type="text"
          onChange={onChange()}
          label="Mobile No."
          placeholder="Enter 10 digit mobile number"
          name="mobile"
          inputSize="full"
          paddingY={PaddingY.Medium}
          validation={validationMessages.mobile}
          Icon={<RiSmartphoneLine size={24} />}
        />
        <div>
          <button
            onClick={handleSubmit}
            className="w-full flex align-middle justify-center py-3 mb-2 text-foreground bg-primary rounded-md"
          >
            {loading ? "Sending Code" : "Send me a Code!"}
          </button>
        </div>
      </form>
    </div>
  );
}
interface verifyOTPInterface {
  mobile: string;
  otp: string;
}

function OTPForm({ name, mobile, expiresAt }: OTPFormProps) {
  const dispatch = useDispatch();
  const [verifyOTPMutation, { loading }] = useMutation(Mutations.verifyOTP);
  const handleSubmit = (e: verifyOTPInterface) => {
    verifyOTPMutation({
      variables: { ...e },
      onCompleted({ verifyOTP }) {
        const { response, token, user } = verifyOTP;
        if (response.success) {
          //save token
          dispatch(getAuthSuccess({ token, ...user }));
          toast.success(response.message);
        } else {
          //show error to user
          toast.error(response.message);
        }
      },
      onError(error) {
        toast.error(error.message);
        console.log(error);
      },
    });
  };

  return (
    <div className="flex-1 w-full flex flex-col gap-16 slide-left">
      <div className="w-full">
        <h1 className="text-4xl font-semibold">Enter OTP !</h1>
        <p className="text-disabled">
          Welcome {name} check your phone {mobile} <br /> and enter otp{" "}
        </p>
      </div>
      <form className="w-full flex flex-col gap-8 ">
        <OTPInput
          numberOfDigits={4}
          onInputComplete={(e) => handleSubmit({ mobile, otp: String(e) })}
        />
        <div className="flex justify-between">
          <span>
            Didn't recieve OTP?{" "}
            <button className="p-0 m-0 ml-4 text-secondary font-semibold bg-foreground border-none outline-none">
              Resend OTP
            </button>
          </span>
          <span className="text-disabled font-semibold">
            <Countdown expiresAt={expiresAt} trigger={() => null} />
          </span>
        </div>
        <button
          onClick={(e) => e.preventDefault()}
          className="w-full flex align-middle justify-center py-3 text-foreground bg-primary rounded-md"
        >
          {loading ? "Verifying OTP..." : "Verify OTP"}
        </button>
      </form>
    </div>
  );
}

export default Login;
