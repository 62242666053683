import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import authReducer from "./slices/authState";
import orgReducer from "./slices/orgState";
import purchaseReducer from "./slices/purchaseState";
import salesReducer from "./slices/salesState";
import utilityReducer from "./slices/utilityState";
import saleReturnReducer from "./slices/salesReturnState";
import purchaseReturnReducer from "./slices/purchaseReturnState";
import planCartReducer from "./slices/PlanCartState";
import subscriptionReducer from "./slices/subscriptionState";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["utilityReducer", "purchase","sales","planCart"],
  whitelist: ["auth", "organization","subscription"],
};
const reducers = combineReducers({
  auth: authReducer,
  organization: orgReducer,
  utility: utilityReducer,
  purchase: purchaseReducer,
  sales:salesReducer,
  saleReturn:saleReturnReducer,
  purchaseReturn: purchaseReturnReducer,
  planCart:planCartReducer,
  subscription: subscriptionReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
