import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { useOnClickOutside } from "usehooks-ts";
import { useNestedClickHandler } from "../../Utils";
import {
  DatePicker,
  DateRangePicker,
  Dropdown,
  Tooltip,
  Whisper,
} from "rsuite";
import { DatePickerProps, DateRangePickerProps } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { getNestedValueAtPath } from "../../Utils/getNestedValueAtPath";
import { RiAddBoxLine, RiQuestionLine, RiSortAsc, RiSortDesc } from "@remixicon/react";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  validation?: string;
  inputSize?: "full" | "half" | "quarter" | "small";
  paddingY?: PaddingY;
  Icon?: React.ReactNode;
  showRequired?: boolean;
  tooltip?: string;
}
export interface searchInputProps<T>
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  validation?: string;
  inputSize?: "full" | "half" | "quarter" | "small";
  paddingY?: PaddingY;
  Icon?: React.ReactNode;
  changeHandler?: (query: string) => void; // Add onChange handler prop
  debounceTime?: number; // Add debounce time prop
  renderResultItem: (item: T) => React.ReactNode; // Function to render each search result item
  searchResults: T[]; // Search results to be passed from the parent component
  loading?: boolean;
  initialValue?: string;
}
export interface SearchDropDownProps<T>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  validation?: string;
  Icon?: React.ReactNode;
  changeHandler?: (query: string) => void;
  debounceTime?: number;
  renderResultItem: (item: T) => React.ReactNode;
  dataList: T[];
  searchCondition: (query: string) => (data: T) => boolean;
  callback: (value: T) => void;
  valueField: string;
  showRequired?: boolean;
  inputSize?: "full" | "half" | "quarter" | "small";
}

export const InputWidths = {
  full: "col-span-8",
  half: "col-span-4",
  quarter: "col-span-2",
  small: "col-span-1",
};

export enum PaddingY {
  Small = "py-2",
  Medium = "py-3",
  Large = "py-6",
}
export function SearchInput<T>({
  label = "label",
  validation = "",
  inputSize = "quarter",
  paddingY = PaddingY.Small,
  Icon = null,
  changeHandler,
  debounceTime = 300, // Default debounce time of 300ms
  renderResultItem,
  searchResults, // Pass search results from the parent component
  loading = false,
  initialValue,
  defaultValue,
  ...props
}: searchInputProps<T>) {
  const menuRef = useRef(null);
  const [isFocused, setFocused] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [value, setValue] = useState<string>("once");

  // Debounce the onChange handler
  const debouncedOnChange = debounce((query: string) => {
    if (changeHandler) {
      changeHandler(query);
    }
  }, debounceTime);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setValue(query);
    debouncedOnChange(query);
  };
  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });
  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);
  return (
    <div className={`flex-1 relative ${InputWidths[inputSize]} `}>
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <div
        className={`flex-1 items-center flex border-2 border-background rounded-lg overflow-hidden pl-3 ring-indigo-300 transition duration-100 ${
          isFocused ? "ring" : ""
        }`}
      >
        <span className={`text-secondary ${loading && "animate-bounce"}`}>
          {Icon}
        </span>
        <input
          autoComplete="nope"
          onFocus={() => {
            setFocused(true);
            setShowMenu(true);
          }}
          onBlur={() => setFocused(false)}
          onChange={handleInputChange}
          type="text"
          value={value}
          className={`flex-1 border-none pl-3 ${paddingY} text-gray-800 placeholder-gray-500 outline-none`}
          {...props}
        />
      </div>

      <span className="text-danger text-xs font-semibold absolute top-[102%] left-0">
        {validation}
      </span>
      {showMenu && (
        <div
          ref={menuRef}
          className="z-40 max-h-16 overflow-y-scroll scrollBar absolute min-h-[10rem] bg-foreground top-full left-0 mt-3 w-full border border-background rounded-lg shadow-lg"
        >
          <ul>
            <SearchOption
              idClass="default"
              key={-1}
              hidden={searchResults.length !== 0}
            >
              No Records
            </SearchOption>
            {searchResults.map((result: any, i) => (
              <li
                onClick={() => {
                  setShowMenu(false);
                  setValue(result["Name"]);
                }}
                key={i}
              >
                {renderResultItem(result)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export function SearchDropDown<T>({
  label = "label",
  validation = "",
  Icon = null,
  debounceTime = 300,
  renderResultItem,
  dataList = [],
  searchCondition,
  callback,
  valueField = "",
  showRequired = false,
  defaultValue = "",
  inputSize = "quarter",
  ...props
}: SearchDropDownProps<T>) {
  const menuRef = useRef(null);
  const [isFocused, setFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<T[]>(dataList || []);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<
    string | number | readonly string[]
  >(defaultValue); // New state for the selected value
  const debouncedOnChange = React.useMemo(
    () =>
      debounce((query: string) => {
        if (searchCondition) {
          const filteredResult = dataList.filter(searchCondition(query));
          setSearchResults([...filteredResult]);
        }
      }, debounceTime),
    [dataList, debounceTime, searchCondition]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    debouncedOnChange(query);
    setSelectedValue(query); // Update selected value while typing
  };

  const onSelect = useNestedClickHandler("menuOption", "value", (index) => {
    const selectedResult: any = searchResults[index];
    callback(selectedResult);
    setSelectedValue(selectedResult[valueField]); // Clear the selected value in the input after selecting
    setShowMenu(false);
  });
  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });
  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
    return () => {};
  }, [defaultValue]);
  return (
    <div className={`relative ${InputWidths[inputSize]}`}>
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}{" "}
        <span
          hidden={!showRequired}
          className="text-slate-400 text-lg leading-none"
        >
          *
        </span>
      </label>
      <div
        className={`flex-1 items-center flex border-2 border-background rounded-lg overflow-hidden pl-3 ring-indigo-300 transition duration-100 ${
          isFocused ? "ring" : ""
        }`}
      >
        <span className="text-secondary">{Icon}</span>
        <input
          onFocus={() => {
            setFocused(true);
            setShowMenu(true);
            setSearchResults(dataList);
          }}
          onBlur={() => setFocused(false)}
          onChange={handleInputChange}
          value={selectedValue}
          type="text"
          autoComplete="off"
          className={`flex-1 border-none pl-3 py-2 text-gray-800 placeholder-gray-500 outline-none`}
          {...props}
        />
      </div>

      <span className="text-danger text-xs font-semibold absolute top-[105%] left-0">
        {validation}
      </span>
      {showMenu && (
        <div
          ref={menuRef}
          onClick={onSelect}
          className="z-50 absolute  h-fit bg-foreground top-full left-0 mt-3 w-full border border-background rounded-lg shadow-lg"
        >
          <ul className="flex flex-col h-[12rem] overflow-scroll scrollBar">
            {searchResults.map((result, i) => (
              <li
                className="menuOption px-3 py-2 hover:bg-slate-100 cursor-pointer transition-all ease-linear"
                key={i}
                value={i}
              >
                {renderResultItem(result)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

interface SearchOptionProps {
  idClass: string;
  onClick?: (value: any) => void;
  children: React.ReactNode;
  hidden?: boolean;
}
export const SearchOption: React.FC<SearchOptionProps> = ({
  idClass,
  onClick,
  hidden = false,
  ...props
}) => {
  return (
    <div
      onClick={onClick}
      className={`${idClass} ${
        hidden && "hidden"
      } px-2 py-1 hover:bg-gray cursor-pointer transition-all ease-in-out active:bg-blue-100 flex flex-wrap gap-2`}
    >
      {props.children}
    </div>
  );
};

export default function Input({
  label = "label",
  validation = "",
  inputSize = "quarter",
  paddingY = PaddingY.Small,
  Icon = null,
  showRequired = false,
  tooltip,
  autoComplete ="off",
  ...props
}: InputProps) {
  const [isFocused, setFocused] = useState(false);
  return (
    <div className={`flex-1 relative ${InputWidths[inputSize]}`}>
      <div className="flex flex-1 justify-between">
        <label className="block mb-2 text-sm font-medium text-gray-900">
          {label}{" "}
          <span
            hidden={!showRequired}
            className="text-slate-400 text-lg leading-none"
          >
            *
          </span>
        </label>
        {tooltip && (
          <Whisper
            placement="auto"
            trigger="hover"
            speaker={<Tooltip arrow={false}>{tooltip}</Tooltip>}
          >
            <span className="text-xs text-disabled hover:text-secondary">
              <RiQuestionLine />
            </span>
          </Whisper>
        )}
      </div>
      <div
        className={`flex-1 items-center flex border-2 border-background rounded-lg overflow-hidden ring-indigo-300 transition duration-100 ${
          isFocused ? "ring" : ""
        }`}
      >
        {Icon && <span className="ml-2 text-secondary">{Icon}</span>}
        <input
          onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
            e.currentTarget.blur()
          }
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          type="text"
          autoComplete={autoComplete}
          className={`flex-1 border-none pl-3 ${paddingY} text-gray-800 placeholder-gray-500 outline-none pr-2`}
          {...props}
        />
      </div>

      <span className="text-danger text-xs font-semibold absolute top-[102%] left-0">
        {validation}
      </span>
    </div>
  );
}
interface DateInputProps extends DatePickerProps {
  label: string;
  validation?: string;
  inputSize?: "full" | "half" | "quarter" | "small";
  paddingY?: PaddingY;
  Icon?: React.ReactNode;
  showRequired?: boolean;
  stringValue?: string;
  stringType?: "iso" | "ms"; //temp
  handleChange?: (e: string) => void;
}
export function DateInput({
  label = "label",
  validation = "",
  inputSize = "quarter",
  paddingY = PaddingY.Small,
  Icon = null,
  showRequired = false,
  stringValue = "",
  handleChange = () => {},
  stringType = "iso",
  ...props
}: DateInputProps) {
  const [isFocused, setFocused] = useState(false);
  const value: Date | null = stringValue
    ? new Date(stringType === "ms" ? parseInt(stringValue) : stringValue)
    : null;
  const onChangeHandler = (e: Date | null) => {
    if (e) {
      handleChange(e.toISOString());
    }
  };
  return (
    <div className={`flex-1 z-30 relative ${InputWidths[inputSize]}`}>
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}{" "}
        <span
          hidden={!showRequired}
          className="text-slate-400 text-lg leading-none"
        >
          *
        </span>
      </label>
      <div
        className={`flex-1 z-30 items-center flex border-2 border-background rounded-lg overflow-hidden ring-indigo-300 transition duration-100 ${
          isFocused ? "ring" : ""
        }`}
      >
        {Icon && (
          <span
            className={`ml-2 ${
              !props?.disabled && isFocused
                ? "text-secondary"
                : "text-slate-400"
            }`}
          >
            {Icon}
          </span>
        )}
        <DatePicker
          className="w-full z-30"
          onChangeCalendarDate={onChangeHandler}
          value={value}
          format="dd-MM-yyyy"
          oneTap
          onFocus={() => setFocused(true)}
          onOpen={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onClose={() => setFocused(false)}
          caretAs={() => <></>}
          {...props}
        />
      </div>

      <span className="text-danger text-xs font-semibold absolute top-[102%] left-0">
        {validation}
      </span>
    </div>
  );
}

interface DateRangeInputProps extends DateRangePickerProps {
  label: string;
  validation?: string;
  inputSize?: "full" | "half" | "quarter" | "small";
  paddingY?: PaddingY;
  Icon?: React.ReactNode;
  showRequired?: boolean;
  stringValue?: [string, string];
  stringType?: "iso" | "ms"; //temp
  handleChange?: (e: [string, string]) => void;
}
export function DateRangeInput({
  label = "label",
  validation = "",
  inputSize = "quarter",
  paddingY = PaddingY.Small,
  Icon = null,
  showRequired = false,
  stringValue = ["", ""],
  handleChange = () => {},
  stringType = "iso",
  ...props
}: DateRangeInputProps) {
  const [isFocused, setFocused] = useState(false);
  const value: DateRange | null = stringValue
    ? [
        new Date(
          stringType === "ms" ? parseInt(stringValue[0]) : stringValue[0]
        ),
        new Date(
          stringType === "ms" ? parseInt(stringValue[1]) : stringValue[1]
        ),
      ]
    : null;
  const onChangeHandler = (e: DateRange | null) => {
    if (e) {
      handleChange([e[0].toISOString(), e[1].toISOString()]);
    }
  };
  return (
    <div className={`flex-1 z-30 relative ${InputWidths[inputSize]}`}>
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}{" "}
        <span
          hidden={!showRequired}
          className="text-slate-400 text-lg leading-none"
        >
          *
        </span>
      </label>
      <div
        className={`flex-1 z-30 items-center flex border-2 border-background rounded-lg overflow-hidden ring-indigo-300 transition duration-100 ${
          isFocused ? "ring" : ""
        }`}
      >
        {Icon && (
          <span
            className={`ml-2 ${
              !props?.disabled && isFocused
                ? "text-secondary"
                : "text-slate-400"
            }`}
          >
            {Icon}
          </span>
        )}
        <DateRangePicker
          className="w-full z-30"
          onChange={onChangeHandler}
          value={value}
          format="dd-MM-yyyy"
          onFocus={() => setFocused(true)}
          onOpen={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onClose={() => setFocused(false)}
          caretAs={() => <></>}
          {...props}
        />
      </div>

      <span className="text-danger text-xs font-semibold absolute top-[102%] left-0">
        {validation}
      </span>
    </div>
  );
}
interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  error: string;
  width?: "full" | "half" | "quarter";
  placeholder?: string;
}

export function FormInput({
  type = "text",
  name = "",
  label = "",
  error = "",
  width = "full",
  placeholder = "placeholder",
  ...props
}: FormInputProps) {
  return (
    <div className={`relative w-${width}`}>
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <div className="flex-1 flex border border-background rounded-lg overflow-hidden pl-3 ring-indigo-300 transition duration-100">
        <input
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          {...props}
          className="flex-1 border-none py-3 px-3 text-gray-800 placeholder-gray-500 outline-none"
        />
      </div>
      <span className="text-red-500 text-xs font-bold absolute top-[102%] left-0">
        {error}
      </span>
    </div>
  );
}

interface FormRowProps {
  mx?: number;
  my?: number;
  gap?: number;
  children: React.ReactNode;
}

export function FormRow({ children, mx = 0, my = 0, gap = 2 }: FormRowProps) {
  return (
    <div
      className={`w-full mx-${mx} py-${my} gap-${gap} grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 justify-start `}
    >
      {children}
    </div>
  );
}
interface SectionHeadingProps {
  heading: string;
  mt?: number;
  mb?: number;
}
export const SectionHeading: React.FC<SectionHeadingProps> = ({
  heading,
  mt,
  mb,
}) => {
  return (
    <h3 className={`mt-${mt} mb-${mb} text-secondary text-lg font-semibold `}>
      {heading}
    </h3>
  );
};
interface RadioButtonGroupProps {
  name: string;
  label: string;
  options: string[];
  selected?: string;
}

export function RadioButtonGroup({
  name = "",
  label = "",
  options = [],
  ...props
}: RadioButtonGroupProps) {
  return (
    <div className="relative w-full">
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <div className="flex items-center space-x-6">
        {options.map((option) => (
          <RadioButton
            key={option}
            name={name}
            option={option}
            checked={option === props?.selected}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}

interface RadioButtonProps {
  name: string;
  option: string;
  checked: boolean;
}

export function RadioButton({
  name = "",
  option,
  checked,
  ...props
}: RadioButtonProps) {
  return (
    <div className="flex items-center" key={option}>
      <input
        type="radio"
        name={name}
        className="h-5 w-5"
        value={option}
        checked={checked}
        {...props}
      />
      <label className="pl-3 text-sm font-medium text-gray-900">{option}</label>
    </div>
  );
}

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  validation?: string;
  inputSize?: "full" | "half" | "quarter" | "small";
  paddingY?: PaddingY;
  showRequired?: boolean;
  rows?: number;
  cols?: number;
}

export function TextArea({
  name = "name",
  label = "label",
  validation = "Error Message",
  inputSize = "quarter",
  rows = 6,
  cols,
  ...props
}: TextAreaProps) {
  return (
    <div className={`flex-1 relative ${InputWidths[inputSize]}`}>
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <textarea
        name={name}
        className="w-full flex-1 rounded border-2 border-background px-3 py-3 text-gray-800 placeholder-gray-500 outline-none ring-indigo-300 transition duration-100 focus:ring"
        rows={rows}
        cols={cols}
        {...props}
      />
      <span className="text-red-500 text-xs font-bold absolute top-[102%] left-0">
        {validation}
      </span>
    </div>
  );
}

interface SortOption {
  value: string;
  label: string;
}
interface sortingOrderHandlerProps {
  name: string | null;
  order: "ASC" | "DESC" | null;
}
interface SortingDropdownProps {
  label: string;
  options: SortOption[];
  onSelect: (selectedValue: sortingOrderHandlerProps) => void;
}

export const SortDropdown: React.FC<SortingDropdownProps> = ({
  label = "label",
  options,
  onSelect,
}) => {
  const [displayValue, setDisplayValue] = useState<string>(label);
  const [value, setValue] = useState<string>("");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [order, setOrder] = useState<any>("ASC");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const selectHandler = (event: React.MouseEvent) => {
    const target = event.target as HTMLLIElement;
    const selectedField = target.getAttribute("data-value");
    const displayText = target.innerText;
    if (selectedField && selectedField !== value) {
      setValue(selectedField);
      onSelect({ name: selectedField, order });
      setDisplayValue(displayText);
      setShowMenu(false);
    } else {
      setValue("");
      onSelect({ name: null, order: null });
      setDisplayValue(label);
      setShowMenu(false);
    }
  };
  const orderHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newOrder = order === "DESC" ? "ASC" : "DESC";
    setOrder(newOrder);
    onSelect({ name: value, order: newOrder });
  };
  const handleMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };
  useOnClickOutside(menuRef, () => setShowMenu(false));
  return (
    <div ref={dropdownRef} className="group relative inline-block">
      <div
        onClick={handleMenu}
        className="flex justify-between items-center bg-foreground border border-disabled pl-2 pr-2 py-1 gap-2 rounded-lg text-sm text-primary font-semibold focus:outline-none focus:ring focus:border-secondary group-hover:border-secondary group-hover:text-secondary cursor-pointer"
      >
        <span>{displayValue}</span>

        <div
          onClick={orderHandler}
          className="flex items-center px-1 py-1 text-disabled hover:bg-secondary hover:text-foreground rounded-md transition-all"
        >
          {order === "DESC" ? <RiSortDesc /> : <RiSortAsc />}
        </div>
      </div>
      <ul
        ref={menuRef}
        onClick={selectHandler}
        hidden={!showMenu}
        className="bg-foreground border-2 border-gray shadow transition-shadow rounded-lg right-0 absolute top-full mt-2 z-50"
      >
        {options.map(({ label, value }) => (
          <li
            className={`w-full min-w-max text-sm py-2 px-4 hover:bg-gray cursor-pointer ${
              displayValue === label ? "text-primary font-semibold" : ""
            }`}
            key={value}
            data-value={value} // Set data-value attribute to store the value
          >
            {label}
          </li>
        ))}
      </ul>
    </div>
  );
};
interface DropdownProps<T> {
  label: string;
  optionList: T[];
  onSelect: (e: any) => void;
  placeholder?: string;
  renderOption?: (e: T) => React.ReactNode;
  inputSize?: "full" | "half" | "quarter" | "small";
  showRequired?: boolean;
  paddingY?: PaddingY;
  Icon?: React.ReactNode;
  labelField?: string;
  value?: any;
  valueField?: string | number;
  otherOption?: {
    label: string;
    callback: (e?: any) => void;
  };
  validation?: string;
}
export function DropdownInput<T>({
  label = "label",
  optionList,
  onSelect,
  placeholder,
  renderOption,
  inputSize = "full",
  showRequired = false,
  paddingY = PaddingY.Small,
  Icon = <></>,
  value,
  valueField,
  labelField,
  otherOption,
  validation,
  ...props
}: DropdownProps<T>) {
  const [isFocused, setFocused] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const handleSelect = (i: number) => (e: any) => {
    setSelectedValue(i);
    if (i === -1) return otherOption?.callback();
    if (valueField) {
      onSelect((optionList as any[])[i][valueField]);
    } else {
      onSelect((optionList as any[])[i]);
    }
  };
  useEffect(() => {
    if (value && valueField) {
      const index = optionList.findIndex(
        (option) => (value as any)[valueField] === (option as any)[valueField]
      );
      setSelectedValue(index);
    } else {
      setSelectedValue(-1);
    }
  }, [value, valueField, optionList]);
  return (
    <div className={`relative flex-1 ${InputWidths[inputSize]} z-50`}>
      <label className="block text-sm mb-2 font-medium text-gray-900">
        {label}{" "}
        <span
          hidden={!showRequired}
          className="text-slate-400 text-lg leading-none"
        >
          *
        </span>
      </label>
      <Dropdown
        onOpen={() => setFocused(true)}
        onClose={() => setFocused(false)}
        menuStyle={{ backgroundColor: "white" }}
        className={`w-full border-2 border-background rounded-lg ring-indigo-300 transition duration-100 ${
          isFocused ? "ring" : ""
        }`}
        title={
          labelField? getNestedValueAtPath(labelField,(optionList as any[])[selectedValue])||label: label
        }
      >
        {otherOption && (
          <Dropdown.Item
            className={`w-full min-w-max text-sm text-primary hover:bg-gray`}
            onSelect={handleSelect(-1)}
            // Use type assertion (option as any) to access valueField
          >
            <span className="flex gap-1 items-center w-full text-primary hover:text-secondary">
              <RiAddBoxLine />
              {otherOption.label}
            </span>
          </Dropdown.Item>
        )}
        {optionList.map((option, i) => (
          <Dropdown.Item
            key={i}
            className={`w-full min-w-max text-sm hover:bg-gray`}
            onSelect={handleSelect(i)}
            // Use type assertion (option as any) to access valueField
          >
            {renderOption? renderOption(option): labelField?(option as any)[labelField] :"" }
          </Dropdown.Item>
        ))}
      </Dropdown>
      <span className="text-danger text-xs font-semibold absolute top-[102%] left-0">
        {validation}
      </span>
    </div>
  );
}
