import { Dispatch, SetStateAction } from 'react';
import cloneDeep from 'lodash/cloneDeep';

export default function handleChange<T>(
  state: T,
  setState: Dispatch<SetStateAction<T>>
) {
  return (type: "string" | "number" = "string") => (
    evt: React.ChangeEvent<HTMLInputElement> |React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = evt.target;

    // Split the hierarchical path using dot (.) as a separator
    const pathSegments = name.split('.');
    let updatedState = cloneDeep(state);

    // Traverse the object to set the value at the correct path
    let currentObject: any = updatedState; // Use 'any' for now
    for (let i = 0; i < pathSegments.length - 1; i++) {
      const segment = pathSegments[i];
      if (typeof currentObject[segment] === 'undefined' || currentObject[segment] === null) {
        // Check if the segment is undefined or null
        if (i === pathSegments.length - 2) {
          // If it's the second to last segment, initialize it as an empty object
          currentObject[segment] = {};
        } else {
          // Otherwise, initialize it as an empty object
          currentObject[segment] = null;
        }
      }
      currentObject = currentObject[segment];
    }

    const typedValue = type === "string" ? value : Number(String(value));
    currentObject[pathSegments[pathSegments.length - 1]] = typedValue;

    setState({...updatedState});
  };
}
