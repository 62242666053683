import { useState } from 'react';
import { toast } from 'react-toastify';

export interface ValidationConfig {
  input_path: string; // Hierarchical path to the input value
  label_name: string;
  input_required: boolean;
  regex?: RegExp;
  error_message: string;
  customValidation?: (value: any, setValidationMssg: (msg: string) => void) => boolean;
}

export interface ValidationMessages {
  [input_name: string]: string;
}

interface ValidationHookResult {
  triggerValidation: () => boolean;
  resetValidation: () => void;
  validationPassed: boolean;
  validationMessages: ValidationMessages;
}

const useValidation = (formState: any, validationConfig: ValidationConfig[]): ValidationHookResult => {
  const [validationPassed, setValidationPassed] = useState(false);
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>({});

  const resetValidation = () => {
    setValidationPassed(false);
    setValidationMessages({});
  };

  const triggerValidation = (): boolean => {
    const messages: ValidationMessages = {};

    validationConfig.forEach(({ input_path, label_name, input_required, regex, error_message, customValidation }) => {
      const pathSegments = input_path.split('.');
      let value = formState;

      for (const segment of pathSegments) {
        if (value && value[segment] !== undefined) {
          value = value[segment];
        } else {
          value = undefined;
          break;
        }
      }

      // Check if the input is required but not entered
      if (input_required && (!value || (typeof value === 'string' && value.trim() === ''))) {
        messages[input_path] = `${label_name} is required`;
      } else if (!input_required && (!value || (typeof value === 'string' && value.trim() === ''))) {
        // Check if the input is not required and not entered
      } else {
        let customValidationPassed = true;

        // Check the custom validation function if provided
        if (customValidation) {
          customValidationPassed = customValidation(value, (msg) => {
            messages[input_path] = msg;
          });
        }

        if (customValidationPassed) {
          // Check the regex pattern if custom validation passes and regex is provided
          if (regex) {
            const regexPattern = new RegExp(regex);
            if (!regexPattern.test(String(value))) {
              messages[input_path] = error_message;
            }
          }
        } else {
          // Custom validation failed, set the error message if not already set
          if (!messages[input_path]) {
            messages[input_path] = error_message;
          }
        }
      }
    });

    const test_passed = Object.keys(messages).length === 0;
    setValidationMessages(messages);
    setValidationPassed(test_passed);
    if (!test_passed) {
      Object.values(messages).forEach((value) => toast.warning(value));
    }

    return test_passed;
  };

  return {
    triggerValidation,
    resetValidation,
    validationPassed,
    validationMessages,
  };
};

export default useValidation;
