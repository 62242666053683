import { createSlice } from "@reduxjs/toolkit";
import {
  AdditionalCharges,
  Address,
  DisitributionStatus,
  GodownDistribution,
  InventoryUnitsInterface,
  ShopDistribution,
} from "../../Constants/Interfaces";
import { CalculateBill } from "../../Utils";

interface SalesReturn {
  saleId: string;
  orgId: string;
  customerId: string;
  amount: number;
  refundAmount: number;
  paymentMode: string;
  products: SalesReturnItems[];
  termsAndCondition: string;
  additionalCharges: AdditionalCharges[];
  remark: string;
}
interface SalesReturnItems {
  productId: string;
  name: string;
  price: number;
  discount: number;
  tax: number;
  quantity: number;
  unit?: string;
  maxQuantity?: number;
  batchId: string;
  inventoryUnitId:string|null;
  inventoryUnit?: InventoryUnitsInterface; 
  godownDistribution?: GodownDistribution[];
  shopDistribution?: ShopDistribution;
  status?: DisitributionStatus;
}

interface InitialState {
  creditNoteNumber: string;
  form: SalesReturn;
  orignalAmount: number;
  subTotal: number;
  totalTax: number;
  totalDiscount: number;
  activeSalesReturnItem: number;
  sales?: {
    salesId: string;
    invoiceId: string;
    orgId: string;
    customer: {
      id: string;
      name: string;
      billAddress: Address
    };
    amount: number;
    paidAmount: number;
    refundAmount: number;
    paymentMode: string;
    products: SalesReturnItems[];
    termsAndCondition: string;
    additionalCharges: AdditionalCharges[];
  } | null;
  initialProducts: SalesReturnItems[];
}

const initialState: InitialState = {
  creditNoteNumber: "",
  form: {
    saleId: "",
    amount: 0,
    orgId: "",
    refundAmount: 0,
    paymentMode: "",
    products: [],
    customerId: "",
    termsAndCondition: "",
    additionalCharges: [],
    remark:""
  },
  orignalAmount: 0,
  subTotal: 0,
  totalTax: 0,
  totalDiscount: 0,
  activeSalesReturnItem: -1,
  sales: {
    customer : {
      id:"",
      name:"",
      billAddress: {
        addressLine:"",
        city:"",
        pincode:"",
        state:""
      }
    },
    amount:0,
    paidAmount:0,
    additionalCharges:[],
    invoiceId:"",
    orgId:"",
    paymentMode:"",
    products:[],
    refundAmount:0,
    salesId:"",
    termsAndCondition:""
  },
  initialProducts: [],
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    setCreditNoteNumber: (state, action) => {
      state.creditNoteNumber = action.payload.creditNoteNumber;
    },
    setSaleDetais: (state, action) => {
      state.sales = action.payload;
    },
    setSaleReturnPaymentMode: (state, action) => {
      state.form.paymentMode = action.payload;
    },
    setSaleRefundAmount: (state, action) => {
      if (state.form.amount >= action.payload && action.payload >= 0) {
        state.form.refundAmount = action.payload;
      }
    },
    setInitialProductList: (state, action) => {
      state.form.products = action.payload.map((product: any) => {
        return { ...product, maxQuantity: product.quantity };
      });
      state.initialProducts = action.payload;
      const { amount, subTotal, totalTax, totalDiscount } = CalculateBill(
        state.form.products
      );
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => parseInt(String(acc)) + parseInt(String(value.charges)),
        0
      );
      //Orignal Amount stores calculated sale amount without any additional charges
      state.orignalAmount = Math.round(amount);
      //add additinal charge to the orignal amount prevents from double adding the same charge
      state.form.amount =
        Math.round(state.orignalAmount) + totalAdditionalCharges;
      state.subTotal = Math.round(subTotal);
      state.totalTax = Math.round(totalTax);
      state.totalDiscount = Math.round(totalDiscount);
    },
    addReturnProduct: (state, action) => {
      state.activeSalesReturnItem = -1;
      if (action.payload.quantity > 0) {
        const { exist, index } = itemExists(
          state.form.products,
          action.payload.batchId,
          "batchId"
        );
        if (exist && index !== null) {
          const product = { ...action.payload };
          product.maxQuantity = product.quantity;
          state.form.products[index] = product;
        } else {
          const product = { ...action.payload };
          product.maxQuantity = product.quantity;
          state.form.products.push(product);
        }
        const { amount, subTotal, totalTax, totalDiscount } = CalculateBill(
          state.form.products
        );
        const totalAdditionalCharges = state.form.additionalCharges.reduce(
          (acc, value) =>
            parseInt(String(acc)) + parseInt(String(value.charges)),
          0
        );
        //Orignal Amount stores calculated sale amount without any additional charges
        state.orignalAmount = Math.round(amount);
        //add additinal charge to the orignal amount prevents from double adding the same charge
        state.form.amount =
          Math.round(state.orignalAmount) + totalAdditionalCharges;
        state.subTotal = Math.round(subTotal);
        state.totalTax = Math.round(totalTax);
        state.totalDiscount = Math.round(totalDiscount);
      }
    },
    addAdditionalCharges: (state, action) => {
      const index = state.form.additionalCharges.findIndex(
        (value) => value.name === action.payload.name
      );
      if (index === -1) {
        state.form.additionalCharges.push(action.payload);
      } else {
        state.form.additionalCharges[index] = action.payload;
      }
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => parseInt(String(acc)) + parseInt(String(value.charges)),
        0
      );
      state.form.amount = state.orignalAmount + totalAdditionalCharges;
    },
    removeAdditionalCharges: (state, action) => {
      state.form.additionalCharges = state.form.additionalCharges.filter(
        (value, i) => i !== action.payload
      );
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => acc + value.charges,
        0
      );
      state.form.amount = state.orignalAmount + totalAdditionalCharges;
    },
    setSaleReturnRemark: (state,action)=>{
      state.form.remark = action.payload
    },

    deleteReturnProduct: (state, action) => {
      state.form.products = state.form.products.filter(
        (item) => item.productId !== action.payload
      );
      const { amount, subTotal, totalTax, totalDiscount } = CalculateBill(
        state.form.products
      );
      state.form.amount = amount;
      state.subTotal = subTotal;
      state.totalTax = totalTax;
      state.totalDiscount = totalDiscount;
    },
    setProductQuantity: (state, action) => {
      const { index, quantity } = action.payload;
      const temp = { ...state.form.products[index] };
      temp.quantity = quantity;
      state.form.products[index] = temp;
      const { amount, subTotal, totalTax, totalDiscount } = CalculateBill(
        state.form.products
      );
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => parseInt(String(acc)) + parseInt(String(value.charges)),
        0
      );
      //Orignal Amount stores calculated sale amount without any additional charges
      state.orignalAmount = Math.round(amount);
      //add additinal charge to the orignal amount prevents from double adding the same charge
      state.form.amount =
        Math.round(state.orignalAmount) + totalAdditionalCharges;
      state.subTotal = Math.round(subTotal);
      state.totalTax = Math.round(totalTax);
      state.totalDiscount = Math.round(totalDiscount);
    },
    setProductPrice: (state, action) => {
      const { index, price } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          products: state.form.products.map((product, i) =>
            i === index ? { ...product, price } : product
          ),
        },
      };
    },
    addReturnDistribution: (state, action) => {
      state.form.products[state.activeSalesReturnItem].shopDistribution =
        action.payload.shopDistribution;
      state.form.products[state.activeSalesReturnItem].godownDistribution =
        action.payload.godownDistribution;
        state.activeSalesReturnItem = -1
    },
    autoAddReturnDistribution: (state, action) => {
      const { index, shopDistribution, godownDistribution, status } =
        action.payload;
      state.form.products[index].shopDistribution = shopDistribution;
      state.form.products[index].godownDistribution = godownDistribution;
      state.form.products[index].status = status;
    },
    setActiveSalesReturnItem: (state, action) => {
      state.activeSalesReturnItem = action.payload;
    },
    setPaymentMode: (state, action) => {
      state.form.paymentMode = action.payload;
    },
    setPaidAmount: (state, action) => {
      if (action.payload === "") {
        state.form.refundAmount = 0;
      } else if (action.payload > 0 && action.payload <= state.form.amount) {
        state.form.refundAmount = parseInt(action.payload);
      }
    },
    setSaleReturnTerms: (state, action) => {
      state.form.termsAndCondition = action.payload.replace(/\n/g, "\\n");
    },
    resetSalesReturnItem: (state, action) => {
      state.form.products = [];
      state.form.amount = 0;
      state.subTotal = 0;
      state.totalTax = 0;
      state.totalDiscount = 0;
    },
    resetSalesPaymentDetails: (state, action) => {
      state.form.refundAmount = 0;
      state.form.paymentMode = "";
    },
    resetSaleReturnForm: (state, action) => {
      state.creditNoteNumber = "";
      state.form = initialState.form;
      state.activeSalesReturnItem = initialState.activeSalesReturnItem;
      state.subTotal = initialState.subTotal;
      state.totalTax = initialState.totalTax;
      state.totalDiscount = initialState.totalDiscount;
    },
  },
});

export const selectSalesForm = (state: any) => state.saleReturn.form;
export const selectSaleReturnSummary = (state: any) => {
  return {
    products: state.saleReturn.form.products.length,
    amount: state.saleReturn.form.amount,
    subTotal: state.saleReturn.subTotal,
    totalTax: state.saleReturn.totalTax,
    totalDiscount: state.saleReturn.totalDiscount,
    refundAmount: state.saleReturn.form.refundAmount,
  };
};
export const selectCreditNoteNumber = (state: any) =>
  state.saleReturn.creditNoteNumber;
export const selectSalesDetails = (state: any) => state.saleReturn.sales;
export const selectSaleReturn = (state: any) => state.saleReturn.form;
export const selectInitialProductList = (state: any) =>
  state.saleReturn.initialProducts;
export const selectSalesReturnItem = (state: any) =>
  state.saleReturn.form.products;
export const selectActiveSalesReturnItem = (state: any) =>
  state?.saleReturn?.form?.products[state.saleReturn.activeSalesReturnItem];
export const selectAdditionalCharges = (state: any) =>
  state.saleReturn.form.additionalCharges;
export const selectSaleTerms = (state: any) =>
  state.saleReturn.form.termsAndCondition;
export const selectSaleReturnPaymentMode = (state: any) =>
  state.saleReturn.form.paymentMode;
export const selectSaleRefundAmount = (state: any) =>
  state.saleReturn.form.refundAmount;
export const {
  setCreditNoteNumber,
  setSaleDetais,
  addReturnDistribution,
  setInitialProductList,
  addReturnProduct,
  addAdditionalCharges,
  removeAdditionalCharges,
  deleteReturnProduct,
  setProductQuantity,
  setProductPrice,
  setActiveSalesReturnItem,
  setSaleReturnPaymentMode,
  resetSalesReturnItem,
  setPaymentMode,
  setPaidAmount,
  resetSalesPaymentDetails,
  resetSaleReturnForm,
  setSaleReturnTerms,
  autoAddReturnDistribution,
  setSaleRefundAmount,
  setSaleReturnRemark
} = salesSlice.actions;
export default salesSlice.reducer;

function itemExists(arr: any[], value: string, field: string) {
  let flag: {
    exist: boolean;
    index: null | number;
  } = {
    exist: false,
    index: null,
  };
  arr.forEach((element: any, index) => {
    if (element[field] === value) {
      flag.exist = true;
      flag.index = index;
    }
  });
  return flag;
}
