import React, { useState, useRef, useEffect } from 'react';

interface OTPInputProps {
  numberOfDigits: number;
  onInputComplete: (digits: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ numberOfDigits, onInputComplete }) => {
  const [digits, setDigits] = useState<string[]>([]);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newDigits = [...digits];
    newDigits[index] = value;

    setDigits(newDigits);

    if (value !== '' && index < numberOfDigits - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newDigits.filter(Boolean).length === numberOfDigits) {
      const otp = newDigits.join('');
      onInputComplete(otp);
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && digits[index] === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text/plain').slice(0, numberOfDigits);
    const newDigits = pasteData.split('');

    setDigits(newDigits);

    newDigits.forEach((digit, index) => {
      inputRefs.current[index]?.focus();
    });

    if (newDigits.length === numberOfDigits) {
      onInputComplete(newDigits.join(''));
    }
  };

  const renderInputs = () => {
    const inputs = [];
    for (let i = 0; i < numberOfDigits; i++) {
      inputs.push(
        <input
            className={`w-2/4 py-3 border border-background ring-indigo-100 focus:ring outline-none rounded-md text-lg font-bold text-center`}
          key={i}
          type="text"
          inputMode="numeric"
          pattern="[0-9]"
          maxLength={1}
          placeholder='*'
          value={digits[i] || ''}
          onChange={(e) => handleInputChange(i, e)}
          onKeyDown={(e) => handleKeyDown(i, e)}
          onPaste={(e) => handlePaste(e)}
          ref={(ref) => (inputRefs.current[i] = ref as HTMLInputElement)}
        />
      );
    }
    return inputs;
  };

  return <div className='w-full flex flex-row items-center gap-4'>{renderInputs()}</div>;
};

export default OTPInput;
