import React, { useEffect } from "react";
import {
  DashCard,
  SecondaryButton,
  SecondaryLinkButton,
  StatusTag,
} from "../../../Components";
import {
  RiArrowLeftCircleFill,
  RiArrowLeftCircleLine,
  RiCashLine,
  RiDeleteBin2Line,
  RiEdit2Line,
  RiEditLine,
  RiMailLine,
  RiPhoneLine,
  RiStoreLine,
} from "@remixicon/react";
import useAPI from "../../../Hooks/useAPI";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { COLORS } from "../../../Constants";
import { Address, IUser, Organization } from "../../../Constants/Interfaces";
import { FormatAmount } from "../../../Utils";
import { useTable } from "../../../Hooks";
import ActionButton from "../../../Components/ActionButton";
import { render } from "@testing-library/react";

type Props = {};

const UserDetails = (props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading, execute } = useAPI({
    method: "GET",
    url: `/admin/users/${id}`,
  });
  const userPayments = useAPI({
    method: "GET",
    url: `/admin/payments/${id}`,
  });
  const userColumns = [
    {
      header: "id",
      accessor: "id",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Address",
      accessor: "address",
      render: ({ address }: {address:Address}) => {
        const { addressLine, city, pincode, state } = address
        return `${addressLine}, ${state}, ${city},${pincode}, ${state}`;
      },
    },
    {
      header: "GSTIN",
      accessor: "gstin",
    },

    {
      header: "Status",
      accessor: "active",
      render: ({ isDeleted, isActive }: Organization) => (
        <StatusTag
          label={isDeleted ? "Deleted" : isActive ? "Active" : "In-Active"}
          status={isDeleted ? "danger" : isActive ? "success" : "warning"}
        />
      ),
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/user/update/${data.id}`);
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  //setShowDeleteModal(data.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];
  const paymentColumns = [
    {
      header: "id",
      accessor: "id",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Address",
      accessor: "address",
      render: ({ address }: {address:Address}) => {
        const { addressLine, city, pincode, state } = address
        return `${addressLine}, ${state}, ${city},${pincode}, ${state}`;
      },
    },
    {
      header: "GSTIN",
      accessor: "gstin",
    },

    {
      header: "Status",
      accessor: "active",
      render: ({ isDeleted, isActive }: Organization) => (
        <StatusTag
          label={isDeleted ? "Deleted" : isActive ? "Active" : "In-Active"}
          status={isDeleted ? "danger" : isActive ? "success" : "warning"}
        />
      ),
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/user/update/${data.id}`);
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  //setShowDeleteModal(data.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const { Table } = useTable({
    columns:userColumns,
    onRowClick: (data) => {
      console.log({ data });
      navigate(`/user/details/${data.id}`);
    },
  });
  const PaymentTable = useTable({
    columns: paymentColumns,
    onRowClick: (data) => {
      console.log({ data });
      navigate(`/user/details/${data.id}`);
    },
  });
  //handlers
  const handleBackButton = () => {
    navigate(-1);
  };

  //Effects
  useEffect(() => {
    execute();
  }, []);
  const {
    name,
    email,
    mobile,
    organizations,
    role,
    isVerifiedEmail,
    isVerifiedMobileNumber,
    dateCreated,
    isDeleted,
  }: IUser = data?.data || {};
  return (
    <div className="w-11/12 mt-6 mx-auto grid grid-cols-2 gap-4">
      <div className="w-full col-span-2 grid-cols-1 md:grid-cols-2 grid gap-4">
        {/*Customer Details */}
        <div className="w-full flex flex-col justify-between bg-foreground rounded-lg p-4">
          <div className="flex justify-between items-center">
            {/*Controls */}
            <div className="flex items-center gap-4">
              <button onClick={handleBackButton}>
                <RiArrowLeftCircleLine />
              </button>
              <h1 className="text-2xl font-medium">{name}</h1>
            </div>
            <SecondaryLinkButton
              to={`/user/update/${id}`}
              title="Edit"
              icon={<RiEdit2Line />}
            />
          </div>
          {/*Address */}

          {/*Contact Details */}
          <div className="w-full mt-3 flex justify-start">
            <a
              href="mailto:"
              className="text-sm text-disabled hover:underline flex flex-1 items-center gap-2"
            >
              <span className="text-secondary">
                <RiMailLine />
              </span>
              <span className="w-full flex-1">
                {email || (
                  <Skeleton
                    width={"80%"}
                    className="md:min-w-[8rem]"
                    height={10}
                  ></Skeleton>
                )}
              </span>
            </a>
            <a
              href="tel:"
              className="text-sm text-disabled hover:underline flex flex-1 items-center gap-2"
            >
              <span className="text-secondary">
                <RiPhoneLine />
              </span>
              <span className="w-full flex-1">
                {mobile || (
                  <Skeleton
                    width={"80%"}
                    className="md:min-w-[8rem]"
                    height={10}
                  ></Skeleton>
                )}
              </span>
            </a>
          </div>
        </div>
        {/*Stats */}
        <div className="w-full row-span-1 flex flex-wrap md:grid grid-cols-2 gap-4">
          <DashCard
            loading={loading}
            color={COLORS.secondary}
            icon={<RiStoreLine />}
            value={String(organizations?.length) || "--"}
            label="Organizations"
          />
          <DashCard
            loading={loading}
            color={COLORS.Green}
            icon={<RiCashLine />}
            value={FormatAmount(555)}
            label="Total Paid Amount"
          />
        </div>
      </div>
      <div className="w-full col-span-2">
        <Table rows={organizations || []} loading={loading} />
      </div>
    </div>
  );
};

export default UserDetails;
