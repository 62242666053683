import { useState, useCallback } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../Axios/config';

interface UseAxiosConfig<T = any> extends AxiosRequestConfig {
  method: 'GET' | 'POST' | 'PUT';
  url: string;
  data?: any;
  signal?: AbortSignal;
  onCompleted?: (data: T) => void;
  onError?: (error: string) => void;
}

interface UseAxiosResponse<T = any> {
  data: T | null;
  loading: boolean;
  error: string | null;
  execute: (config?: Partial<UseAxiosConfig<T>>) => Promise<void>;
  refetch: () => Promise<void>;
}

const useAPI = <T = any>({
  method,
  url,
  data,
  signal,
  onCompleted,
  onError,
}: UseAxiosConfig<T>): UseAxiosResponse<T> => {
  const [response, setResponse] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const execute = useCallback(
    async (config?: Partial<UseAxiosConfig<T>>) => {
      setLoading(true);
      setError(null);

      try {
        const axiosInstance = API({ signal: config?.signal || signal });
        let result: AxiosResponse<T>;

        switch (config?.method || method) {
          case 'GET':
            result = await axiosInstance.get(config?.url || url);
            break;
          case 'POST':
            result = await axiosInstance.post(config?.url || url, config?.data || data);
            break;
          case 'PUT':
            result = await axiosInstance.put(config?.url || url, config?.data || data);
            break;
          default:
            throw new Error('Unsupported request method');
        }

        setResponse(result.data);
        if (onCompleted) onCompleted(result.data);
      } catch (err: any) {
        const errorMessage = axios.isAxiosError(err) && err.response
          ? (err.response.data as string)
          : err.message;
        setError(errorMessage);
        if (onError) onError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [method, url, data, signal, onCompleted, onError]
  );

  const refetch = useCallback(() => {
    return execute();
  }, [execute]);

  return { data: response, loading, error, execute, refetch };
};

export default useAPI;
