import { gql } from "@apollo/client";

const Mutations = {
  getOTP: gql`
    mutation Mutation($mobile: String!) {
      getOTP(mobile: $mobile) {
        response {
          message
          status
          success
        }
        otp
        mobile
        expiresAt
      }
    }
  `,
  verifyOTP: gql`
    mutation VerifyOTP($mobile: String!, $otp: String!) {
      verifyOTP(mobile: $mobile, otp: $otp) {
        response {
          message
          status
          success
        }
        token
        user {
          id
          name
          mobile
          email
          role {
            name
          }
          isVerifiedEmail
          isVerifiedMobileNumber
          organizations {
            id
            name
            isActive
            address {
              addressLine
              city
            }
          }
        }
      }
    }
  `,
  addUser: gql`
    mutation Mutation($name: String!, $mobile: String!, $email: String!) {
      addUser(name: $name, mobile: $mobile, email: $email) {
        response {
          message
          status
          success
        }
        otp
        expiresAt
        mobile
      }
    }
  `,
  addInventory: gql`
    mutation AddInvenotry(
      $name: String!
      $brand: String!
      $hsn: String!
      $gst: Int!
      $discountPer: Int!
      $unitId: ID!
      $reorderPoint: Int!
      $barcode: String!
      $stockDistribution: [stockDistribution]
      $openingStock: Int
      $inventoryId: ID
      $inventoryUnits: [InventoryUnitInput]
    ) {
      addInventory(
        name: $name
        brand: $brand
        hsn: $hsn
        gst: $gst
        discountPer: $discountPer
        unitId: $unitId
        reorderPoint: $reorderPoint
        barcode: $barcode
        stockDistribution: $stockDistribution
        openingStock: $openingStock
        inventoryId: $inventoryId
        inventoryUnits: $inventoryUnits
      ) {
        status
        message
        success
      }
    }
  `,
  deleteInventory: gql`
    mutation DeleteInventory($inventoryId: ID!) {
      deleteInventory(inventoryId: $inventoryId) {
        status
        message
        success
      }
    }
  `,
  addOrganization: gql`
    mutation AddOrganization(
      $name: String!
      $address: AddressInput!
      $mobile: String!
      $email: String
      $gstin: String!
    ) {
      addOrganization(
        name: $name
        address: $address
        mobile: $mobile
        email: $email

        gstin: $gstin
      ) {
        status
        message
        success
      }
    }
  `,
  addShop: gql`
    mutation AddShop($name: String!, $address: AddressInput!, $shopId: ID) {
      addShop(name: $name, address: $address, shopId: $shopId) {
        status
        message
        success
      }
    }
  `,
  addGodown: gql`
    mutation AddGodown($name: String!, $address: AddressInput!, $godownId: ID) {
      addGodown(name: $name, address: $address, godownId: $godownId) {
        status
        message
        success
      }
    }
  `,
  addVendor: gql`
    mutation AddVendor(
      $vendorName: String!
      $vendorMobile: String!
      $vendorOrganizationName: String!
      $address: AddressInput!
      $vendorOrganizationMobile: String!
      $vendorOrganizationEmailAddress: String!
      $gstin: String
      $vendorEmail: String
      $vendorId: ID
    ) {
      addVendor(
        vendorName: $vendorName
        vendorMobile: $vendorMobile
        vendorOrganizationName: $vendorOrganizationName
        address: $address
        vendorOrganizationMobile: $vendorOrganizationMobile
        vendorOrganizationEmailAddress: $vendorOrganizationEmailAddress
        gstin: $gstin
        vendorEmail: $vendorEmail
        vendorId: $vendorId
      ) {
        status
        message
        success
      }
    }
  `,
  addPurchase: gql`
    mutation AddPurchase(
      $vendorId: ID!
      $amount: Int!
      $paidAmount: Int!
      $paymentMode: String!
      $products: [PurchaseProductList]!
    ) {
      addPurchase(
        vendorId: $vendorId
        amount: $amount
        paidAmount: $paidAmount
        paymentMode: $paymentMode
        products: $products
      ) {
        purchaseId
        response {
          message
          status
          success
        }
      }
    }
  `,
  deletePurchase: gql`
    mutation DeletePurchase($purchaseId: ID!) {
      deletePurchase(purchaseId: $purchaseId) {
        status
        message
        success
      }
    }
  `,
  addCustomer: gql`
    mutation AddCustomer(
      $customerName: String!
      $customerMobile: String!
      $address: AddressInput!
      $customerEmail: String
      $gstin: String
      $customerId: ID
    ) {
      addCustomer(
        customerName: $customerName
        customerMobile: $customerMobile
        address: $address
        customerEmail: $customerEmail
        gstin: $gstin
        customerId: $customerId
      ) {
        status
        message
        success
      }
    }
  `,
  addSales: gql`
    mutation AddSale(
      $customerId: ID!
      $amount: Int!
      $paidAmount: Int!
      $products: [SaleProductList]!
      $paymentMode: String!
      $invoiceId: ID!
      $termsAndCondition: String
      $dueDate: String
      $additionalCharges: [AdditionalSalesChargesInput]
      $shippingAddress: AddressInput
    ) {
      addSale(
        customerId: $customerId
        amount: $amount
        paidAmount: $paidAmount
        products: $products
        paymentMode: $paymentMode
        invoiceId: $invoiceId
        termsAndCondition: $termsAndCondition
        dueDate: $dueDate
        additionalCharges: $additionalCharges
        shippingAddress: $shippingAddress
      ) {
        response {
          message
          status
          success
        }
        saleId
      }
    }
  `,
  deleteSale: gql`
    mutation deleteSales($saleId: ID!) {
      deleteSale(saleId: $saleId) {
        status
        message
        success
      }
    }
  `,
  addCustomerPayments: gql`
    mutation AddCustomerPayment(
      $customerId: ID!
      $paidAmount: Int!
      $paymentMode: String!
      $saleId: ID
      $customerPaymentId: ID
    ) {
      addCustomerPayment(
        customerId: $customerId
        paidAmount: $paidAmount
        paymentMode: $paymentMode
        saleId: $saleId
        customerPaymentId: $customerPaymentId
      ) {
        message
        status
        success
      }
    }
  `,
  addCustomerRefund: gql`
    mutation AddCustomerRefund(
      $customerId: ID!
      $paidAmount: Int!
      $deletedSaleId: ID!
      $paymentMode: String!
      $customerRefundId: ID
    ) {
      addCustomerRefund(
        customerId: $customerId
        paidAmount: $paidAmount
        deletedSaleId: $deletedSaleId
        paymentMode: $paymentMode
        customerRefundId: $customerRefundId
      ) {
        status
        message
        success
      }
    }
  `,
  markSaleFullyPaid: gql`
    mutation Mutation($saleId: ID!, $customerId: ID!) {
      markFullyPaidSales(saleId: $saleId, customerId: $customerId) {
        status
        message
        success
      }
    }
  `,
  markPurchaseFullyPaid: gql`
    mutation MarkFullyPaidPurchase($purchaseId: ID!, $vendorId: ID!) {
      markFullyPaidPurchase(purchaseId: $purchaseId, vendorId: $vendorId) {
        status
        message
        success
      }
    }
  `,
  autoAdjustCustomerPayments: gql`
    mutation AutoAdjustPaymentSale($customerId: ID!) {
      autoAdjustPaymentSale(customerId: $customerId) {
        message
        status
        success
      }
    }
  `,
  deleteCustomerPayment: gql`
    mutation DeleteCustomerPayment($customerPaymentId: ID!) {
      deleteCustomerPayment(customerPaymentId: $customerPaymentId) {
        status
        message
        success
      }
    }
  `,
  addVendorPayments: gql`
    mutation AddVendorPayment(
      $vendorId: ID!
      $paidAmount: Int!
      $paymentMode: String!
      $purchaseId: ID
      $vendorPaymentId: ID
    ) {
      addVendorPayment(
        vendorId: $vendorId
        paidAmount: $paidAmount
        paymentMode: $paymentMode
        purchaseId: $purchaseId
        vendorPaymentId: $vendorPaymentId
      ) {
        status
        message
        success
      }
    }
  `,
  autoAdjustVendorPayments: gql`
    mutation AutoAdjustPaymentPurchase($vendorId: ID!) {
      autoAdjustPaymentPurchase(vendorId: $vendorId) {
        status
        message
        success
      }
    }
  `,
  deleteVendorPayment: gql`
    mutation DeleteVendorPayment($vendorPaymentId: ID!) {
      deleteVendorPayment(vendorPaymentId: $vendorPaymentId) {
        message
        status
        success
      }
    }
  `,
  addDiscardedStock: gql`
    mutation AddDiscardStock(
      $reason: String!
      $product: DiscardProdcutInput!
      $description: String
      $remark: String
      $url: String
      $inventoryUnitId: ID
    ) {
      addDiscardStock(
        reason: $reason
        product: $product
        description: $description
        remark: $remark
        url: $url
        inventoryUnitId: $inventoryUnitId
      ) {
        status
        message
        success
      }
    }
  `,
  deleteDiscardedStock: gql`
    mutation DeleteDiscardedStock($discardedStockId: ID!) {
      deleteDiscardedStock(discardedStockId: $discardedStockId) {
        status
        message
        success
      }
    }
  `,
  addStockMovement: gql`
    mutation addStockMovement(
      $product: DiscardProdcutInput!
      $assignedTo: ID
      $godownId: ID
      $shopId: ID
      $remark: String
      $inventoryUnitId: ID
    ) {
      addStockMovement(
        product: $product
        assignedTo: $assignedTo
        godownId: $godownId
        shopId: $shopId
        remark: $remark
        inventoryUnitId: $inventoryUnitId
      ) {
        status
        message
        success
      }
    }
  `,
  deleteStockMovement: gql`
    mutation DeleteStockMovement($stockMovementId: ID!) {
      deleteStockMovement(stockMovementId: $stockMovementId) {
        status
        message
        success
      }
    }
  `,
  addExpense: gql`
    mutation addExpense(
      $amount: Int!
      $categoryId: ID!
      $paymentMode: String!
      $expenseDate: DateTime!
      $recieptImageUrl: String
      $description: String
    ) {
      addExpense(
        amount: $amount
        categoryId: $categoryId
        paymentMode: $paymentMode
        expenseDate: $expenseDate

        recieptImageUrl: $recieptImageUrl
        description: $description
      ) {
        status
        message
        success
      }
    }
  `,
  deleteExpense: gql`
    mutation DeleteExpense($expenseId: ID!) {
      deleteExpense(expenseId: $expenseId) {
        status
        message
        success
      }
    }
  `,
  addSaleReturn: gql`
    mutation AddSaleReturn(
      $saleId: Int!
      $creditNoteNumber: ID!
      $paymentMode: String!
      $amount: Int!
      $products: [SaleReturnItemsInput]!
      $remark: String
      $refundAmount: Int
      $refund: Boolean
      $termsAndCondition: String
      $additionalCharges: [AdditionalSalesChargesInput]
    ) {
      addSaleReturn(
        saleId: $saleId
        creditNoteNumber: $creditNoteNumber
        paymentMode: $paymentMode
        amount: $amount
        products: $products
        remark: $remark
        refundAmount: $refundAmount
        refund: $refund
        termsAndCondition: $termsAndCondition
        additionalCharges: $additionalCharges
      ) {
        response {
          message
          status
          success
        }
        saleReturnId
      }
    }
  `,
  deleteSaleReturn: gql`
    mutation DeleteSaleReturn($returnedSaleId: ID!) {
      deleteSaleReturn(returnedSaleId: $returnedSaleId) {
        message
        status
        success
      }
    }
  `,
  addPurchaseReturn: gql`
    mutation AddPurchaseReturn(
      $purchaseId: Int!
      $amount: Int!
      $products: [PurchaseReturnItemsInput]!
      $remark: String
      $refund: Boolean
      $paymentMode: String!
      $termsAndCondition: String
      $additionalCharges: [AdditionalSalesChargesInput]
      $refundAmount: Int
      $purchaseReturnNumberId: ID!
    ) {
      addPurchaseReturn(
        purchaseId: $purchaseId
        amount: $amount
        products: $products
        remark: $remark
        refund: $refund
        paymentMode: $paymentMode
        termsAndCondition: $termsAndCondition
        additionalCharges: $additionalCharges
        refundAmount: $refundAmount
        purchaseReturnNumberId: $purchaseReturnNumberId
      ) {
        response {
          message
          status
          success
        }
        purchaseReturnId
      }
    }
  `,
  deletePurchaseReturn: gql`
    mutation DeletePurchaseReturn($returnedPurchaseId: ID!) {
      deletePurchaseReturn(returnedPurchaseId: $returnedPurchaseId) {
        status
        message
        success
      }
    }
  `,
  addEmployee: gql`
    mutation Mutation(
      $roleId: ID!
      $name: String!
      $mobile: String!
      $employeeId: ID
      $email: String
      $designation: String!
    ) {
      addEmployee(
        roleId: $roleId
        name: $name
        mobile: $mobile
        employeeId: $employeeId
        email: $email
        designation: $designation
      ) {
        status
        message
        success
      }
    }
  `,
  addPlanOrder: gql`
    mutation AddPlanOrder(
      $plans: [PlanPriceInput]!
      $isSubscription: Boolean!
      $couponCode: String
    ) {
      addPlanOrder(
        plans: $plans
        isSubscription: $isSubscription
        couponCode: $couponCode
      ) {
        response {
          message
          status
          success
        }
        order {
          id
          entity
          amount
          amountPaid
          amountDue
          currency
          receipt
          offerId
          status
          attempts
          createdAt
          planOrder
        }
      }
    }
  `,
  verifyPayments: gql`
    mutation VerfiyOrder(
      $planOrderId: ID!
      $paymentId: String!
      $signature: String!
      $method: String!
      $subscriptionId: ID
    ) {
      verfiyOrder(
        planOrderId: $planOrderId
        paymentId: $paymentId
        signature: $signature
        method: $method
        subscriptionId: $subscriptionId
      ) {
        status
        message
        success
      }
    }
  `,
  addVendorRefund: gql`
    mutation AddVendorRefund(
      $vendorId: ID!
      $paidAmount: Int!
      $deletedPurchaseId: ID!
      $paymentMode: String!
      $vendorRefundId: ID
    ) {
      addVendorRefund(
        vendorId: $vendorId
        paidAmount: $paidAmount
        deletedPurchaseId: $deletedPurchaseId
        paymentMode: $paymentMode
        vendorRefundId: $vendorRefundId
      ) {
        status
        message
        success
      }
    }
  `,
    addPlan: gql`
    mutation AddPlan(
      $name:String!,
      $features:[FeatureInput]!,
      $price:Int!,
      $duration:Int,
      $planDetails:[PlanDetailsInput]!,
      $description:String!,
      $isAddon:Boolean!,
      $isVisible:Boolean,
      $planId:ID
    ) 
    {
    addPlan(
    name: $name,
    features: $features,
    price: $price,
    planDetails: $planDetails,
    description: $description,
    isVisible: $isVisible,
    duration: $duration,
    planId: $planId,
    isAddon: $isAddon)
    {
    status
    message
    success
  }
}
  `,
};
export default Mutations;
