import React from 'react'

type StatusTagProps = {
    status: "success"|"warning"|"danger";
    label: string,
    customColor?: string
}
const Color = {
    success: "bg-green-200 text-green-600",
    warning: "bg-yellow-200 text-yellow-700",
    danger : "bg-red-200 text-red-600"
}
const StatusTag:React.FC<StatusTagProps> = ({status,label,customColor}) => {
  return (
    <div className={`rounded-full h-fit w-fit whitespace-nowrap px-4 py-1 text-sm ${customColor?`bg-${customColor}-200 text-${customColor}-600`:Color[status]}`}>{label}</div>
  )
}

export default StatusTag