import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ImagePaths } from "../Constants";

import { useDispatch } from "react-redux";
import { getLogout, selectUserName } from "../Redux/slices/authState";
import { useOnClickOutside } from "usehooks-ts";
import { useSelector } from "react-redux";
import { resetOrganization, selectOrganization } from "../Redux/slices/orgState";
import { RiArrowDownSLine, RiLogoutBoxLine, RiQuestionLine, RiSettings2Line, RiUser2Line } from "@remixicon/react";

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const userName = useSelector(selectUserName)
  return (
    <div
      className={`fixed top-0 printInvisible ${
        pathname === "/" ? "hidden" : "flex"
      } items-center bg-foreground px-16 py-4 w-full gap-12 z-50`}
    >
      <div className="flex items-center flex-[1] ml-8">
        <img className="w-32" src={ImagePaths.logo} alt="logo" />
      </div>
      
      <div className="flex gap-4 flex-[1] justify-end">
        <button className="hover:text-primary transition-colors ease-in-out">
          <RiQuestionLine />
        </button>
        <UserDropDown userName={userName} />
      </div>
    </div>
  );
};
interface UserDropDownProps{
  userName: string;
}
const UserDropDown: React.FC<UserDropDownProps> = ({userName}) => {
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState<Boolean>(false);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(getLogout());
    dispatch(resetOrganization())
  };
  useOnClickOutside(ref, () => setShowMenu(false));
  return (
    <div className="relative">
      <button
        onClick={() => setShowMenu(!showMenu)}
        className="flex items-center gap-3 text-sm font-bold hover:text-primary transition-colors ease-in-out"
      >
        <span className="bg-background rounded-lg p-1 hover:shadow shadow-secondary">
          <RiUser2Line />
        </span>
        <span className="flex items-center gap-1">
        {userName} <RiArrowDownSLine size={16} strokeWidth={3} />
        </span>
      </button>
      {/*drop down Menu */}
      {showMenu && (
        <div
          ref={ref}
          className="absolute top-full bg-foreground rounded border border-background shadow-sm mt-2 w-full slide-bottom"
        >
          <ul>
            <MenuItem
              label="Profile Settings"
              icon={<RiSettings2Line />}
              onClick={() => {navigate("/settings/my-details")}}
            />
            <MenuItem
              label="Logout"
              icon={<RiLogoutBoxLine />}
              onClick={logoutHandler}
            />
          </ul>
        </div>
      )}
    </div>
  );
};
interface MenuItemProps {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}
const MenuItem: React.FC<MenuItemProps> = ({ label, icon, onClick }) => {
  return (
    <li
      onClick={onClick}
      className="flex gap-2 text-sm hover:text-secondary p-2 cursor-pointer transition-colors ease-in-out"
    >
      {icon} {label}
    </li>
  );
};

export default Header;
