import React, { useEffect, useState } from 'react'
import { FormLoadingOverlay, PrimaryButton, SecondaryButton } from '../../../Components';
import { RiAddLine, RiArrowLeftCircleLine, RiArrowRightCircleLine, RiCloseLine } from '@remixicon/react';
import Input, { FormRow, SectionHeading } from '../../../Components/controls/Input';
import { useNavigate, useParams } from 'react-router-dom';
import useAPI from '../../../Hooks/useAPI';
import { PlansInterface } from '../../../Constants/Interfaces';
import { toast } from 'react-toastify';
import { useValidation } from '../../../Hooks';
import { handleChange } from '../../../Utils';
import { REGEX } from '../../../Constants';
import { ValidationConfig } from '../../../Hooks/useValidation';
import { Checkbox, CheckboxGroup } from 'rsuite';
import Mutations from "../../../GraphQl/Mutations";
import { useMutation, useQuery } from "@apollo/client";

interface PlansForm {
  planId: string,
  name: string,
  price: string,
  duration: number | undefined,
  planDetails: { key: string, value: boolean }[],
  features: { key: string, value: string }[],
  description: string,
  isAddon?: boolean,
  isVisible?: boolean
}


function PlansForm() {
  const { id } = useParams()
  const navigate = useNavigate()
  const handleBackButton = () => {
    navigate(-1);
  };
  const initialForm: PlansForm = {
    planId: id ? String(id) : "",
    name: "",
    price: "",
    duration: undefined,
    planDetails: [],
    features: [],
    description: "",
    isAddon: false,
    isVisible: false
  }

  const [form, setForm] = useState<PlansForm>({ ...initialForm })

  const { data, execute, loading } = useAPI<({ data: PlansInterface, success: boolean, message: string })>({
    method: "GET",
    url: `admin/plans/${id}`,
    onCompleted: ({ data,success, message }) => {
      if (success) {
        const planDetails: { key: string, value: boolean }[] = []
        for (const detail in data.planDetails) {
          planDetails.push({ key: detail, value: data.planDetails[detail] })
        }
        const features: { key: string, value: string }[] = []
        for (const feature in data.features) {
          features.push({ key: feature, value: data.features[feature] })
        }
        setForm({
          planId: id ? String(id) : "",
          name: String(data.name),
          price: String(data.price),
          duration: data.duration ? Number(data.duration) : undefined,
          planDetails: planDetails,
          features: features,
          description: String(data.description),
          isAddon: Boolean(data.isAddon),
          isVisible: Boolean(data.isVisible)
        })
        // toast.success(message)
      } else {
        toast.error(message)
      }
    },
    onError: (err: any) => {
      toast.error(err.message)
    }
  })

  const validationRules: ValidationConfig[] = [
    {
      input_path: "name",
      label_name: "Name",
      error_message: "Enter Valid Plan Name",
      input_required: true,
      regex: REGEX.Name.regex,
    },
    {
      input_path: "price",
      label_name: "Price",
      error_message: "Enter Valid Plan Price",
      input_required: true,
      regex: REGEX.Integers.regex,
    },
    {
      input_path: "duration",
      label_name: "Duration",
      error_message: "Enter Valid Plan Duration",
      input_required: false,
      regex: REGEX.Integers.regex,
    },
    {
      input_path: "description",
      label_name: "Description",
      error_message: "Enter Valid Plan Description",
      input_required: true,
      regex: REGEX.ANYTHING.regex,
    },
  ]

  const resetHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if(id){
      execute()
    }else{
      setForm({ ...initialForm })
    }
  }

  const addDetail = (e: any) => {
    setForm({ ...form, planDetails: [...form.planDetails, { key: "", value: false }] })
  }

  const addfeature = (e: any) => {
    setForm({ ...form, features: [...form.features, { key: "", value: "" }] })
  }

  const { triggerValidation, validationMessages } = useValidation(form, validationRules);
  const onChange = handleChange(form, setForm);

  useEffect(() => {
    if (id) {
      execute()
    }
  }, [])

  const [addPlan] = useMutation(Mutations.addPlan);

  const setDetail = (inputIndex: number) => {
    const newPlanDetails: { key: string, value: boolean }[] = form.planDetails.map((detail: { key: string, value: boolean }, index: number) => {
      if (inputIndex === index) {
        return ({
          key: detail.key,
          value: !detail.value
        })
      } else {
        return ({
          key: detail.key,
          value: detail.value
        })
      }
    })
    setForm({ ...form, planDetails: [...newPlanDetails] })
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const features: { key: string, value: number }[] = []
    for (const feature of form.features) {
      features.push({ key: feature.key, value: Number(feature.value) })
    }
    // alert(JSON.stringify(form))
    if (triggerValidation()) {
      addPlan({
        variables: {
          name:String(form.name),
          features:features,
          duration: form.duration ? Number(form.duration) : undefined,
          planDetails:form.planDetails,
          description:form.description,
          isAddon:form.isAddon,
          isVisible:form.isVisible,
          planId:form.planId,
          price: Number(form.price),
        },
        onCompleted: (data) => {
          if (data.addPlan.success) {
            toast.success(data.addPlan.message);
          } else {
            toast.error(data.addPlan.message);
          }
        },
        onError: ({ message }) => {
          // alert(JSON.stringify(message))
          // toast.error(message);
        },
      });
      navigate('/plans')
    }
  }



  return (
    <form className="relative flex flex-col space-y-3 p-8 rounded-xl bg-white w-11/12 mx-auto mt-14 sm:mt-20 lg:mt-24">
      <FormLoadingOverlay loading={loading} />
      <div className=" flex items-center flex-row space-x-2">
        <button onClick={handleBackButton} className=' hidden sm:block hover:text-secondary'>
          <RiArrowLeftCircleLine />
        </button>
        <h3>{id ? "Update Plan" : "Add Plan"}</h3>
      </div>
      <SectionHeading heading="" />
      <FormRow gap={4}>
        <Input
          name="name"
          label="Name"
          placeholder="Enter Plan Name"
          onChange={onChange()}
          validation={validationMessages["name"]}
          showRequired
          inputSize="quarter"
          value={form["name"] || ""}
        />
        <Input
          name="price"
          label="Plan Price"
          placeholder="Enter Plan Price"
          onChange={onChange()}
          validation={validationMessages.price}
          showRequired
          inputSize="quarter"
          value={form["price"]}
        />
        <Input
          name="duration"
          label="Plan Duration"
          placeholder="Enter Plan Duration"
          onChange={onChange()}
          validation={validationMessages.duration}
          inputSize="quarter"
          value={form["duration"]}
        />
        <Input
          name="description"
          label="Description"
          placeholder="Enter Plan Description"
          onChange={onChange()}
          validation={validationMessages.description}
          showRequired
          inputSize="quarter"
          value={form["description"]}
        />
      </FormRow>
      <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 '>
        {
          form.isVisible ?
          <>
           <Checkbox checked onClick={() => setForm({ ...form, isVisible: !form.isVisible })} name="isVisible" >Visibility</Checkbox>
          </>
          :
          <Checkbox onClick={() => setForm({ ...form, isVisible: !form.isVisible })} name="isVisible" >Visibility</Checkbox>
        }
        {
          form.isAddon ?
          <>
            <Checkbox checked onClick={() => setForm({ ...form, isAddon: !form.isAddon })} name="isAddon"  >Addon</Checkbox>
          </>
          :
          <>
             <Checkbox onClick={() => setForm({ ...form, isAddon: !form.isAddon })} name="isAddon"  >Addon</Checkbox>
          </>
        }
      </div>
      <div className=' flex'>
        <SectionHeading heading="Plan Features" />
        <span className=' text-secondary text-sm' onClick={addfeature}><RiAddLine /></span>
      </div>
      {
        form.features.map((feature: { key: string, value: string }, index: number) => {
          validationRules.push({
            input_path: `features.${index}.key`,
            label_name: feature.key,
            error_message: `Enter Valid plan feature name`,
            input_required: true,
            regex: REGEX.Name.regex,
          },
            {
              input_path: `features.${index}.value`,
              label_name: feature.key,
              error_message: `Enter Valid limitation of feature Plan`,
              input_required: true,
              regex: REGEX.Integers.regex,
            }
          )
          return (
            <FormRow gap={4}>
              <Input
                name={`features.${index}.key`}
                label="Feature Name"
                placeholder="Enter Feature Name"
                onChange={onChange()}
                validation={validationMessages[`features.${index}.key`]}
                showRequired
                inputSize="half"
                value={form.features[index].key}
              />
              <Input
                name={`features.${index}.value`}
                label="Feature Limitation"
                placeholder="Enter Feature Limitation"
                onChange={onChange()}
                validation={validationMessages[`features.${index}.value`]}
                showRequired
                inputSize="half"
                value={form.features[index].value}
              />
            </FormRow>
          )
        })
      }
      <div className=' flex'>
        <SectionHeading heading="Plan Details" />
        <span className=' text-secondary text-sm' onClick={addDetail}><RiAddLine /></span>
      </div>
      {
        form.planDetails.map((planDetail: { key: string, value: boolean }, index: number) => {
          validationRules.push({
            input_path: `planDetails.${index}.key`,
            label_name: planDetail.key,
            error_message: `Enter Valid Plan Detail`,
            input_required: true,
            regex: REGEX.Name.regex,
          },
          )
          return (
            <FormRow gap={4}>
              <Input
                name={`planDetails.${index}.key`}
                label=""
                placeholder={`Enter Detail`}
                onChange={onChange()}
                validation={validationMessages[`planDetails.${index}.key`]}
                inputSize="half"
                value={form.planDetails[index].key}
              />
              <div className=' flex items-center'>
                {planDetail.value ? 
                <>
                 <Checkbox checked onClick={() => setDetail(index)} ></Checkbox>
                </> :
                <>
                 <Checkbox onClick={() => setDetail(index)} ></Checkbox>
                </>
                }
              </div>
            </FormRow>
          )
        })
      }
      <div className="flex justify-end items-center gap-4">
        <SecondaryButton icon={<RiCloseLine />} title="Reset"
          callback={resetHandle}
        />
        <PrimaryButton
          icon={<RiArrowRightCircleLine />}
          title="Submit"
          callback={handleSubmit}
        />
      </div>
    </form>
  )

}

export default PlansForm