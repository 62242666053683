import { PreventedRoute } from "../Utils";
import RoleBasedRoute from "../Utils/AccessCheckedRoute";
import { Access, RoutesInterface } from "./Interfaces";
import { Dashboard, Fallback, Login, LeadIndex, OrganizationFrom, OrganizationDetails, SubscrptionsDetails, OrganizationIndex, UserDetails, UserForm, UserIndex, LeadForm, LeadDetails, DealerIndex, RewardIndex, RewardDeatils, CouponCodeIndex, CouponCodeForm, CouponCodeDetails, PlanOrderIndex, RewardForm, PlansIndex, PlansDetails, PlansForm, SubscriptionIndex, SubscriptionFrom, PaymentIndex } from "../pages";
import { RiBankCardLine, RiCalendarScheduleLine, RiCoupon2Line, RiCoupon3Line, RiGiftLine, RiGroup2Line, RiHome2Line, RiMoneyRupeeCircleLine, RiStoreLine, RiSurveyLine, RiTeamLine, RiUser2Line, RiUserLine, RiUserSearchLine, RiVipDiamondLine } from "@remixicon/react";
import DealerForm from "../pages/protected/Dealers/DealerForm";
import DealerDetails from "../pages/protected/Dealers/DealerDetails";
import PlanOrderDetails from "../pages/protected/PlanOrders/PlanOrderDetails";


const ROUTES: RoutesInterface[] = [
  {
    path: "/",
    component: () => (
      <PreventedRoute>
        <Login />
      </PreventedRoute>
    ),
    icon: <></>,
    is_nav: false,
    label: "login",
  },
  {
    path: "/forbidden",
    component: () => <Fallback />,
    icon: <></>,
    is_nav: false,
    label: "forbidden",
  },
  {
    path: "/dashboard",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/dashboard"
        access={[Access.ANY]}
        element={() => <Dashboard />}

      />
    ),
    icon: <RiHome2Line />,
    is_nav: true,
    label: "Home",
  },
  {
    path: "/user",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/user"
        access={[Access.ANY]}
        element={() => <UserIndex />}

      />
    ),
    icon: <RiUserLine />,
    is_nav: true,
    label: "User",
  },
  {
    path: "/user/add",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/user/add"
        access={[Access.ANY]}
        element={() => <UserForm />}

      />
    ),
    icon: <RiUserLine />,
    is_nav: false,
    label: "User",
  },
  {
    path: "/user/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/user/update/:id"
        access={[Access.ANY]}
        element={() => <UserForm />}

      />
    ),
    icon: <RiUserLine />,
    is_nav: false,
    label: "User",
  },
  {
    path: "/user/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/user/details/:id"
        access={[Access.ANY]}
        element={() => <UserDetails />}

      />
    ),
    icon: <RiUserLine />,
    is_nav: false,
    label: "User",
  },
  {
    path: "/organizations",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/organizations"
        access={[Access.ANY]}
        element={() => <OrganizationIndex />}

      />
    ),
    icon: <RiStoreLine />,
    is_nav: true,
    label: "Organizations",
  },
  {
    path: "/organizations/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/organizations/update/:id"
        access={[Access.ANY]}
        element={() => <OrganizationFrom />}
      />
    ),
    icon: <RiStoreLine />,
    is_nav: false,
    label: "Organizations",
  },
  {
    path: "/organizations/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/organizations/details/:id"
        access={[Access.ANY]}
        element={() => <OrganizationDetails />}

      />
    ),
    icon: <RiStoreLine />,
    is_nav: false,
    label: "Organizations",
  },
  {
    path: "/organizations/add",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/organization/add"
        access={[Access.ANY]}
        element={() => <OrganizationFrom />}
      />
    ),
    icon: <RiStoreLine />,
    is_nav: false,
    label: "Organizations",
  },
  {
    path: "/subscriptions/details/:id/:orgId",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/subscriptions/details/:id/:orgId"
        access={[Access.ANY]}
        element={() => <SubscrptionsDetails />}

      />
    ),
    icon: <RiVipDiamondLine />,
    is_nav: false,
    label: "Subscriptions",
  },
  {
    path: "/subscriptions/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/subscriptions/details/:id"
        access={[Access.ANY]}
        element={() => <SubscrptionsDetails />}

      />
    ),
    icon: <RiVipDiamondLine />,
    is_nav: false,
    label: "Subscriptions",
  },
  {
    path: "/subscriptions",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/subscriptions"
        access={[Access.ANY]}
        element={() => <SubscriptionIndex />}

      />
    ),
    icon: <RiCalendarScheduleLine/>,
    is_nav: true,
    label: "Subscriptions",
  },
  {
    path: "/subscriptions/add",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/subscriptions/add"
        access={[Access.ANY]}
        element={() => <SubscriptionFrom />}

      />
    ),
    icon: <RiCalendarScheduleLine/>,
    is_nav: false,
    label: "Subscriptions",
  },
  {
    path: "/subscriptions/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/subscriptions/update/:id"
        access={[Access.ANY]}
        element={() => <SubscriptionFrom />}

      />
    ),
    icon: <RiCalendarScheduleLine/>,
    is_nav: false,
    label: "Subscriptions",
  },
  {
    path: "/leads",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/leads"
        access={[Access.ANY]}
        element={() => <LeadIndex />}
      />
    ),
    icon: <RiUserSearchLine />,
    is_nav: true,
    label: "Leads",
  },
  {
    path: "/lead/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/lead/update/:id"
        access={[Access.ANY]}
        element={() => <LeadForm />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Leads",
  },
  {
    path: "/lead/add",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/lead/add"
        access={[Access.ANY]}
        element={() => <LeadForm />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Leads",
  },
  {
    path: "/lead/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/lead/details/:id"
        access={[Access.ANY]}
        element={() => <LeadDetails />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Leads",
  },
  {
    path: "/dealers",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/dealers"
        access={[Access.ANY]}
        element={() => <DealerIndex />}
      />
    ),
    icon: <><RiUser2Line/></>,
    is_nav: true,
    label: "Dealers",
  },
  {
    path: "/dealers/add",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/dealers/add"
        access={[Access.ANY]}
        element={() => <DealerForm />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Dealers Add",
  },
  {
    path: "/dealers/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/dealers/details/:id"
        access={[Access.ANY]}
        element={() => <DealerDetails />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Dealers Details",
  },
  {
    path: "/dealers/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/dealers/update/:id"
        access={[Access.ANY]}
        element={() => <DealerForm />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Dealers Update",
  },
  {
    path: "/rewards",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/rewards"
        access={[Access.ANY]}
        element={() => <RewardIndex />}
      />
    ),
    icon: <><RiGiftLine/></>,
    is_nav: true,
    label: "Rewards",
  },
  {
    path: "/rewards/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/rewards/details/:id"
        access={[Access.ANY]}
        element={() => <RewardDeatils />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Rewards",
  },
  {
    path: "/rewards/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/rewards/update/:id"
        access={[Access.ANY]}
        element={() => <RewardForm />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Rewards",
  },
  {
    path: "/orders",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/orders"
        access={[Access.ANY]}
        element={() => <PlanOrderIndex />}
      />
    ),
    icon: <RiSurveyLine/>,
    is_nav: true,
    label: "Plan Orders",
  },
  {
    path: "/orders/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/orders/details/:id"
        access={[Access.ANY]}
        element={() => <PlanOrderDetails />}
      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Plan Orders",
  },
  {
    path: "/plans",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/plans"
        access={[Access.ANY]}
        element={() => <PlansIndex/>}

      />
    ),
    icon: <RiVipDiamondLine />,
    is_nav: true,
    label: "Plans",
  },
  {
    path: "/plans/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/Plans"
        access={[Access.ANY]}
        element={() => <PlansDetails/>}

      />
    ),
    icon: <RiVipDiamondLine />,
    is_nav: false,
    label: "Plans",
  },
  {
    path: "/plans/add",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/Plans"
        access={[Access.ANY]}
        element={() => <PlansForm/>}

      />
    ),
    icon: <RiVipDiamondLine />,
    is_nav: false,
    label: "Plans",
  },
  {
    path: "/plans/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/Plans"
        access={[Access.ANY]}
        element={() => <PlansForm/>}

      />
    ),
    icon: <RiVipDiamondLine />,
    is_nav: false,
    label: "Plans",
  },
  {
    path: "/payments",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/payments"
        access={[Access.ANY]}
        element={() => <PaymentIndex/>}
      />
    ),
    icon: <RiMoneyRupeeCircleLine />,
    is_nav: true,
    label: "Payments",
  },
  {
    path: "/Expense",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/Expense"
        access={[Access.ANY]}
        element={() => < ></>}

      />
    ),
    icon: <RiBankCardLine />,
    is_nav: true,
    label: "Expense",
  },
  {
    path: "/coupons",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/coupons"
        access={[Access.ANY]}
        element={() => < ><CouponCodeIndex/></>}

      />
    ),
    icon: <RiCoupon2Line />,
    is_nav: true,
    label: "Coupons",
  },
  {
    path: "/coupons/add",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/coupons/add"
        access={[Access.ANY]}
        element={() => < ><CouponCodeForm/></>}

      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Coupons",
  },
  {
    path: "/coupons/update/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/coupons/update/:id"
        access={[Access.ANY]}
        element={() => < ><CouponCodeForm/></>}

      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Coupons Update",
  },
  {
    path: "/coupons/details/:id",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/coupons/details/:id"
        access={[Access.ANY]}
        element={() => < ><CouponCodeDetails/></>}

      />
    ),
    icon: <></>,
    is_nav: false,
    label: "Coupons Details",
  },
  {
    path: "/employees",
    component: () => (
      <RoleBasedRoute
        fallbackPath="/forbidden"
        path="/employees"
        access={[Access.ANY]}
        element={() => < ></>}

      />
    ),
    icon: <RiTeamLine />,
    is_nav: true,
    label: "Employees",
  },
];
export const getNavigationRoutes = (): RoutesInterface[] => {
  return ROUTES.filter((route) => route.is_nav);
};
export default ROUTES;
