export interface RoutesInterface {
  path: string;
  component: any;
  label: string;
  icon: React.ReactNode;
  is_nav: boolean;
}

export interface RoleBasedRouteProps {
  path: string;
  element: React.ComponentType;
  access: Access[];
  fallbackPath: string;
  orgRequired?: boolean;
  subscriptionRequired?: boolean;
}

export enum Access {
  "ANY",
  "DEALER",
  "SUPERADMIN",
  "OWNER",
  "MANAGER",
  "LABOUR",
  "PREVENTED ",
}

export interface CarousalProps {
  Slides: slide[];
  interval: number;
}
export interface slide {
  heading: string;
  subHeading: string;
  img: any;
}
export interface TableColumn {
  header: string;
  accessor: string;
  render?: (rowData: any, index?: number) => React.ReactNode;
}
export interface Address {
  id?: string | null;
  addressLine: string;
  city: string;
  state: string;
  pincode: string;
}
export interface godown {
  id?: string;
  name?: string;
  address?: Address;
  dateCreated?: string;
}
export interface shop {
  id?: string;
  name?: string;
  address?: Address;
  dateCreated?: string;
}
export interface batchInterface {
  id?: string | null;
  name: string;
  purchaseAmount: number;
  saleAmount: number;
  expiryDate?: string;
}
export interface GodownDistribution {
  name?: string;
  maxStock?: number;
  godownId: string;
  quantity: number;
  cost?: number;
}
export interface ShopDistribution {
  name?: string;
  quantity: number;
  maxStock?: number;
  cost?: number;
}
export interface ShopDistributionInput {
  quantity: number;
}
export interface GodownDistributionInput {
  godownId: string;
  quantity: number;
}
export interface StockDistributionInput {
  batch?: batchInterface;
  shopDistribution: ShopDistributionInput;
  godownDistribution: GodownDistributionInput[];
}
export interface StockDistribution {
  batch?: batchInterface;
  shopDistribution: ShopDistribution;
  godownDistribution: GodownDistribution[];
}

export interface Page {
  skip: number;
  take: number;
  totalRecords: number;
}
export interface SortInterface {
  name: string | null;
  orderby: "ASC" | "DESC" | null;
}

export interface CustomerPaymentsInterface {
  orgId: string;
  customerId: string;
  paidAmount: number;
  paymentMode: string;
  id: string;
  sales?: {
    id: string;
    invoiceNumber: {
      invoiceNumber: string;
    };
  };
  deletedSales?: {
    id: string;
    invoiceNumber: {
      invoiceNumber: string;
    };
  };
}
export interface VendorInterface {
  id?: string | null;
  orgId?: string;
  vendorName: string;
  vendorMobile: string;
  vendorEmail?: string;
  vendorOrganizationName: string;
  vendorOrganizationAddress: Address;
  vendorOrganizationMobile: string;
  vendorOrganizationEmailAddress: string;
  roleId: string;
  gstin?: string;
}
export interface VendorPaymentsInterface {
  orgId: string;
  vendorId: string;
  paidAmount: number;
  paymentMode: string;
  id: string;
  purchase?: {
    id: string;
  };
  deletedPurchase: {
    id: string;
  };
}

export interface StockMovementHistoryProps {
  id?: string;
  assignedBy: {
    name: string;
  };
  assignedTo?: {
    user: {
      name: string;
    };
  };
  godown: godown;
  shop: {
    name: string;
  };
  inventoryUnit?: InventoryUnitsInterface | null,
  product: {
    batch: {
      id: string;
      name: string;
    };
    inventory: {
      name: string;
      unit: {
        shortHand: string;
      };
    };
  };
  quantity: string;
  remark: string;
  dateCreated: string;
}
export interface AdditionalCharges {
  name: string;
  charges: number;
}
export interface ExpenseCategory {
  id: string;
  category: string;
  isActive?: boolean;
  isDeleted?: boolean;
}
export interface ExpenseType {
  id: string;
  addedBy: {
    name: string;
    role: string | null;
  };
  amount: number;
  category: ExpenseCategory;
  description: string;
  paymentMode: string;
  recieptImageUrl?: string | null;
  expenseDate: string;
  dateCreated: string;
}
export interface ExpenseInput {
  expenseId?: string;
  amount: number;
  categoryId: string;
  category?: string;
  description: string;
  paymentMode: string;
  recieptImageUrl?: string | null;
  expenseDate: string;
}
export interface SaleItem {
  id?: string;
  batch?: {
    id: string;
    batchName: string;
  };
  product: {
    id: string;
    name: string;
    hsn: string;
    unit: {
      name: string;
      shortHand: string;
    };
    expireDate: string;
    batchNo: string;
  };
  inventoryUnit?: {
    id: string;
    unit: {
      shortHand: string;
    }
    conversionMultiple: number;
  };
  salePrice: number;
  saleTax: number;
  saleDiscount: number;
  quantity: number;
}
export interface PurchaseItem {
  id?: string;
  batch?: {
    id: string;
    batchName: string;
  };
  product: {
    id: string;
    name: string;
    hsn: string;
    unit: {
      name: string;
      shortHand: string;
    };
    expireDate: string;
    batchNo: string;
  };
  inventoryUnit: InventoryUnitsInterface;
  purchasePrice: number;
  purchaseTax: number;
  purchaseDiscount: number;
  quantity: number;
}

export interface DisitributionStatus {
  success: boolean;
  status: "Success" | "Warning" | "Error" | "Resolved";
  message: string;
  color: "green-500" | "yellow-500" | "red-500";
}

export interface AutoDistributeResult {
  shopDistribution: ShopDistribution;
  godownDistribution: GodownDistribution[];
  status: DisitributionStatus;
}
export interface FormattedItemList {
  name: string;
  hsn: string;
  price: number;
  quantity: number;
  discount: number;
  tax: number;
  unitShortHand: string;
}
export interface InvoicePdfProps {
  organization: {
    name: string;
    address: Address;
    gstin: string;
  };
  customer: {
    name: string;
    billAddress?: Address;
    shippingAddress?: Address;
    gstin?: string;
  };
  terms: string[];
  QRUrl: string;
  saleItems: saleItem[];
  summary: SummaryRowProps[];
}
export interface SummaryRowProps {
  label: string;
  value: string;
}
export interface saleItem {
  product: {
    id: string;
    name: string;
    hsn: string;
    unit: {
      name: string;
      shortHand: string;
    };
    expireDate: string;
    batchNo: string;
  };
  salePrice: number;
  saleTax: number;
  saleDiscount: number;
  quantity: number;
}
export interface PlansInterface {
  id: string;
  name: string;
  description: string;
  duration: number | null;
  planDetails: any;
  features: any;
  isAddon: boolean;
  price: number;
  isVisible: boolean;
}
export interface subscriptionInterface {
  id: string;
  validity: string;
  startDate: string,
  addOn: any;
  isActive: false;
  plan?: PlansInterface;
  dateCreated: string;
}
export interface PlanOrderInterface {
  id: string;
  amount: number;
  isPaid: boolean;
  discount:number;
  orderId: string;
  receipt: string;
  tax: number;
  dateCreated: string;
}

export interface CartItemInterface {
  planId: string;
  price: number;
  description: string;
  isAddon: boolean;
  duration: number | null;
  name: string;
}

export interface OrderInput {
  plans: { planId: string }[];
  isSubscription: boolean;
  couponCode: string | null

}
export interface HSNSearchResult {
  description: string;
  hsnCode: string;
  rate: number;
}
export interface InventoryUnitsInterface {
  id: string | null;
  conversionMultiple: number;
  price: number;
  unit: UnitInterface;
}
export interface UnitInterface {
  id: string;
  shortHand: string;
  name: string;
}

export interface IUser {
  id: string;
  name: string;
  mobile: string;
  email: string;
  role: Role;
  organizations: Organization[];
  dateCreated: string;
  isVerifiedEmail: boolean;
  isVerifiedMobileNumber: boolean;
  isDeleted: boolean;
}

export interface IUserInput {
  userId: string;
  name: string;
  mobile: string;
  email: string;
  roleId: number;
  dateCreated: string;
  isVerifiedEmail: boolean;
  isVerifiedMobileNumber: boolean;
  isDeleted: boolean;
}
export interface Role {
  id: number;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
}

export interface Organization {
  id: string;
  name: string;
  address: Address;
  renewDate: string;
  dateCreated: string;
  invoiceTemplate: number;
  mobile: string;
  termsAndCondition: string | null;
  emailAddress: string | null;
  gstin: string;
  isActive: boolean;
  isDeleted: boolean;
  license: string | null;
}

export interface LeadsInterface {
  id: number;
  name?: string
  mobile?: string
  email?: string
  location?: string
  status: string
  source?: string
  user?: IUser
}

export interface DealerInterface {
  id: number;
  user: IUser
  area?: string
  organizationName?: string
  gstin?: string
  isActive: boolean
  isDeleted: boolean
  dateCreated: string
}

export interface RewardTrackerInterface {
  id:number;
  dealer:DealerInterface;
  reward:number;
  bonus:number;
  isIssued:boolean
}

export interface CouponCodeInterface {
  id: number;
  createdBy: IUser;
  code: string;
  discountPer?: number;
  discountFlat?: number;
  description: string;
  upto: number;
  minOrderAmount: number;
  isActive: boolean;
  dateCreated: Date;
  validTill: Date;
  useLimit: number;
  plan:PlansInterface;
}

export interface UserPaymentInterface {
  id: number;
  user:IUser,
  amount: number;
  paymentId: string;
  orderId: string;
  method: string;
  dateCreated: string;
}
