import { ApolloProvider } from "@apollo/client";
import "./App.css";
import client from "./GraphQl/GraphClient";
import { useSelector } from "react-redux";
import { selectAccessToken, selectIsTokenExpired } from "./Redux/slices/authState";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Header, Navbar, ReAuthModal } from "./Components";
import { ROUTES } from "./Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import 'rsuite/dist/rsuite.min.css';
import { selectOrganization } from "./Redux/slices/orgState";


function App() {
  const token = useSelector(selectAccessToken);
  const org = useSelector(selectOrganization)
  const tokenExpired = useSelector(selectIsTokenExpired)
  return (
    <ApolloProvider client={client(token,org?.id)}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Header/>
        <Navbar/>
        {tokenExpired&&<ReAuthModal/>}
        <div className="w-full pt-20">
          <div className="w-11/12 pl-8 mx-auto">
        <Routes>
          {ROUTES.map(({ path, component: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
        </div>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
