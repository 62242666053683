import { RiLoader3Line, RiLoaderLine } from '@remixicon/react'
import React from 'react'

type Props = {
    loading:boolean;
}

const FormLoadingOverlay = ({loading}: Props) => {
    if(loading) return (
    <div className='w-full h-full absolute inset-0 backdrop-blur-sm flex flex-col items-center justify-center z-40'>
        <RiLoaderLine className='animate-spin text-primary' size={100}/>
       <span className='text-2xl font-semibold'>Loading Please Wait...</span> 
    </div>
  )
  return <></>
}

export default FormLoadingOverlay