import { RiArrowLeftCircleLine, RiBarChartFill, RiBroadcastLine, RiCloseLine, RiEdit2Line, RiGameLine, RiMailLine, RiMapPinLine, RiMessageLine, RiPhoneLine, RiUser2Fill, RiUser2Line, RiUserLocationLine, RiUserSearchLine } from "@remixicon/react"
import { useNavigate, useParams } from "react-router-dom"
import useAPI from "../../../Hooks/useAPI"
import { IUser, LeadsInterface } from "../../../Constants/Interfaces"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { SectionHeading } from "../../../Components/controls/Input"
import { SecondaryButton, SecondaryLinkButton } from "../../../Components"


const LeadDetails = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const { data, execute, loading } = useAPI<{ data: LeadsInterface, success: boolean, message: string }>({
        method: 'GET',
        url: `admin/leads/${id}`,
        onCompleted: ({ success, message }) => {
            if (!success) {
                toast.error(message)
            }
        },
        onError: (error: any) => {
            toast.error(error)
        },
    })

    const handleBackButton = () => {
        navigate(-1);
    };

    useEffect(() => {
        execute()
    }, [])

    const {
        user,
        name,
        mobile,
        source,
        status,
        email,
        location,
    } = data?.data || {}



    return (
        <div className=" w-3/4 mx-auto bg-foreground p-8 flex flex-col space-y-5 mt-10 sm:mt-28 rounded-lg">
            {/* heading  */}
            <div className=' w-full text-center sm:grid sm:grid-cols-3 '>
                <button onClick={handleBackButton} className=' hidden sm:block hover:text-secondary'>
                    <RiArrowLeftCircleLine />
                </button>
                <div className=' flex justify-center items-center space-x-2'>
                    <span className='text-3xl font-bold'>Lead</span>
                    <button role='button' className=' sm:hidden text-xm text-disabled hover:text-secondary'><RiEdit2Line /></button>
                </div>
                <span className=' hidden sm:flex w-full justify-end'>
                    <SecondaryLinkButton
                        to={`/lead/update/${id}`}
                        title="Edit"
                        icon={<RiEdit2Line />}
                    />
                </span>
            </div>
            {/* lead Details */}
            <div className=" flex flex-col space-y-2">
                <SectionHeading heading="Lead Deatils" />
                <div className=" grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className=" flex flex-row space-x-2 items-center">
                        <span className=" text-primary"><RiUserSearchLine /></span>
                        <span>{name || "Not Provide"}</span>
                    </div>
                    <div className=" flex flex-row space-x-2 items-center">
                        <span className=" text-primary"><RiMailLine /></span>
                        <span>{email || "Not Provide"}</span>
                    </div>
                    <div className=" flex flex-row space-x-2 items-center">
                        <span className=" text-primary"><RiPhoneLine /></span>
                        <span>{mobile || "Not Provide"}</span>
                    </div>
                    <div className=" flex flex-row space-x-2 items-center">
                        <span className=" text-primary"><RiMapPinLine /></span>
                        <span>{location || "Not Provide"}</span>
                    </div>
                    <div className=" flex flex-row space-x-2 items-center">
                        <span className=" text-primary"><RiBarChartFill /></span>
                        <span>{status}</span>
                    </div>
                    <div className=" flex flex-row space-x-2 items-center">
                        <span className=" text-primary"><RiBroadcastLine /></span>
                        <span>{source || "Not Provide"}</span>
                    </div>
                    <div className=" flex flex-row space-x-2 items-center">
                        <span className=" text-primary"><RiUser2Line /></span>
                        <button role="button" className=" hover:text-secondary" onClick={() => user?.name ?  navigate('/leads'): null }>{user?.name || "Not Provided"}</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default LeadDetails