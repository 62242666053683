import React from 'react';

interface PaginationProps {
  page: {
    skip: number,
    take: number,
    totalRecords: number,
  }
  onPageChange: React.Dispatch<React.SetStateAction<{ skip: number; take: number,totalRecords: number }>>;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  onPageChange,
}) => {
  const {totalRecords,skip,take} = page;
  const totalPages = Math.ceil(totalRecords / take);
  //Current Page
  const currentPage = ()=>Math.ceil(skip/take);

  // Calculate startPage and endPage based on currentPage and totalPages
  let startPage = Math.max(1, currentPage() - 2);
  let endPage = Math.min(totalPages, currentPage() + 2);


  if (totalPages > 5) {
    if (currentPage() <= 3) {
      endPage = 5;
    } else if (currentPage() + 2 >= totalPages) {
      startPage = totalPages - 4;
    }
  }

  const handlePageChange = (page: number) => {
    const skip = (page - 1) * take;
    onPageChange({ skip, take,totalRecords  });
  };

  const handlePrevious = () => {
    if (currentPage() > 1) {
      const skip = (currentPage() - 2) * take;
      onPageChange({ skip, take, totalRecords });
    }
  };

  const handleNext = () => {
    if (currentPage() < totalPages) {
      const skip = currentPage() * take;
      onPageChange({ skip, take,totalRecords });
    }
  };

  return (
    <div className="flex-1 flex w-full items-center justify-end gap-4">
      <button onClick={handlePrevious} disabled={currentPage() === 1}>
        &lt; {/* Previous arrow */}
      </button>

      {startPage > 1 && (
        <button
          className="px-3 py-1 rounded"
          onClick={() => handlePageChange(1)}
        >
          1
        </button>
      )}

      {startPage > 2 && <span>...</span>}

      {Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
        const page = startPage + index;
        return (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-1 rounded ${
              currentPage()+1 === page ? "text-primary font-bold" : ""
            }`}
          >
            {page}
          </button>
        );
      })}

      {endPage < totalPages - 1 && <span>...</span>}

      {endPage < totalPages && (
        <button onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>
      )}

      <button onClick={handleNext} disabled={currentPage() === totalPages}>
        &gt; {/* Next arrow */}
      </button>
    </div>
  );
};

export default Pagination;
