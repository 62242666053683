import { createSlice } from "@reduxjs/toolkit";
import { CartItemInterface, OrderInput } from "../../Constants/Interfaces";
const initialState: stateProps = {
  cart: [],
  cartTotal: 0,
  coupon: null,
  couponValid: null,
};
interface stateProps {
  cart: CartItemInterface[];
  cartTotal: number;
  coupon: string | null;
  couponValid: boolean | null;
}

export const planCartSlice = createSlice({
  name: "planCart",
  initialState,
  reducers: {
    addCartItem: (state, action: { payload: CartItemInterface }) => {
      const cartItem = action.payload;
      state.cart = [...state.cart, cartItem];
      state.cartTotal += cartItem.price;
    },
    removeCartItem: (state, action: { payload: { index: number } }) => {
      const index = action.payload.index;
      state.cartTotal -= state.cart[index].price;
      const newList = state.cart.filter((item, i) => index !== i);
      state.cart = newList;
    },
    resetCart: (state, action) => {
      state.cart = initialState.cart;
      state.cartTotal = initialState.cartTotal;
    },
  },
});

export const selectCartItems = (state: any) => state.planCart.cart;
export const selectCartTotal = (state: any) => state.planCart.cartTotal;
export const selectOrderPayload = (state: any) => {
  const payload: OrderInput = {
    couponCode: state.planCart.couponCode,
    isSubscription: state.planCart.cart.some(
      (item: CartItemInterface) => item.isAddon === false
    ),
    plans:
      state.planCart.cart.map((item: CartItemInterface) => {
        return { planId: item.planId };
      }),
  };

  return payload;
};

export const { addCartItem, removeCartItem, resetCart } = planCartSlice.actions;
export default planCartSlice.reducer;
