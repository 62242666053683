import { toast } from "react-toastify";
import { Organization, PlanOrderInterface, PlansInterface, UserPaymentInterface } from "../../../Constants/Interfaces";
import useAPI from "../../../Hooks/useAPI";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { RiArrowLeftCircleLine, RiEdit2Line } from "@remixicon/react";
import { SecondaryLinkButton } from "../../../Components";
import { SectionHeading } from "../../../Components/controls/Input";
import moment from "moment";



interface PlanOrderWithSubscriptionInterface extends PlanOrderInterface {
    subscription?: {
        id: number;
        plan:PlansInterface,
        organization: Organization
    },
    organization: Organization,
    planOrderItems: {
        plan: PlansInterface
    }[],
    userPayment: UserPaymentInterface
}


const PlanOrderDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { data, error, execute, loading, refetch } = useAPI<{ data: PlanOrderWithSubscriptionInterface, message: string, success: boolean, totalRecords: number }>({
        method: "GET",
        url: `/admin/orders/${id}`,
        onCompleted: (data) => {
        },
        onError: (err: any) => {
            toast.error(err)
        }
    });

    const {
        amount,
        isPaid,
        discount,
        orderId,
        receipt,
        organization,
        tax,
        dateCreated,
        subscription,
        userPayment,
        planOrderItems
    } = data?.data || {}

    const handleBackButton = () => {
        navigate(-1);
    };
    console.log(planOrderItems)
    useEffect(() => {
        execute()
    }, [])

    return (
        <div className='w-11/12 mx-auto rounded-xl bg-foreground flex flex-col space-y-5 mt-10 sm:mt-28  p-10 lg:p-5'>
            {/* <div style={{ width: "96%" }} className='mx-auto  '> */}
            {/* Reward Heading*/}
            <div className=' w-full text-center sm:grid sm:grid-cols-3'>
                <button onClick={handleBackButton} className=' hidden sm:block hover:text-secondary'>
                    <RiArrowLeftCircleLine />
                </button>
                <div className=' flex justify-center items-center space-x-2'>
                    <span className='text-3xl text-primary font-bold'>{"Plan Order"}</span>
                    <button role='button' className=' sm:hidden text-xm text-disabled hover:text-secondary'><RiEdit2Line /></button>
                </div>
                {/* <span className=' hidden sm:flex w-full justify-end'>
                    <SecondaryLinkButton
                        to={`/organizations/update/${id}`}
                        title="Edit"
                        icon={<RiEdit2Line />}
                    />
                </span> */}
            </div>
            {/* Order Deatils  */}
            <div className='flex flex-col space-y-1'>
                <SectionHeading heading="Order Details" />
                <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Order ID:</span>
                        <span className='  '>{orderId}</span>
                    </div>
                    {
                        subscription && 
                        <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Subscription:</span>
                        <span className='  '><button className=" text-primary" onClick={()=>navigate(`/subscriptions/details/${subscription.id}`)}>{subscription.plan.name}</button></span>
                    </div>
                    }
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Amount:</span>
                        <span className='  '>{amount ? String(amount) : 0}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Tax:</span>
                        <span className='  '> {tax ? String(tax) : 0}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Discount:</span>
                        <span className='  '> {discount ? String(discount) : 0}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Payment:</span>
                        <span className='  '> {isPaid ? "Paid" : "Pending"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Receipt:</span>
                        <span className='  '> {receipt}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Order Created:</span>
                        <span className='  '> {moment(dateCreated).format('MMMM Do YYYY, h:mm:ss a')}</span>
                    </div>
                        <div className=' flex flex-row sm:space-x-1'>
                            <span className=' text-disabled  '>For Organization:</span>
                            <span className='  '> {organization?.name || ""}</span>
                        </div>
                </div>
            </div>
            {/*User Payment*/}
            <div className='flex flex-col space-y-1'>
                <SectionHeading heading="User Payment" />
                <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>User:</span>
                        <span className='  '><button className=" text-primary" onClick={() => navigate(`/user/details/${userPayment?.user.id}`)}>{userPayment?.user.name}</button></span>
                    </div>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Method:</span>
                        <span className='  '>{userPayment?.method}</span>
                    </div>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Payment ID:</span>
                        <span className='  '>{userPayment?.paymentId}</span>
                    </div>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Payment Date:</span>
                        <span className='  '>{moment(userPayment?.dateCreated).format('MMMM Do YYYY, h:mm:ss a')}</span>
                    </div>
                </div>
            </div>
            {/* Plans */}
            {
                planOrderItems?.length !== 0 && 
                <div className=' flex flex-col space-y-1'>
                <SectionHeading heading="Selected Plans" />
                <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
                    {
                        planOrderItems?.map(({plan}:{plan:PlansInterface})=>{
                            console.log(plan)
                            return(
                                <div className=' flex flex-row sm:sm:space-x-1'>
                                <span className=' text-disabled  '>Plan:</span>
                                <span className='  '> <button className=" text-primary" onClick={() => navigate(`/plans/details/${plan.id}`)} >{plan.name}</button></span>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
            }
        </div >
    )
}

export default PlanOrderDetails

function setPage(arg0: any) {
    throw new Error("Function not implemented.");
}
