import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectRole, setTokenExpired } from "../Redux/slices/authState";
import { Access, RoleBasedRouteProps } from "../Constants/Interfaces";
import { selectOrganization } from "../Redux/slices/orgState";
import { useQuery } from "@apollo/client";
import Queries from "../GraphQl/Queries";

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
  path,
  element: Component,
  access,
  fallbackPath,
  orgRequired = false,
  subscriptionRequired=true,
}) => {
  const accessToken = useSelector(selectAccessToken);
  const role = useSelector(selectRole);
  const dispatch = useDispatch();
  useQuery(Queries.GET_USERS(``), {
    onCompleted(data){
      console.log(data)
      dispatch(setTokenExpired(false)) 
    },
    onError(error) {
      dispatch(setTokenExpired(true)) 
    },
    fetchPolicy:"network-only",

  });
  if (!accessToken) {
    // Redirect to login or fallback page if the user is not authenticated
    return <Navigate to={"/"} replace />;
  }
  if (!access.includes(role) && !access.includes(Access.ANY)) {
    // Redirect to fallback page if the user's role is not allowed
    return <Navigate to={fallbackPath} replace />;
  }
  // Render the route component if the user has the required role
  return <Component />;
};

export default RoleBasedRoute;
