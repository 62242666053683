import { RiMoreLine } from "@remixicon/react";
import React, { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

type Props = {
  options: Action[];
  CustomIcon?: React.ReactNode 
  align?: "right"|"left"
};
interface Action {
  label: string;
  icon: React.ReactNode;
  callback: (params: any) => any;
  
}
enum Alignment{
  "right"= "left-0",
  "left"= "right-0"
}
const ActionButton = ({ options ,align="right",CustomIcon}: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });
  const handleShowMenu = (e:React.MouseEvent<HTMLButtonElement>)=>{
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(true)
  }
  return (
    <div className="relative">
      <button onClick={handleShowMenu} className="text-lg text-black">
        {CustomIcon?CustomIcon:<RiMoreLine strokeWidth={3} />}
      </button>
      {showMenu && (
        <div
          ref={menuRef}
          className={`z-40 absolute top-full ${Alignment[align]} rounded shadow-md flex flex-col bg-foreground`}
        >
          {options.map((opt, i) => (
            <button
              key={i}
              className="w-full min-w-max py-2 px-2 hover:bg-slate-100 flex items-center justify-start gap-2"
              onClick={opt.callback}
            >
              <span className="text-xs">{opt.icon}</span>
              {opt.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActionButton;
