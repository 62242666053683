import { RiArrowLeftCircleLine, RiCheckLine, RiCloseLine, RiDeleteBin2Line, RiEdit2Line, RiEditLine, RiHomeGearLine, RiUser6Line, RiUserAddLine } from '@remixicon/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DashCard, FilterBar, Pagination, SecondaryLinkButton, StatusTag } from '../../../Components'
import { SectionHeading } from '../../../Components/controls/Input'
import moment from 'moment'
import { COLORS } from '../../../Constants'
import useAPI from '../../../Hooks/useAPI'
import { CouponCodeInterface, IUser, Page, PlansInterface } from '../../../Constants/Interfaces'
import { toast } from 'react-toastify'
import { Interface } from 'readline'
import { useTable } from '../../../Hooks'
import ActionButton from '../../../Components/ActionButton'
import { PrimaryLinkButton } from '../../../Components/buttons/Button'

interface PlanWithCouponCodeInterface extends PlansInterface {
    couponCode: CouponCodeInterface[]
}

export interface ApiResponse {
    message: string;
    data: IUser[];
    totalRecords: number;
}

function PlansDetails() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Page>({
        totalRecords: 0,
        skip: 0,
        take: 10,
    });
    const couponCodes = useAPI<ApiResponse>({
        method: "GET",
        url: "/admin/coupons",
        onCompleted: (data) => {
            setPage({ ...page, totalRecords: data.totalRecords })
        }
    });

    const { data, execute, loading } = useAPI<({ data: PlanWithCouponCodeInterface, success: boolean, message: string })>({
        method: "GET",
        url: `admin/plans/${id}`,
        onCompleted: ({ success, message }) => {
            if (success) {
                // toast.success(message)
            } else {
                toast.error(message)
            }
        },
        onError: (err: any) => {
            toast.error(err.message)
        }
    })

    const columns = [
        {
            header: "id",
            accessor: "id",
        },
        {
            header: "Code",
            accessor: "code",
        },
        {
            header: "Created By",
            accessor: "createdBy",
            render: ({ createdBy }: { createdBy: IUser }) => createdBy.name,
        },
        {
            header: "Validity",
            accessor: "validTill",
            render: ({ validTill }: { validTill: string }) => moment(validTill).format('MMMM Do YYYY, h:mm:ss a'),
        },
        {
            header: "Limit",
            accessor: "useLimit",
            render: ({ useLimit }: { useLimit: number }) => useLimit >= -1 ? "Unlimited" : String(useLimit),
        },
        {
            header: "Status",
            accessor: "active",
            render: (isActive: boolean) => (
                <StatusTag
                    label={
                        isActive
                            ? "Verified"
                            : "Unverfied"
                    }
                    status={
                        isActive
                            ? "success"
                            : "warning"
                    }
                />
            ),
        },
        {
            header: "Action",
            accessor: "action",
            render: (data: any) => {
                return (
                    <ActionButton
                        align="left"
                        options={[
                            {
                                label: "Update",
                                icon: <RiEditLine />,
                                callback: () => {
                                    navigate(`/coupons/update/${data.id}`)
                                },
                            },
                            {
                                label: "Delete",
                                icon: <RiDeleteBin2Line />,
                                callback: () => {
                                    //setShowDeleteModal(data.id);
                                },
                            },
                        ]}
                    />
                );
            },
        },
    ];

    const { Table } = useTable({
        columns,
        onRowClick: (data) => {
            console.log({ data });
            navigate(`/coupons/details/${data.id}`);
        },
    });

    useEffect(() => {
        if (id) {
            execute()
            couponCodes.execute({
                url: `/admin/coupons?query=${query}&skip=${page.skip}&take=${page.take}&byPlanId=${id}`,
            })
        }
    }, [query, page.skip, page.take])


    const planDetails: { feature: string, available: boolean }[] = []
    for (const i in data?.data?.planDetails) {
        const available: boolean = data?.data?.planDetails[i] || false
        planDetails.push({
            feature: i,
            available: available
        })
    }

    return (
        <div className='w-11/12 mx-auto rounded-xl bg-foreground flex flex-col space-y-5 p-10 lg:p-5'>
            {/* Plan Name*/}
            <div className=' w-full text-center sm:grid sm:grid-cols-3'>
                <button onClick={() => navigate(-1)} className=' hidden sm:block hover:text-secondary'>
                    <RiArrowLeftCircleLine />
                </button>
                <div className=' flex justify-center items-center space-x-2'>
                    <span className='text-3xl text-primary font-bold'>{data?.data?.name}</span>
                    <button role='button' className=' sm:hidden text-xm text-disabled hover:text-secondary'><RiEdit2Line /></button>
                </div>
                <span className=' hidden sm:flex w-full justify-end'>
                    <SecondaryLinkButton
                        to={`/plans/update/${id}`}
                        title="Edit"
                        icon={<RiEdit2Line />}
                    />
                </span>
            </div>
            {/* plan Deatils*/}
            <div className='flex flex-col space-y-1'>
                <SectionHeading heading="Plan Deatils" />
                <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Duration:</span>
                        <span className='  '>{data?.data?.duration}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>visibility:</span>
                        <span className='  '>{data?.data?.isVisible ? "Yes" : "No"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Plan Price:</span>
                        <span className='  '>{data?.data?.price}</span>
                    </div>
                </div>
                <div className='sm:flex'>
                    <DashCard
                        loading={loading}
                        color={COLORS.Yellow}
                        icon={<RiHomeGearLine />}
                        value={data?.data?.features?.godown || 0}
                        label="Godowns"
                    />
                    <DashCard
                        loading={loading}
                        color={COLORS.Green}
                        icon={<RiUser6Line />}
                        value={data?.data.features.customer || 0}
                        label="Customers"
                    />
                    <DashCard
                        loading={loading}
                        color={COLORS.danger}
                        icon={<RiUser6Line />}
                        value={data?.data.features.vendor || 0}
                        label="Vendors"
                    />
                </div>
            </div>
            {/* Description*/}
            <div className=' flex flex-col space-y-1'>
                <SectionHeading heading="Plan Description" />
                <span className=' break-all'>{data?.data?.description}</span>
            </div>
            {/* plan Details */}
            <div className=' flex flex-col space-y-1'>
                <SectionHeading heading="Plan Features" />
                <div className='grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'>
                    {
                        planDetails.map(({ feature, available }: { feature: string, available: boolean }, index) => {
                            return (
                                <div key={index} className=' flex flex-row space-x-5' >
                                    {available ?
                                        <>
                                            <span className=' text-green-600 '>
                                                <RiCheckLine />
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span className=' text-red-700 '>
                                                <RiCloseLine />
                                            </span>
                                        </>
                                    }
                                    <span className=' '>
                                        {feature}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="flex-col gap-4 w-full flex">
                <FilterBar
                    exportHandler={() => { }}
                    filter={<></>}
                    placeholder="Search User Name, Mobile"
                    // searchHandler={(e) => setQuery(e)}
                    debounceTime={500}
                    // sortByList={[
                    //   { label: "Name", value: "name" },
                    //   { label: "Mobile", value: "brand" },
                    //   { label: "Email", value: "mrp" },
                    //   { label: "Verified", value: "mobileVerified" },
                    // ]}
                    sortingHandler={({ name, order }) => {
                        //setSort({ name, orderby: order });
                    }}
                    resetPagination={() => {
                        //setPage({ ...page, skip: 0 })
                    }}
                    loading={loading}
                    refetch={couponCodes.refetch} searchHandler={function (e: string): void {
                        throw new Error('Function not implemented.')
                    }} sortByList={[]} />
                <Table height="80vh" loading={loading} rows={couponCodes.data?.data || []} />
                <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
                    <PrimaryLinkButton
                        to={"/coupons/add"}
                        title="Add Coupon Code"
                        icon={<RiUserAddLine size={18} />}
                    />
                    <Pagination page={page} onPageChange={setPage} />
                </div>
            </div>
        </div >
    )
}

export default PlansDetails