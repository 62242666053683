const COLORS = {
    background: "#E3EFF6",
    foreground: "#FFFFFF",
    primary: "#5489DE",
    secondary: "#699BF7",
    disabled: "#A5A5A5",
    black: "#232323 ",
    danger: "#F24E1E",
    gray: "#F1F1F1",
    Green: "#5DD362",
    Yellow: "#FFD12C",
    Orange: "#ff8e75",
    Purple: "#DE54C0",
    
  }
export const GraphColors = [
    "#5489DE","#FFD12C","#5DD362", "#F24E1E","#A13BC4","#00A4CC","#FF6F91" ,"#8BC34A"
]

  export default COLORS;