import { RiDeleteBin2Line, RiEditLine, RiUserAddLine, RiUserLine } from "@remixicon/react";
import {
  FilterBar,
  Pagination,
  PrimaryButton,
  SecondaryLinkButton,
  StatusTag,
} from "../../../Components";
import ActionButton from "../../../Components/ActionButton";
import { useTable } from "../../../Hooks";
import useAPI from "../../../Hooks/useAPI";
import { FormatAmount, GetFormattedDate } from "../../../Utils";
import { IUser, Page, PlanOrderInterface, PlansInterface, subscriptionInterface } from "../../../Constants/Interfaces";
import { useEffect, useState } from "react";
import { PrimaryLinkButton } from "../../../Components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

type Props = {};

interface IsubscriptionsWithPlanOrder extends subscriptionInterface {
  planOrder?: PlanOrderInterface,
}


const SubscriptionIndex = (props: Props) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Page>({
    totalRecords: 0,
    skip: 0,
    take: 10,
  });
  const getSubscriptions = useAPI<{ data: IsubscriptionsWithPlanOrder[], success: boolean, message: string, totalRecords: number }>({
    method: "GET",
    url: 'admin/subscriptions',
    onCompleted: ({ data, success, message, totalRecords }) => {
      if (!success) {
        toast.error(message)
      }
      setPage({ ...page, totalRecords: totalRecords })
    },
    onError: (err) => {
      toast.error(err)
    }
  })

  const deleteSubscription = useAPI<{ success: boolean, message: string }>({
    method: "POST",
    url: "",
    onCompleted: ({ success, message }) => {
      if (success) {
        toast.success(message)
      }
    },
    onError: (err) => {
      toast.error(err)
    }
  })


  const columns = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Plan Name",
      accessor: "plan.name",
      render: ({ plan }: { plan: PlansInterface }) => String(plan.name)
    },
    {
      header: " Start",
      accessor: "startDate",
      render: ({ startDate }: { startDate: string }) => moment(startDate).format('MMMM Do YYYY, h:mm:ss a')
    },
    {
      header: "End",
      accessor: "validity",
      render: ({ validity }: { validity: string }) => moment(validity).format('MMMM Do YYYY, h:mm:ss a')

    },
    {
      header: "Duration",
      accessor: "plan.duration",
      render: ({ plan }: { plan: PlansInterface }) => String(plan.duration) + ' Days'
    },
    {
      header: "Amount",
      accessor: "planOrder",
      render: ({ amount }: PlanOrderInterface) => String(amount || 0)
    },
    {
      header: "Status",
      accessor: "active",
      render: ({ isActive }: subscriptionInterface) => (
        <StatusTag
          label={
            isActive ? "Active" : "Deactivate"
          }
          status={
            isActive ? "success" : "warning"
          }
        />
      ),
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/organizations/update/${data.id}`)
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  deleteSubscription.execute({
                    url:`admin/subscriptions/${data.id}`
                  })
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const { Table } = useTable({
    columns,
    onRowClick: (data) => {
      navigate(`/subscriptions/details/${data.id}`);
    },
  });

  useEffect(() => {
    getSubscriptions.execute({
      url: `/admin/subscriptions?query=${query}&skip=${page.skip}&take=${page.take}`,
    })
  }, [query, page.skip, page.take]);

  return (
    <div className="flex-col gap-4 w-full flex">
      <FilterBar
        exportHandler={() => { }}
        filter={<></>}
        placeholder="Search User Name, Mobile"
        searchHandler={(e) => setQuery(e)}
        debounceTime={500}
        sortByList={[
          { label: "Name", value: "name" },
          { label: "Mobile", value: "brand" },
          { label: "Email", value: "mrp" },
          { label: "Verified", value: "mobileVerified" },
        ]}
        sortingHandler={({ name, order }) => {
          //setSort({ name, orderby: order });
        }}
        resetPagination={() => {
          //setPage({ ...page, skip: 0 })
        }}
        loading={getSubscriptions.loading}
        refetch={getSubscriptions.refetch}
      />
      <Table height="80vh" loading={getSubscriptions.loading} rows={getSubscriptions.data?.data || []} />
      <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
        <PrimaryLinkButton
          to={"/subscriptions/add"}
          title="Add Subscription"
          icon={<RiUserAddLine size={18} />}
        />
        <Pagination page={page} onPageChange={setPage} />
      </div>
    </div>
  );
};

export default SubscriptionIndex;
