function FormatAmount(number:string|number,positiveOnly?:boolean) {
    // Check if the input is a string and convert it to a number if necessary
    if (typeof number === 'string') {
      number = parseFloat(number);
    }
  
    // Check if the input is a valid number
    if (isNaN(number)) {
      return 'Invalid input';
    }
    if(positiveOnly){
      number = number>=0?number:0
    }
    // Check if the number has decimal places
    const hasDecimal = number % 1 !== 0;
  
    // Format the number with commas and optional decimal places
    const formattedAmount = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: hasDecimal ? 2 : 0, // Add decimal places if present
      maximumFractionDigits: hasDecimal ? 2 : 0,
    }).format(number);
  
    return formattedAmount;
  }
  export default FormatAmount