import { useNavigate, useParams } from "react-router-dom";
import useAPI from "../../../Hooks/useAPI";
import { useEffect, useState } from "react";
import { DealerInterface, PlanOrderInterface, RewardTrackerInterface } from "../../../Constants/Interfaces";
import { toast } from "react-toastify";
import { FormLoadingOverlay, PrimaryButton, SecondaryButton } from "../../../Components";
import Input, { FormRow, SearchDropDown, SectionHeading } from "../../../Components/controls/Input";
import { useValidation } from "../../../Hooks";
import { handleChange } from "../../../Utils";
import { ValidationConfig } from "../../../Hooks/useValidation";
import { REGEX } from "../../../Constants";
import { RiArrowRightCircleLine, RiCloseLine, RiUserSettingsLine } from "@remixicon/react";

interface planOrderWithCouponCodeInterface extends PlanOrderInterface {
    couponCode: {
        id: number;
        code: string
    }
}

interface RewardWithPlanOrderAndCouponCodeIntercae extends RewardTrackerInterface {
    planOrder?: planOrderWithCouponCodeInterface,
}

interface RewardInput {
    id: number;
    dealerId: number;
    reward: number;
    bonus: number;
    isIssued: boolean;
}


const RewardForm = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [form, setForm] = useState<RewardInput>({
        id: 0,
        dealerId: 1,
        reward: 0,
        bonus: 0,
        isIssued: false
    })
    const { data, execute, loading } = useAPI<{ message: string, success: boolean, data: RewardTrackerInterface }>({
        method: "GET",
        url: `/rewards/${id}`,
        onCompleted: ({ message, success, data }) => {
            if (!success) {
                toast.error(message)
            }
            setForm({
                id: data.id,
                dealerId: data.dealer.id,
                reward: data.reward,
                bonus: data.bonus,
                isIssued: data.isIssued
            })
            toast.success(message)
        },
        onError: (err: any) => {
            toast.error(err)
        }
    });

    const dealers = useAPI<{
        message: string;
        success: boolean;
        data: DealerInterface[];
        totalRecords: number;
    }>({
        method: "GET",
        url: "/admin/dealers",
        onCompleted: (data) => {
            if (!data.success) {
                toast.error(data.message)
            }
        }
    });

    const addReward = useAPI<{
        message: string;
        success: boolean;
    }>({
        method: "POST",
        url: `/admin/rewards/${id}`,
        data: form,
        onCompleted: (data) => {
            if (!data.success) {
                toast.error(data.message)
            }
        },
        onError: (err:any) => {
            toast.error(err)
        }
    });

    const ValidationRules: ValidationConfig[] = [
        {
            input_path: "reward",
            label_name: "Reward",
            input_required: true,
            regex: REGEX.Integers.regex,
            error_message: "Enter valid Reward",
        },
        {
            input_path: "bonus",
            label_name: "Bonus",
            input_required: true,
            regex: REGEX.Integers.regex,
            error_message: "Enter Valid bonus",
        },
        {
            input_path: "dealerId",
            label_name: "Dealer",
            input_required: true,
            regex: REGEX.Integers.regex,
            error_message: "Select Valid Dealer",
        },
    ];

    const handleSelectDealer = (value: any) => {
        setForm({ ...form, dealerId: value.id })

    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (triggerValidation()) {
            addReward.execute()
            navigate('/rewards')
        }

    }

    const { triggerValidation, validationMessages } =
        useValidation(form, ValidationRules);
    const onChange = handleChange(form, setForm);
    
    useEffect(() => {
        dealers.execute();
        execute()
        navigate('/rewards')
    }, [])

    // function handleResetForm(e: MouseEvent<HTMLButtonElement, MouseEvent>): void {
    //     throw new Error("Function not implemented.");
    // }

    console.log(JSON.stringify(dealers?.data?.data))
    return (
        <form className="relative flex flex-col gap-3 p-8 rounded-xl bg-white w-11/12 mx-auto mt-28">
            <FormLoadingOverlay loading={loading} />
            <h3>Update Reward</h3>
            <SectionHeading heading="" />
            <FormRow gap={4}>
                <Input
                    name="reward"
                    label="Reward"
                    placeholder="Enter User's Name"
                    onChange={onChange()}
                    validation={validationMessages["name"]}
                    showRequired
                    inputSize="half"
                    value={form["reward"] || ""}
                />
                <Input
                    name="bonus"
                    label="Bonus"
                    placeholder="Enter Mobile no."
                    onChange={onChange()}
                    validation={validationMessages.mobile}
                    showRequired
                    inputSize="half"
                    value={form["bonus"]}
                />
                <SearchDropDown
                    Icon={<RiUserSettingsLine />}
                    label="Dealer"
                    name="dealerId"
                    placeholder="Select Dealer"
                    inputSize="half"
                    renderResultItem={({ user }) => <>{user.name}</>}
                    dataList={dealers?.data?.data || []}
                    searchCondition={(query: string) => (data: any) => {
                        return data.name.toLowerCase().includes(query.toLowerCase());
                    }}
                    callback={handleSelectDealer}
                    validation={validationMessages["dealerId"]}
                    valueField={"name"}
                    showRequired
                    value={dealers?.data?.data.find((dealer: any) => Number(form["dealerId"]) === Number(dealer.id))?.user.name || ""}
                    defaultValue={dealers?.data?.data.find((dealer: any) => Number(form["dealerId"]) === Number(dealer.id))?.user.name || ""}
                />
            </FormRow>
            <div className="flex justify-end items-center gap-4">
                {/* <SecondaryButton icon={<RiCloseLine />} title="Reset" callback={handleResetForm} /> */}
                <PrimaryButton
                    icon={<RiArrowRightCircleLine />}
                    title="Submit"
                    callback={handleSubmit}
                />
            </div>
        </form>
    );

}

export default RewardForm;