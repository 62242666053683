import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IUser, IUserInput, Organization } from '../../../Constants/Interfaces'
import useAPI from '../../../Hooks/useAPI'
import { toast } from 'react-toastify'
import { FormLoadingOverlay, PrimaryButton, SecondaryButton } from '../../../Components'
import Input, { DateInput, FormRow, RadioButton, SearchDropDown, SectionHeading, TextArea } from '../../../Components/controls/Input'
import { RiArrowLeftCircleLine, RiArrowRightCircleLine, RiCloseLine, RiUserLine, RiUserSettingsLine } from '@remixicon/react'
import useValidation, { ValidationConfig } from '../../../Hooks/useValidation'
import { REGEX } from '../../../Constants'
import { handleChange } from '../../../Utils'
import { from } from '@apollo/client'

interface AddressInput {
  addressId?: string | null;
  addressLine: string;
  city: string;
  state: string;
  pincode: string;
}

interface OrganizationInput {
  orgId?: string,
  userId: string
  name: string;
  address: AddressInput;
  renewDate: string;
  dateCreated: string;
  invoiceTemplate: number;
  mobile: string;
  termsAndCondition: string | null;
  emailAddress: string | null;
  gstin: string;
  isActive: boolean;
  isDeleted: boolean;
  license: string | null;
}

const initialForm: OrganizationInput = {
  // id: "",
  orgId: "",
  userId: "",
  name: "",
  address: {
    addressId: null,
    addressLine: "",
    city: "",
    state: "",
    pincode: "",
  },
  renewDate: "",
  dateCreated: "",
  invoiceTemplate: 0,
  mobile: "",
  termsAndCondition: null,
  emailAddress: null,
  gstin: "",
  isActive: false,
  isDeleted: false,
  license: null
}

interface IOrganizationWithUser extends Organization {
  user: IUser
}

const OrganizationFrom = () => {
  const { id } = useParams()
  // const [users, setUsers] = useState<IUserInput[] | null>(null)
  const navigate = useNavigate()
  const [form, setForm] = useState<OrganizationInput>({ ...initialForm })

  const organizationDetails = useAPI<{ data: IOrganizationWithUser, success: boolean, message: string }>({
    method: 'GET',
    url: `admin/organizations/${id}`,
    data: from,
    onCompleted: ({ data, success, message }) => {
      if (success) {
        toast.success(message)
        setForm({ ...data, userId: data.user.id, orgId: data.id })
      } else {
        toast.error(message)
      }
    },
    onError: (err) => {
      toast.error(err)
    }
  })

  const addOrganization = useAPI<{ success: boolean, message: string }>({
    method: 'POST',
    url: `admin/add_organization`,
    data: form,
    onCompleted: (data) => {
      if (data.success) {
        toast.success(`${data.message}`)
        // navigate("/organizations");
      } else {
        toast.success(`${data.message}`)
      }
    },
    onError: (err) => {
      toast.success(`${err}`)
    }
  })

  const users = useAPI<{ success: boolean, data: IUser[], totalRecords: number, message: string }>({
    method: 'GET',
    url: 'admin/users',
    onCompleted: (response) => {
      if (!response.success) {
        toast.warning(`${response.message}`)
      }
    },
    onError: (err) => {
      toast.warning(`${err}`)
    }
  })

  const validationRules: ValidationConfig[] = [
    {
      input_path: "name",
      label_name: "Name",
      error_message: "Enter Valid Organization Name",
      input_required: true,
      regex: REGEX.Name.regex,
    },
    {
      input_path: "mobile",
      label_name: "Mobile No.",
      error_message: "Enter Valid Organization Mobile No.",
      input_required: true,
      regex: REGEX.Mobile.regex,
    },
    {
      input_path: "emailAddress",
      label_name: "Email Address",
      error_message: "Enter Valid Organization Name",
      input_required: true,
      regex: REGEX.Email.regex,
    },
    {
      input_path: "gstin",
      label_name: "GSTIN",
      error_message: "Enter Valid Organization GSTIN",
      input_required: true,
      regex: REGEX.GSTIN.regex,
    },
    {
      input_path: "userId",
      label_name: "User ",
      error_message: "Enter Valid User",
      input_required: true,
      regex: REGEX.ANYTHING.regex,
    },
    {
      input_path: "invoiceTemplate",
      label_name: "Invoice Template",
      error_message: "Enter Valid Invoice Template",
      input_required: false,
      regex: REGEX.ANYTHING.regex,
    },
    {
      input_path: 'address.addressLine',
      label_name: "Address Line",
      error_message: "Enter Valid Address Line",
      input_required: true,
      regex: REGEX.ANYTHING.regex,
    },
    {
      input_path: "address.city",
      label_name: "City",
      error_message: "Enter Valid City",
      input_required: true,
      regex: REGEX.Name.regex,
    },
    {
      input_path: "address.state",
      label_name: "State",
      error_message: "Enter Valid State",
      input_required: true,
      regex: REGEX.Name.regex,
    },
    {
      input_path: "address.pincode",
      label_name: "Pincode",
      error_message: "Enter Valid Pincode",
      input_required: true,
      regex: REGEX.Pincode.regex,
    },
    {
      input_path: "termsAndCondition",
      label_name: "Term And Condition",
      error_message: "Enter Term And Condition",
      input_required: false,
      regex: REGEX.ANYTHING.regex,
    }
  ]

  const handleSelectUser = (value: IUser) => {
    setForm({ ...form, userId: value.id })
  }

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (triggerValidation()) {
      addOrganization.execute()
      navigate('/organizations')
    }
  }
  const resetHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (id) {
      setForm({ ...initialForm })
    } else {
      setForm({ orgId: form.orgId, ...initialForm })
    }
  }
  const { triggerValidation, validationMessages } = useValidation(form, validationRules);
  const onChange = handleChange(form, setForm);

  useEffect(() => {
    if (id) {
      organizationDetails.execute()
    }
    users.execute()
  }, [])

  return (
    <form className="relative flex flex-col space-y-3 p-8 rounded-xl bg-white w-11/12 mx-auto mt-14 sm:mt-20 lg:mt-24">
      <FormLoadingOverlay loading={organizationDetails.loading} />
      <div className=" flex items-center flex-row space-x-2">
        <button onClick={handleBackButton} className=' hidden sm:block hover:text-secondary'>
          <RiArrowLeftCircleLine />
        </button>
        <h3>{id ? "Update Organization" : "Add Organization"}</h3>
      </div>
      <SectionHeading heading="" />
      <FormRow gap={4}>
        <Input
          name="name"
          label="Name"
          placeholder="Enter User's Name"
          onChange={onChange("string")}
          validation={validationMessages["name"]}
          showRequired
          inputSize="quarter"
          value={form["name"] || ""}
        />
        <Input
          name="mobile"
          label="Mobile no."
          placeholder="Enter Mobile no."
          onChange={onChange()}
          validation={validationMessages.mobile}
          showRequired
          inputSize="quarter"
          value={form["mobile"]}
        />
        <Input
          name="emailAddress"
          label="Email Address"
          placeholder="Enter Email Address"
          onChange={onChange()}
          validation={validationMessages.email}
          inputSize="quarter"
          value={form["emailAddress"] || ""}
        />
        <Input
          name="gstin"
          label="GSTIN"
          placeholder="Enter GSTIN"
          onChange={onChange()}
          validation={validationMessages.email}
          showRequired
          inputSize="quarter"
          value={form["gstin"] || ""}
        />
        <Input
          name="invoiceTemplate"
          label="Invoice Template"
          placeholder="Enter Template Number"
          onChange={onChange()}
          validation={validationMessages.invoiceTemplate}
          inputSize="quarter"
          value={form['invoiceTemplate']}
        />

        <SearchDropDown
          Icon={<RiUserLine />}
          label="User"
          name="userId"
          placeholder="Select User"
          inputSize="quarter"
          renderResultItem={({ name }) => <>{name}</>}
          dataList={users.data?.data || []}
          searchCondition={(query: string) => (data: any) => {
            return data.name.toLowerCase().includes(query.toLowerCase());
          }}
          callback={handleSelectUser}
          validation={validationMessages["roleId"]}
          valueField={"name"}
          showRequired
          value={users.data?.data.find((user: any) => form["userId"] === String(user.id))?.name || ""}
          defaultValue={users.data?.data.find((role: any) => form["userId"] === String(role.id))?.name || ""}
        />

      </FormRow>
      <SectionHeading heading="Address" />
      <FormRow gap={4}>
        <Input
          name="address.addressLine"
          label="Address Line"
          placeholder="Enter Address Line"
          onChange={onChange()}
          validation={validationMessages.addressLine}
          showRequired
          inputSize="quarter"
          value={form.address["addressLine"]}
        />
        <Input
          name="address.city"
          label="City"
          placeholder="Enter City"
          showRequired
          onChange={onChange()}
          validation={validationMessages.city}
          inputSize="quarter"
          value={form.address["city"]}
        />
        <Input
          name="address.state"
          label="State"
          placeholder="Enter State"
          onChange={onChange()}
          validation={validationMessages.state}
          showRequired
          inputSize="quarter"
          value={form.address["state"]}
        />
        <Input
          name="address.pincode"
          label="Pincode"
          placeholder="Enter Pincode"
          onChange={onChange()}
          validation={validationMessages.pincode}
          showRequired
          inputSize="quarter"
          value={form.address["pincode"]}
        />
        <TextArea label={"Term And Conditions"} value={form['termsAndCondition'] || ""} name={'termsAndCondition'} onChange={onChange()} validation={validationMessages.termsAndCondition} />
      </FormRow>
      <div className="flex justify-end items-center gap-4">
        <SecondaryButton icon={<RiCloseLine />} title="Reset"
          callback={resetHandle}
        />
        <PrimaryButton
          icon={<RiArrowRightCircleLine />}
          title="Submit"
          callback={handleSubmit}
        />
      </div>
    </form>
  )
}

export default OrganizationFrom