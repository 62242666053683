import { RiDeleteBin2Line, RiEditLine, RiUserAddLine, RiUserLine } from "@remixicon/react";
import {
  FilterBar,
  Pagination,
  PrimaryButton,
  SecondaryLinkButton,
  StatusTag,
} from "../../../Components";
import ActionButton from "../../../Components/ActionButton";
import { useTable } from "../../../Hooks";
import useAPI from "../../../Hooks/useAPI";
import { FormatAmount, GetFormattedDate } from "../../../Utils";
import { IUser, Page, PlanOrderInterface, UserPaymentInterface } from "../../../Constants/Interfaces";
import { useEffect, useState } from "react";
import { PrimaryLinkButton } from "../../../Components/buttons/Button";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface IUserPaymentWithPlanOrder extends UserPaymentInterface{
  planOrder:PlanOrderInterface
}

const PaymentIndex = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Page>({
    totalRecords: 0,
    skip: 0,
    take: 10,
  });
  const { data, error, execute, loading, refetch } = useAPI<{message: string,data: IUserPaymentWithPlanOrder[],totalRecords: number}>({
    method: "GET",
    url: "/admin/payments",
    onCompleted: (data)=>{
      setPage({...page,totalRecords:data.totalRecords})
    }
  });
  const columns = [
    {
      header: "id",
      accessor: "id",
    },
    {
      header: "User",
      accessor: "user",
      render: ({ user }: {user:IUser}) => 
        <>
      <span className=" text-primary" onClick={()=>navigate(`/user/details/${user.id}`)}>
        {user.name}
      </span>
      </>,
    },
    {
      header: "Amount",
      accessor: "amount",
      render: ({ amount }: {amount:number}) => amount,
    },
    {
      header: "Payment Id",
      accessor: "paymentId",
      render: ({ paymentId }: {paymentId:string}) => paymentId,
    },
    {
      header: "Order Id",
      accessor: "orderId",
      render: ({ orderId,planOrder }: {orderId:string,planOrder:PlanOrderInterface}) => 
      <>
      <span className=" text-primary" onClick={()=>navigate(`/orders/details/${planOrder.id}`)}>
        {orderId}
      </span>
      </>,
    },
    {
      header: "Payment Mode",
      accessor: "method",
      render: ({ method }: {method:string}) => method,
    },
    {
      header: "Payment Date",
      accessor: "dateCreated",
      render: ({ dateCreated }: {dateCreated:string}) => moment(dateCreated).format('MMMM Do YYYY, h:mm:ss a'),
    },
  ];
  const { Table } = useTable({
    columns,
    onRowClick: (data) => {
      // console.log({ data });
      // navigate(`/payments/details/${data.id}`);
    },
  });
  useEffect(() => {
    execute({
      url: `/admin/payments?query=${query}&skip=${page.skip}&take=${page.take}`,
    })
  }, [query,page.skip,page.take]);
  console.log({ data });
  
  function setSort(arg0: { name: string | null; orderby: "ASC" | "DESC" | null; }) {
    throw new Error("Function not implemented.");
  }

  return (
    <div className="flex-col gap-4 w-full flex">
      <FilterBar
        exportHandler={() => {}}
        filter={<></>}
        placeholder="Search User Name, Mobile"
        searchHandler={(e) => setQuery(e)}
        debounceTime={500}
        sortByList={[
          { label: "Name", value: "name" },
          { label: "Mobile", value: "mobile" },
          { label: "Email", value: "email" },
        ]}
        sortingHandler={({ name, order }) => {
          setSort({ name, orderby: order });
        }}
        resetPagination={() => {
          setPage({ ...page, skip: 0 })
        }}
        loading={loading}
        refetch={refetch}
      />
      <Table height="80vh" loading={loading} rows={data?.data || []} />
      <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
        {/* <PrimaryLinkButton
          to={"/user/add"}
          title="Add User"
          icon={<RiUserAddLine size={18} />}
        /> */}
        <Pagination page={page} onPageChange={setPage} />
      </div>
    </div>
  );
};

export default PaymentIndex;
