import { createSlice } from "@reduxjs/toolkit";
import {
  AdditionalCharges,
  Address,
  DisitributionStatus,
  GodownDistributionInput,
  InventoryUnitsInterface,
  ShopDistributionInput,
} from "../../Constants/Interfaces";
import { CalculateBill } from "../../Utils";

interface Sales {
  salesId: string;
  invoiceId: string;
  orgId: string;
  customerId: string;
  amount: number;
  paidAmount: number;
  paymentMode: string;
  products: SalesItems[];
  termsAndCondition: string;
  additionalCharges: AdditionalCharges[];
  dueDate: string;
}
interface SalesItems {
  productId: string;
  name: string;
  price: number;
  discount: number;
  hsn?:string;
  tax: number;
  quantity: number;
  inventoryUnitId: string|null;
  inventoryUnit?: InventoryUnitsInterface;
  batchid: string;
  godownDistribution?: GodownDistributionInput[];
  shopDistribution?: ShopDistributionInput;
  status?: DisitributionStatus;
}

interface InitialState {
  invoiceNumber: string;
  form: Sales;
  orignalAmount: number;
  subTotal: number;
  totalTax: number;
  totalDiscount: number;
  activeSalesItem: number;
  activeCustomer: {
    name: string;
    billAddress: Address;
    Balance: number;
  };
  activeForm: "customer" | "product" | "payment";
  activeAdditionalChargeIndex: number;
}

const initialState: InitialState = {
  invoiceNumber: "",
  form: {
    invoiceId: "",
    salesId: "",
    amount: 0,
    orgId: "",
    paidAmount: 0,
    paymentMode: "",
    products: [],
    customerId: "",
    termsAndCondition: "",
    additionalCharges: [],
    dueDate: "",
  },
  orignalAmount: 0,
  subTotal: 0,
  totalTax: 0,
  totalDiscount: 0,
  activeForm: "customer",
  activeSalesItem: -1,
  activeCustomer: {
    name: "",
    Balance: 0,
    billAddress: {
      addressLine: "",
      city: "",
      pincode: "",
      state: "",
    },
  },
  activeAdditionalChargeIndex: 0,
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    setInvoiceNumber: (state, action) => {
      state.form.invoiceId = action.payload.id;
      state.invoiceNumber = action.payload.invoiceNumber;
    },
    setSalesId: (state, action) => {
      state.form.salesId = action.payload;
    },
    setCustomerId: (state, action) => {
      console.log(action.payload);
      const activeCustomer = {
        ...action.payload.customer,
        Balance: action.payload.Balance,
      };
      state.form.customerId = action.payload.customer.id;
      state.activeCustomer = activeCustomer;
      state.activeForm = "product";
    },
    setPaymentDetails: (state, action) => {
      state.form.paidAmount = action.payload.paidAmount;
      state.form.paymentMode = action.payload.paymentMode;
    },
    addProduct: (state, action) => {
      if (action.payload.quantity > 0) {
        const { exist, index } = itemExists(
          state.form.products,
          action.payload.batchId,
          "batchId"
        );
        if (exist && index !== null) {
          state.form.products[index] = action.payload;
        } else {
          state.form.products.push(action.payload);
        }
        const { amount, subTotal, totalTax, totalDiscount } = CalculateBill(
          state.form.products
        );
        const totalAdditionalCharges = state.form.additionalCharges.reduce(
          (acc, value) =>
            parseInt(String(acc)) + parseInt(String(value.charges)),
          0
        );
        //Orignal Amount stores calculated sale amount without any additional charges
        state.orignalAmount = Math.round(amount);
        //add additinal charge to the orignal amount prevents from double adding the same charge
        state.form.amount =
          Math.round(state.orignalAmount) + totalAdditionalCharges;
        state.subTotal = Math.round(subTotal);
        state.totalTax = Math.round(totalTax);
        state.totalDiscount = Math.round(totalDiscount);
      }
    },
    setAdditionalChargeName: (
      state,
      action: { payload: { index: number; name: string } }
    ) => {
      const additonalCharge = {
        name: action.payload.name,
        charges: 0,
      };
      state.form.additionalCharges[state.activeAdditionalChargeIndex] =
        additonalCharge;
      if (state.activeAdditionalChargeIndex === 0 && action.payload.name === "")
        state.form.additionalCharges = [];
    },
    setAdditionalChargeValue: (
      state,
      action: { payload: { index: number; charges: string } }
    ) => {
      state.form.additionalCharges[state.activeAdditionalChargeIndex].charges =
        parseInt(action.payload.charges || "0");
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => parseInt(String(acc)) + parseInt(String(value.charges)),
        0
      );
      state.form.amount = state.orignalAmount + totalAdditionalCharges;
    },
    addAdditionalCharges: (state, action) => {
      const currAdditionalCharge =
        state.form.additionalCharges[state.activeAdditionalChargeIndex];
      if (currAdditionalCharge?.name && currAdditionalCharge?.charges) {
        state.activeAdditionalChargeIndex += 1;
        const newAdditionalCharges = [...state.form.additionalCharges];
        newAdditionalCharges.push({
          charges: 0,
          name: "",
        });
        state.form.additionalCharges = newAdditionalCharges;
      }
    },
    removeAdditionalCharges: (state, action) => {
      state.form.additionalCharges = state.form.additionalCharges.filter(
        (value, i) => i !== action.payload
      );
      if (state.activeAdditionalChargeIndex > 0) {
        state.activeAdditionalChargeIndex -= 1;
      } else {
        state.activeAdditionalChargeIndex = 0;
      }

      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => acc + value.charges,
        0
      );
      state.form.amount = state.orignalAmount + totalAdditionalCharges;
    },
    setDueDate: (state, action) => {
      state.form.dueDate = action.payload;
    },

    deleteProduct: (state, action) => {
      state.form.products = state.form.products.filter(
        (item) => item.productId !== action.payload
      );
      const { amount, subTotal, totalTax, totalDiscount } = CalculateBill(
        state.form.products
      );
      state.form.amount = amount;
      state.subTotal = subTotal;
      state.totalTax = totalTax;
      state.totalDiscount = totalDiscount;
    },
    addDistribution: (state, action) => {
      const { shopDistribution, godownDistribution } = action.payload;
      const  product  = state.form.products[state.activeSalesItem]
      state.form.products[state.activeSalesItem].shopDistribution =
        shopDistribution;
      state.form.products[state.activeSalesItem].godownDistribution =
        godownDistribution;

      const targetQuantity =
        state.form.products[state.activeSalesItem].quantity;
      const shopQuantity = shopDistribution.quantity;
      const godownQuantity = godownDistribution.reduce(
        (acc: number, godown: GodownDistributionInput) => acc + godown.quantity,
        0
      );
      const currStatus = product.status?.status

      if ((targetQuantity === shopQuantity + godownQuantity)&&(currStatus==="Error"||currStatus==="Warning")) {
        state.form.products[state.activeSalesItem].status = {
          color: "green-500",
          status: "Resolved",
          message: "Resoved",
          success: true,
        };
      }

      state.activeForm = "product";
      state.activeSalesItem = -1;
    },
    setActiveSalesItem: (state, action) => {
      state.activeSalesItem = action.payload;
    },
    setActiveForm: (state, action) => {
      state.activeForm = action.payload;
    },
    setPaymentMode: (state, action) => {
      state.form.paymentMode = action.payload;
    },
    setPaidAmount: (state, action) => {
      if (action.payload === "") {
        state.form.paidAmount = 0;
      } else if (action.payload > 0 && action.payload <= state.form.amount) {
        state.form.paidAmount = parseInt(action.payload);
        console.log(
          action.payload,
          state.form.amount,
          parseInt(action.payload) === state.form.amount
        );
        if (parseInt(action.payload) === state.form.amount)
          state.form.dueDate = "";
      }
    },
    setSalesTerms: (state, action) => {
      state.form.termsAndCondition = action.payload.replace(/\n/g, "\\n");
    },
    resetSalesItems: (state, action) => {
      state.form.products = [];
      state.form.amount = 0;
      state.subTotal = 0;
      state.totalTax = 0;
      state.totalDiscount = 0;
    },
    resetSalesPaymentDetails: (state, action) => {
      state.form.paidAmount = 0;
      state.form.paymentMode = "";
    },
    resetSalesForm: (state, action) => {
      state.invoiceNumber = "";
      state.form = initialState.form;
      state.activeForm = initialState.activeForm;
      state.activeSalesItem = initialState.activeSalesItem;
      state.subTotal = initialState.subTotal;
      state.totalTax = initialState.totalTax;
      state.totalDiscount = initialState.totalDiscount;
    },
  },
});

export const selectSalesForm = (state: any) => state.sales.form;
export const selectSalesSummary = (state: any) => {
  return {
    products: state.sales.form.products.length,
    amount: state.sales.form.amount,
    subTotal: state.sales.subTotal,
    totalTax: state.sales.totalTax,
    totalDiscount: state.sales.totalDiscount,
  };
};
export const selectinvoiceNumber = (state: any) => state.sales.invoiceNumber;
export const selectCustomerId = (state: any) => state.sales.form.customerId;
export const selectActiveCustomer = (state: any) => state.sales.activeCustomer;
export const selectSalesItems = (state: any) => state.sales.form.products;
export const selectActiveSalesItem = (state: any) =>
  state?.sales?.form?.products[state.sales.activeSalesItem];
export const selectActiveSalesForm = (state: any) => state.sales.activeForm;
export const selectAdditionalCharges = (state: any) =>
  state.sales.form.additionalCharges;
export const selectActiveSalesAdditionalChargeIndex = (state: any) =>
  state.sales.activeAdditionalChargeIndex;
export const selectSaleTerms = (state: any) =>
  state.sales.form.termsAndCondition;

export const {
  setInvoiceNumber,
  setCustomerId,
  addDistribution,
  addProduct,
  addAdditionalCharges,
  removeAdditionalCharges,
  deleteProduct,
  setActiveForm,
  setActiveSalesItem,
  setPaymentDetails,
  resetSalesItems,
  setPaymentMode,
  setPaidAmount,
  resetSalesPaymentDetails,
  resetSalesForm,
  setSalesTerms,
  setDueDate,
  setAdditionalChargeName,
  setAdditionalChargeValue,
} = salesSlice.actions;
export default salesSlice.reducer;

function itemExists(arr: any[], value: string, field: string) {
  let flag: {
    exist: boolean;
    index: null | number;
  } = {
    exist: false,
    index: null,
  };
  arr.forEach((element: any, index) => {
    if (element[field] === value) {
      flag.exist = true;
      flag.index = index;
    }
  });
  return flag;
}
