import { useDispatch } from "react-redux";
import { getLogout } from "../Redux/slices/authState";
import LottieDiv from "./LottieDiv";
import { ImagePaths } from "../Constants";
import { PrimaryButton } from "./buttons/Button";
import { RiRefreshLine } from "@remixicon/react";

const ReAuthModal = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(getLogout());
  };
  return (
    <div className="z-40 w-full h-screen fixed top-0 left-0 bg-[#23232350] flex items-center justify-center">
      <div className="w-5/12 p-4 bg-foreground rounded-lg flex flex-col items-center justify-between">
        <h3>You have been Logged out! </h3>

        <LottieDiv className="w-5/12" lottie={ImagePaths.Reauth} />
        <p className="w-6/12 text-center">
          Time to sign in again to keep working, Quick login, and you're good to
          go. Let's handle those bills!"
        </p>
        <div className="flex mt-4">
          <PrimaryButton
            callback={handleLogout}
            title="Login Again"
            icon={<RiRefreshLine />}
          />
        </div>
      </div>
    </div>
  );
};

export default ReAuthModal