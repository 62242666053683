import React, { useEffect, useState } from "react";
import { FormLoadingOverlay, FormRow, PrimaryButton, SecondaryButton } from "../../../Components";
import Input, { DateInput, SearchDropDown, SectionHeading } from "../../../Components/controls/Input";
import { RiArrowRightCircleLine, RiCloseLine, RiInputField, RiUserSettingsLine } from "@remixicon/react";
import { REGEX } from "../../../Constants";
import useValidation, { ValidationConfig } from "../../../Hooks/useValidation";
import { handleChange } from "../../../Utils";
import { IUser, IUserInput } from "../../../Constants/Interfaces";
import useAPI from "../../../Hooks/useAPI";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Queries from "../../../GraphQl/Queries";

type Props = {};
const initialForm:IUserInput = {
  userId:"",
  email:"",
  mobile:"",
  name:"",
  roleId:1,
  dateCreated:"",
  isDeleted:false,
  isVerifiedEmail:false,
  isVerifiedMobileNumber:false,

}
const UserForm = (props: Props) => {
  const {id} = useParams();
  console.log({id})
  const navigate = useNavigate();
  const [form,setForm] = useState<IUserInput>({...initialForm})
  const ValidationRules: ValidationConfig[] = [
    {
      input_path: "name",
      label_name: "User Name",
      input_required: true,
      regex: REGEX.Name.regex,
      error_message: "Enter valid User Name",
    },
    {
      input_path: "mobile",
      label_name: "Mobile No.",
      input_required: true,
      regex: REGEX.Mobile.regex,
      error_message: "Enter valid Mobile No.",
    },
    {
      input_path: "email",
      label_name: "Email Address",
      input_required: true,
      regex: REGEX.Email.regex,
      error_message: "Enter Valid Email Address",
    },
    {
      input_path: "roleId",
      label_name: "User Role",
      input_required: true,
      regex: REGEX.Integers.regex,
      error_message: "Select Valid User Role",
    },
  ];
  //Roles Query 
  const Roles = useQuery(Queries.GET_ALL_ROLES)
  //User Query
  const UserDetails = useAPI<{success:boolean,data:IUser}>({
    method:"GET",
    url:`/admin/users/${id}`,
    onCompleted({data}) {
        console.log("fetch data",data)
        setForm({
          email:data.email,
          dateCreated:data.dateCreated,
          isDeleted:data.isDeleted,
          isVerifiedEmail:data.isVerifiedEmail,
          isVerifiedMobileNumber:data.isVerifiedMobileNumber,
          mobile:data.mobile,
          name:data.name,
          roleId:data.role.id,
          userId:data.id
        })
      },
  })
  //User Mutation
  const { execute, loading } = useAPI<{success:boolean,message:string}>({
    method: "POST",
    url: "/admin/add_user",
    data: form,
    onCompleted:(data)=>{
      if(data.success){
          toast.success("User Created Successfully")
          navigate("/user");
      }else{
          toast.error(data.message);
      }
    },
    onError:(err)=>{
      toast.error("Failed to create new user");
      console.log("Add User",err);
    }
  });
  const handleSelectRole = (value:any)=>{
    setForm({...form,roleId:value.id})

  }
  const handleResetForm = ()=>{
    setForm({...initialForm})
  }
  const handleSubmit = (e:React.MouseEvent<HTMLButtonElement>)=>{
    e.preventDefault();
    if(triggerValidation()){
      execute()
    }

  }
  const { triggerValidation, validationMessages } =
    useValidation(form, ValidationRules);
  const onChange = handleChange(form, setForm);
  useEffect(()=>{
    if(id){
      UserDetails.execute();
    }
  },[])
  return (
    <form className="relative flex flex-col gap-3 p-8 rounded-xl bg-white w-11/12 mx-auto mt-28">
      <FormLoadingOverlay loading={loading}/>
      <h3>Add User</h3>
      <SectionHeading heading="" />
      <FormRow  gap={4}>
        <Input
          name="name"
          label="Name"
          placeholder="Enter User's Name"
          onChange={onChange("string")}
          validation={validationMessages["name"]}
          showRequired
          inputSize="half"
          value={form["name"] || ""}
        />
        <Input
          name="mobile"
          label="Mobile no."
          placeholder="Enter Mobile no."
          onChange={onChange()}
          validation={validationMessages.mobile}
          showRequired
          inputSize="half"
          value={form["mobile"]}
        />
        <Input
          name="email"
          label="Email"
          placeholder="Enter Email Address"
          onChange={onChange()}
          validation={validationMessages.email}
          inputSize="half"
          value={form["email"]}
        />
        <SearchDropDown
          Icon={<RiUserSettingsLine />}
          label="Role"
          name="roleId"
          placeholder="Select Role"
          inputSize="half"
          renderResultItem={({ name }) => <>{name}</>}
          dataList={Roles?.data?.getRoles?.roles||[]}
          searchCondition={(query: string) => (data: any) => {
            return data.name.toLowerCase().includes(query.toLowerCase());
          }}
          callback={handleSelectRole}
          validation={validationMessages["roleId"]}
          valueField={"name"}
          showRequired
          value={Roles?.data?.getRoles?.roles?.find((role:any)=>Number(form["roleId"])===Number(role.id))?.name||""}
          defaultValue={Roles?.data?.getRoles?.roles?.find((role:any)=>Number(form["roleId"])===Number(role.id))?.name||""}
        />
      </FormRow>
      <div className="flex justify-end items-center gap-4">
        <SecondaryButton icon={<RiCloseLine />} title="Reset" callback={handleResetForm} />
        <PrimaryButton
          icon={<RiArrowRightCircleLine />}
          title="Submit"
          callback={handleSubmit}
        />
      </div>
    </form>
  );
};

export default UserForm;
