import { ApolloClient, InMemoryCache} from '@apollo/client';
const client =(token:string,orgId?:string)=> new ApolloClient({
    uri:  `${process.env.REACT_APP_URI}graphql`,
    cache: new InMemoryCache(
      {
        addTypename: false
      }
    ),
    headers: {
      token: "bearer " + token,
      orgid: orgId||""
    }
  });


  export default client