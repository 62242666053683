const REGEX = {
  ANYTHING:{
    regex: /^.+$/,
    description: "matches with any string"
  },
  Alphabates: {
    regex: /^[a-zA-Z]+$/,
    description: "Alphabates only"
  },
  Integers:{
    regex: /^-?\d+(\.\d+)?$/,
    description: "Integers and decimals"
  },
  Positive_Integers: {
    regex: /^\d+(\.\d+)?$/,
    description: "Positive integers and decimals"
  }, 
  Price:{
    regex: /^(?!0+(\.0*)?$)\d+(\.\d{1,2})?$/,
    description: "Zero price is not allowed, decimal allowed"

  } ,
  Name:{
    regex: /^[A-Za-z]+(\.?\s[A-Za-z]+(\.)?)?(\s[A-Za-z]+(\.)?)?$/,
    description: "Enter your full name with optional middle and last names. You can include a '.' after any one of them."
  },
  Email:{
    regex: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
    description: ""
  },
  Mobile: {
    regex: /^[6-9]\d{9}$/,
    description : `optionally starts with "+91" followed by a 10-digit number, where the first digit is in the range of 6-9.`
  },
  GSTIN: {
    regex: /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/i,
    description: `consists of 15 alphanumeric characters.`
  },
  PAN: {
    regex: /^([A-Z]{5})(\d{4})([A-Z]{1})$/,
    description: `three uppercase letters followed by four digits and one uppercase letter.`
  },
  Pincode: {
    regex: /^\d{6}$/,
    description: `6-digit pincode format commonly used in India.`
  },
  Bank_Account_Number: {
    regex: /^\d{9,18}$/,
    description: ` bank account number format, which consists of 9 to 18 digits.`
  },
  HSN:{
    regex: /^(?:\d{4}|\d{6}|\d{8})$/,
    description: "string contains only digits and has a length of 4 to 8 characters"

  },
  Date: {
    regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
    description: "Date string formatted as yyyy-mm-dd"
  }
  
};
export default REGEX;
