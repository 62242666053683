import { createSlice } from "@reduxjs/toolkit";
import {
  AdditionalCharges,
  DisitributionStatus,
  GodownDistribution,
  GodownDistributionInput,
  InventoryUnitsInterface,
  ShopDistribution,
  VendorInterface,
  batchInterface,
} from "../../Constants/Interfaces";
import { CalculateBill } from "../../Utils";

interface Purchase {
  purchaseId?: string;
  orgId: string;
  vendorId: string;
  amount: number;
  paidAmount: number;
  paymentMode: String;
  products: PurchaseItems[];
  additionalCharges: AdditionalCharges[];
}
interface PurchaseItems {
  productId: string;
  name: string;
  price: number;
  tax: number;
  hsn?:string;
  quantity: number;
  batchId?: string;
  batch?: batchInterface;
  inventoryUnitId: string|null;
  inventoryUnit?: InventoryUnitsInterface;
  godownDistribution?: GodownDistribution[];
  shopDistribution?: ShopDistribution[];
  status?: DisitributionStatus;
}
interface InitialState {
  form: Purchase;
  subTotal: number;
  totalTax: number;
  activeVendor: VendorInterface
  activePurchaseItem: number;
  activeForm: "vendor" | "product" |"payment";
}

const initialState: InitialState = {
  form: {
    purchaseId: "",
    amount: 0,
    orgId: "",
    paidAmount: 0,
    paymentMode: "",
    products: [],
    vendorId: "",
    additionalCharges:[]
  },
  subTotal: 0,
  totalTax: 0,
  activeForm: "vendor",
  activeVendor: {
    vendorName: "",
    vendorMobile:"",
    vendorOrganizationAddress: {
      addressLine:"",
      city:"",
      pincode:"",
      state:"",
    },
    roleId:"",
    vendorOrganizationEmailAddress:"",
    vendorOrganizationMobile:"",
    vendorOrganizationName:"",
    gstin:"",
  },
  activePurchaseItem: -1,
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setVendor: (state, action) => {
      state.form.vendorId = action.payload.id;
      state.activeVendor = action.payload;
      state.activeForm = "product";
    },
    setPaymentDetails: (state, action) => {
      state.form.paidAmount = action.payload.paidAmount;
      state.form.paymentMode = action.payload.paymentMode;
    },
    addProduct: (state, action) => {
      if (action.payload.quantity > 0) {
        const { exist, index } = itemExists(
          state.form.products,
          action.payload.batchId,
          "batchId"
        );
        if (exist && index !== null) {
          state.form.products[index] = action.payload;
        } else {
          state.form.products.push(action.payload);
        }
        const { amount, subTotal, totalTax } = CalculateBill(
          state.form.products
        );
        state.form.amount = Math.round(amount);
        state.subTotal = Math.round(subTotal);
        state.totalTax = Math.round(totalTax);
      }
    },
    deleteProduct: (state, action) => {
      state.form.products = state.form.products.filter(
        (item) => item.productId !== action.payload
      );
      const { amount, subTotal, totalTax } = CalculateBill(state.form.products);
      state.form.amount = amount;
      state.subTotal = subTotal;
      state.totalTax = totalTax;
    },
    addDistribution: (state, action) => {
      const { shopDistribution, godownDistribution } = action.payload;
      const  product  = state.form.products[state.activePurchaseItem]
      state.form.products[state.activePurchaseItem].shopDistribution =
        shopDistribution;
      state.form.products[state.activePurchaseItem].godownDistribution =
        godownDistribution;

      const targetQuantity =
        state.form.products[state.activePurchaseItem].quantity;
      const shopQuantity = shopDistribution.quantity;
      const godownQuantity = godownDistribution.reduce(
        (acc: number, godown: GodownDistributionInput) => acc + godown.quantity,
        0
      );
      const currStatus = product.status?.status

      if ((targetQuantity === shopQuantity + godownQuantity)&&(currStatus==="Error"||currStatus==="Warning")) {
        state.form.products[state.activePurchaseItem].status = {
          color: "green-500",
          status: "Resolved",
          message: "Resoved",
          success: true,
        };
      }

      state.activeForm = "product";
      state.activePurchaseItem = -1;
    },
    setActivePurchaseItem: (state, action) => {
      state.activePurchaseItem = action.payload;
    },
    setActiveForm: (state, action) => {
      state.activeForm = action.payload;
      state.activePurchaseItem = -1
    },
    setPaymentMode: (state, action) => {
      state.form.paymentMode = action.payload;
    },
    setPaidAmount: (state, action) => {
      if(action.payload===""){
        state.form.paidAmount =0
      }else if (action.payload > 0 && action.payload <= state.form.amount) {
        state.form.paidAmount = parseInt(action.payload);
      }
    },
    resetPurchaseItems: (state, action) => {
      state.form.products = [];
      state.form.amount = 0;
      state.subTotal = 0;
      state.totalTax = 0;
    },
    resetPurchasePaymentDetails: (state,action)=>{
      state.form.paidAmount = 0;
      state.form.paymentMode = "";
    },
    resetPurchaseForm:(state,action)=>{
      state.form = initialState.form;
      state.activeForm = initialState.activeForm;
      state.activePurchaseItem = initialState.activePurchaseItem;
      state.subTotal = initialState.subTotal;
      state.totalTax = initialState.totalTax;

    }
  },
});

export const selectPurchaseForm = (state: any) => state.purchase.form;
export const selectPurchaseSummary = (state: any) => {
  return {
    products: state.purchase.form.products.length,
    amount: state.purchase.form.amount,
    subTotal: state.purchase.subTotal,
    totalTax: state.purchase.totalTax,
  };
};
export const selectVendorId = (state: any) => state.purchase.form.vendorId;
export const selectActiveVendor = (state: any) => state.purchase.activeVendor;
export const selectPurchaseItems = (state: any) => state.purchase.form.products;
export const selectActivePurchaseItem = (state: any) =>
  state?.purchase?.form?.products[state.purchase.activePurchaseItem];
export const selectActivePurchaseForm = (state: any) =>
  state.purchase.activeForm;
export const selectAdditionalCharges = (state: any)=> state.purchase.form.additionalCharges
export const {
  setVendor,
  addDistribution,
  addProduct,
  deleteProduct,
  setActiveForm,
  setActivePurchaseItem,
  setPaymentDetails,
  resetPurchaseItems,
  setPaymentMode,
  setPaidAmount,
  resetPurchasePaymentDetails
  ,resetPurchaseForm
} = purchaseSlice.actions;
export default purchaseSlice.reducer;

function itemExists(arr: any[], value: string, field: string) {
  let flag: {
    exist: boolean;
    index: null | number;
  } = {
    exist: false,
    index: null,
  };
  arr.forEach((element: any, index) => {
    if (element[field] === value) {
      flag.exist = true;
      flag.index = index;
    }
  });
  return flag;
}
