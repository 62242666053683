import { createSlice } from "@reduxjs/toolkit";
import { subscriptionInterface } from "../../Constants/Interfaces";
interface IntitalState extends subscriptionInterface {}
const initialState: IntitalState = {
  addOn: {},
  dateCreated: "",
  startDate:"",
  id: "",
  isActive: false,
  plan: undefined,
  validity: "",
};

export const subscriptionSlice = createSlice({
  name: "subscriptionState",
  initialState,
  reducers: {
    setSubscriptionDetails: (state, action: { payload: IntitalState }) => {
      state.isActive = action.payload.isActive;
      state.addOn = action.payload.addOn;
      state.validity = action.payload.validity;
      state.id = action.payload.id;
    },
    resetSubscriptionDetails: (state) => {
      state.isActive = initialState.isActive;
      state.addOn = initialState.addOn;
      state.validity = initialState.validity;
      state.id = initialState.id;
    },
  },
});

export const selectSubscription = (state: any): subscriptionInterface =>
  state.subscription;
export const selectSubscriptionState = (state: any): boolean => {
  return state.subscription.isActive;
};
export const { setSubscriptionDetails,resetSubscriptionDetails } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
