import { RiArrowLeftCircleLine, RiArrowRightCircleLine, RiBarChartFill, RiBroadcastLine, RiCloseLine, RiMailLine, RiMapPinLine, RiPhoneLine, RiUser2Line, RiUserSearchLine } from "@remixicon/react";
import { FormLoadingOverlay, FormRow, Input, PrimaryButton, SecondaryButton } from "../../../Components";
import { SearchDropDown } from "../../../Components/controls/Input";
import { useNavigate, useParams } from "react-router-dom";
import { IUser, LeadsInterface } from "../../../Constants/Interfaces";
import useAPI from "../../../Hooks/useAPI";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import useValidation, { ValidationConfig } from "../../../Hooks/useValidation";
import { REGEX } from "../../../Constants";
import { handleChange } from "../../../Utils";

export interface LeadsInputInterface {
    leadId?: string,
    name?: string
    mobile?: string
    email?: string
    location?: string
    status: string
    source?: string
}

const initialForm: LeadsInputInterface = {
    name: "",
    mobile: "",
    email: "",
    location: "",
    status: "",
    source: "",
}

const statusType: string[] = ["Interested", "Not Interested", "Registered", "Subscribed"]

const sourceType: string[] = ["Super Admins", "Website", "Social Media", "Employee", "Dealers"]

const LeadForm = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [form, setForm] = useState<LeadsInputInterface>({ ...initialForm })

    const addLead = useAPI<{ success: boolean, message: string }>({
        method: 'POST',
        data: { ...form },
        url: 'admin/add_lead',
        onCompleted: (response) => {
            if (!response.success) {
                toast.warning(`${response.message}`)
            } else {
                toast.success(response.message)
            }
        },
        onError: (err) => {
            toast.warning(`${err}`)
        }
    })

    const users = useAPI<{ success: boolean, data: IUser[], totalRecords: number, message: string }>({
        method: 'GET',
        url: 'admin/users',
        onCompleted: (response) => {
            if (!response.success) {
                toast.warning(`${response.message}`)
            }
        },
        onError: (err) => {
            toast.warning(`${err}`)
        }
    })
    const { data, execute, loading } = useAPI<{ success: boolean, data: LeadsInterface, message: string }>({
        method: 'GET',
        url: `admin/leads/${id}`,
        onCompleted: ({ success, data, message }) => {
            if (!success) {
                toast.warning(`${message}`)
            } else {
                setForm({ ...data, leadId: String(data.id) })
                toast.success(`${message}`)
            }

        },
        onError: (err) => {
            toast.warning(`${err}`)
        }
    })

    const validationRules: ValidationConfig[] = [
        {
            input_path: "name",
            input_required: false,
            label_name: "Name",
            error_message: "Enter Valid Lead Name",
            regex: REGEX.ANYTHING.regex
        },
        {
            input_path: "mobile",
            input_required: false,
            label_name: "Mobile No.",
            error_message: "Enter Valid Lead Mobile No.",
            regex: REGEX.Mobile.regex
        },
        {
            input_path: "email",
            input_required: false,
            label_name: "Email Address",
            error_message: "Enter Valid Lead Email Address",
            regex: REGEX.Email.regex
        },
        {
            input_path: "source",
            input_required: false,
            label_name: "Source",
            error_message: "Enter Valid Souce",
            regex: REGEX.ANYTHING.regex
        },
        {
            input_path: "location",
            input_required: false,
            label_name: "Location",
            error_message: "Enter Valid Lead Location",
            regex: REGEX.ANYTHING.regex
        },
        {
            input_path: "status",
            input_required: false,
            label_name: "Name",
            error_message: "Enter Valid Status",
            regex: REGEX.ANYTHING.regex
        },
    ]

    const handleSelectStatus = (value: string) => {
        setForm({ ...form, status: value })
    }

    const handleSelectSource = (value: string) => {
        setForm({ ...form, source: value })
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (triggerValidation()) {
            addLead.execute()
            navigate('/leads')
        }
    }

    const handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setForm(() => id ? { leadId: id, ...initialForm } : { ...initialForm })
    }

    const handleBackButton = () => {
        navigate(-1);
    };

    const { triggerValidation, validationMessages } = useValidation(form, validationRules)
    const onChange = handleChange(form, setForm);
    useEffect(() => {
        users.execute()
        if (id) {
            execute()
        }
    }, [])

    return (
        <form className="relative flex flex-col space-y-5 p-8 justify-center rounded-xl bg-white w-10/12 mx-auto mt-14 md:mt-24 lg:mt-32">
            <FormLoadingOverlay loading={loading} />
            <div className=" flex items-center flex-row space-x-2">
                <button onClick={handleBackButton} className=' hidden sm:block hover:text-secondary'>
                    <RiArrowLeftCircleLine />
                </button>
                <h3 className=" text-start">{id ? "Update Lead" : "Add Lead"}</h3>
            </div>
            <FormRow gap={5}>
                <Input
                    name="name"
                    label="Name"
                    placeholder="Enter Lead Name"
                    onChange={onChange("string")}
                    validation={validationMessages.name}
                    inputSize="quarter"
                    value={form["name"] || ""}
                />
                <Input
                    name="mobile"
                    label="Mobile No."
                    placeholder="Enter Lead Mobile no."
                    onChange={onChange()}
                    validation={validationMessages.mobile}
                    inputSize="quarter"
                    value={form["mobile"]}
                />
                <Input
                    name="email"
                    label="Email Address"
                    placeholder="Enter Lead Email Address"
                    onChange={onChange()}
                    validation={validationMessages.email}
                    inputSize="quarter"
                    value={form["email"] || ""}
                />
                <Input
                    name="location"
                    label="Location"
                    placeholder="Enter Lead Location"
                    onChange={onChange()}
                    validation={validationMessages.loaction}
                    inputSize="quarter"
                    value={form["location"] || ""}
                />
                <SearchDropDown
                    label="Status"
                    name="status"
                    placeholder="Select Lead Status"
                    inputSize="quarter"
                    renderResultItem={(status) => <>{status}</>}
                    dataList={statusType || []}
                    searchCondition={(query: string) => (status: any) => {
                        return status.toLowerCase().includes(query.toLowerCase());
                    }}
                    onChange={onChange()}
                    callback={handleSelectStatus}
                    validation={validationMessages.status}
                    valueField={"status"}
                    value={form["status"]}
                />
                <SearchDropDown
                    label="Source"
                    name="source"
                    placeholder="Select Lead Source"
                    inputSize="quarter"
                    renderResultItem={(source) => <>{source}</>}
                    dataList={sourceType || []}
                    searchCondition={(query: string) => (source: any) => {
                        return source.toLowerCase().includes(query.toLowerCase());
                    }}
                    onChange={onChange()}
                    callback={handleSelectSource}
                    validation={validationMessages.source}
                    valueField={"source"}
                    value={form["source"]}
                />
                {/* <Input
                    name="source"
                    label="Source"
                    Icon={<RiBroadcastLine />}
                    placeholder="Enter Lead Source"
                    onChange={onChange()}
                    validation={validationMessages.source}
                    inputSize="quarter"
                    value={form['source']}
                /> */}
            </FormRow>
            <div className="flex justify-end items-center gap-4">
                <SecondaryButton icon={<RiCloseLine />} title="Reset"
                    callback={handleReset}
                />
                <PrimaryButton
                    icon={<RiArrowRightCircleLine />}
                    title="Submit"
                    callback={handleSubmit}
                />
            </div>
        </form>
    )
}

export default LeadForm