import React, { Suspense, CSSProperties } from "react";
import { lazily } from "react-lazily";

// Type for the props of LottieDiv component
interface LottieDivProps {
  lottie: string;
  id?: string;
  className?: string;
  style?: CSSProperties;
  loop?: boolean;
  speed?: number;
}

// Lazily import Player component
const { Player } = lazily(() => import("@lottiefiles/react-lottie-player"));

// LottieDiv component in TypeScript
export default function LottieDiv({
  lottie,
  id = "",
  className = "",
  style = {},
  loop = true,
  speed = 1,
}: LottieDivProps): JSX.Element {
  return (
    <div id={`lottie-${id}`} className={className}>
      <Suspense fallback={<div style={{ width: 100, height: 100 }}></div>}>
        <Player
          autoplay
          loop={loop}
          src={lottie}
          style={{ height: "100%", width: "100%", ...style }}
          keepLastFrame={!loop}
          speed={speed}
        />
      </Suspense>
    </div>
  );
}
