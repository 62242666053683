import React, { useEffect, useState } from "react";
import { FormLoadingOverlay, FormRow, PrimaryButton, SecondaryButton } from "../../../Components";
import Input, { DateInput, SearchDropDown, SectionHeading } from "../../../Components/controls/Input";
import { RiArrowRightCircleLine, RiCloseLine, RiInputField, RiUserSettingsLine, RiVipDiamondLine } from "@remixicon/react";
import { REGEX } from "../../../Constants";
import useValidation, { ValidationConfig } from "../../../Hooks/useValidation";
import { handleChange } from "../../../Utils";
import { CouponCodeInterface, IUser, IUserInput } from "../../../Constants/Interfaces";
import useAPI from "../../../Hooks/useAPI";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Queries from "../../../GraphQl/Queries";

type Props = {};
interface CouponCodeInputInterface {
  couponId?: string;
  code: string;
  discountPer?: string;
  discountFlat?: string;
  description: string;
  upto: string;
  minOrderAmount: string;
  validTill: Date;
  useLimit: string;
  planId: string;
}

const initialForm: CouponCodeInputInterface = {
  couponId: '',
  code: '',
  discountPer: "",
  discountFlat: '',
  description: '',
  upto: '',
  minOrderAmount: '',
  validTill: new Date(),
  useLimit: '-1',
  planId: ''
};

const CouponCodeForm = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState<CouponCodeInputInterface>({ ...initialForm })
  const ValidationRules: ValidationConfig[] = [
    {
      input_path: "code",
      label_name: "Code",
      input_required: true,
      regex: REGEX.ANYTHING.regex,
      error_message: "Enter valid Code",
    },
    {
      input_path: "discountFlat",
      label_name: "Discount Falt",
      input_required: false,
      regex: REGEX.Integers.regex,
      error_message: "Enter valid Discount Flat",
    },
    {
      input_path: "discountPer",
      label_name: "Discount Per",
      input_required: false,
      regex: REGEX.Integers.regex,
      error_message: "Enter Valid Discount Per",
      customValidation(value, setValidationMssg) {
        if(Number(value) > 20){
          setValidationMssg('Discount Should not higher then 20.')
          return false
        }
        return true
      },
    },
    {
      input_path: "description",
      label_name: "description",
      input_required: true,
      regex: REGEX.ANYTHING.regex,
      error_message: "Enter Valid Description for Code",
    },
    {
      input_path: "upto",
      label_name: "Maximum Order Amount",
      input_required: true,
      regex: REGEX.Integers.regex,
      error_message: "Enter Valid Maximun Order Amount",
    },
    {
      input_path: "minOrderAmount",
      label_name: "Minimum Order Amount",
      input_required: true,
      regex: REGEX.Integers.regex,
      error_message: "Enter Valid Minimum Order Amount",
    },
    // {
    //   input_path: "validTill",
    //   label_name: "Validity",
    //   input_required: true,
    //   regex: REGEX.Date.regex,
    //   error_message: "Enter Valid Validity Date",
    // },
    {
      input_path: "useLimit",
      label_name: "Usage Limit",
      input_required: false,
      regex: REGEX.Integers.regex,
      error_message: "Enter Valid Usage Limit",
    },
    {
      input_path: "planId",
      label_name: "Plan",
      input_required: false,
      regex: REGEX.Integers.regex,
      error_message: "Select Valid Plan",
    },
  ];
  
  const Plans = useQuery(Queries.GET_ALL_PLANS)

  const couponeDetails = useAPI<{success:boolean,data:CouponCodeInterface,message:string}>({
    method:"GET",
    url:`/admin/coupons/${id}`,
    onCompleted({data,success,message}) {
        console.log("fetch data",data)
        if(success){
          setForm({
            couponId: String(data.id),
            code: data.code,
            discountPer: String( Number(data.discountPer) > 0 ? data.discountPer : ""),
            discountFlat: String( Number(data.discountFlat) > 0 ? data.discountFlat : ""),
            description: data.description,
            upto: String(data.upto),
            minOrderAmount: String(data.minOrderAmount),
            validTill: data.validTill,
            useLimit: String(data.useLimit),
            planId: data.plan.id
          })
        }else{
          toast.error(message)
        }
      },
  })

  const { execute, loading } = useAPI<{ success: boolean, message: string }>({
    method: "POST",
    url: "/admin/coupons",
    data: form,
    onCompleted: (data) => {
      if (data.success) {
        toast.success(data.message)
        // navigate("/coupons");
      } else {
        toast.error(data.message);
      }
    },
    onError: (err) => {
      toast.error("Failed to create coupone Code");
    }
  });
  const handleSelectPlan = (value: any) => {
    setForm({ ...form, planId: value.id })

  }
  const handleResetForm = () => {
    setForm({ ...initialForm })
  }
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
      if (triggerValidation()) {
        execute()
        navigate('/coupons')
      }
  }
  const { triggerValidation, validationMessages } =
    useValidation(form, ValidationRules);
  const onChange = handleChange(form, setForm);
  
  useEffect(() => {
    if (id) {
      couponeDetails.execute();
    }
  }, [])

  return (
    <form className="relative flex flex-col gap-3 p-8 rounded-xl bg-white w-11/12 mx-auto  mt-14 sm:mt-20 lg:mt-24">
      <FormLoadingOverlay loading={loading} />
      <h3>{id ? "Update Coupon" : "Add Coupon"}</h3>
      <SectionHeading heading="" />
      <FormRow gap={4}>
        <Input
          name="code"
          label="Code"
          placeholder="Enter Code"
          onChange={onChange()}
          validation={validationMessages["code"]}
          showRequired
          inputSize="quarter"
          value={form["code"]}
        />
        <Input
          name="discountPer"
          label="Discount Percentage"
          placeholder="Enter Discount Percentage"
          onChange={onChange()}
          validation={validationMessages["discountPer"]}
          inputSize="quarter"
          value={form["discountPer"] || ""}
        />
        <Input
          name="discountFlat"
          label="Discount Flat"
          placeholder="Enter Discount Flat Amount"
          onChange={onChange()}
          validation={validationMessages["discountFlat"]}
          inputSize="quarter"
          value={form["discountFlat"] || ""}
        />
        <Input
          name="description"
          label="Description"
          placeholder="Enter Description"
          onChange={onChange()}
          validation={validationMessages["description"]}
          showRequired
          inputSize="quarter"
          value={form["description"]}
        />
        <Input
          name="upto"
          label="Maximum Order Amount"
          placeholder="Enter Maximum Order Amount"
          onChange={onChange()}
          validation={validationMessages["upto"]}
          showRequired
          inputSize="quarter"
          value={form["upto"]}
        />
        <Input
          name="minOrderAmount"
          label="Minimum Order Amount"
          placeholder="Enter Minimum Order Amount"
          onChange={onChange()}
          validation={validationMessages["minOrderAmount"]}
          showRequired
          inputSize="quarter"
          value={form["minOrderAmount"]}
        />
        {/* <Input
          name="validTill"
          label="Validity"
          placeholder="Enter Valid Till Date"
          onChange={onChange()}
          validation={validationMessages["validTill"]}
          showRequired
          inputSize="quarter"
          value={form["validTill"]}
        /> */}

        {/* <DateInput 
        label={"Validity"} 
        name="validTill" 
        validation={validationMessages["validTill"]}
        placeholder="Enter Valid Till Date" 
        onChange={onChange}
        showRequired
        inputSize="quarter"
        stringValue={form["validTill"]}
        /> */}

        <Input
          name="useLimit"
          label="Usage Limit"
          placeholder="Enter Use Limit"
          onChange={onChange()}
          validation={validationMessages["useLimit"]}
          inputSize="quarter"
          value={form["useLimit"]}
        />
        <SearchDropDown
          Icon={<RiVipDiamondLine />}
          label="Plans"
          name="planId"
          placeholder="Select Plan"
          inputSize="quarter"
          renderResultItem={({ name }) => <>{name}</>}
          dataList={Plans.data?.getPlans?.plans || []}
          searchCondition={(query: string) => (data: any) => {
            return data.name.toLowerCase().includes(query.toLowerCase());
          }}
          callback={handleSelectPlan}
          validation={validationMessages["planId"]}
          valueField={"name"}
          value={Plans.data?.getPlans?.plans.find((plan: any) => Number(form["planId"]) === Number(plan.id))?.name || ""}
          defaultValue={Plans.data?.getPlans?.plans.find((plan: any) => Number(form["planId"]) === Number(plan.id))?.name || ""}
        />
      </FormRow>
      <div className="flex justify-end items-center gap-4">
        <SecondaryButton icon={<RiCloseLine />} title="Reset" callback={handleResetForm} />
        <PrimaryButton
          icon={<RiArrowRightCircleLine />}
          title="Submit"
          callback={handleSubmit}
        />
      </div>
    </form>
  );
};

export default CouponCodeForm;
