import React from 'react'
import Input, { SortDropdown } from './controls/Input';
import { debounce } from 'lodash';
import { RiRefreshLine, RiSearch2Line } from '@remixicon/react';
interface sortListOptions{
  label: string,
  value: string
}
interface sortingOrderHandlerProps{
  name: string|null,
  order: "ASC"|"DESC"|null
}

type Props = {
    searchHandler: (e:string)=>void;
    debounceTime: number;
    filter: React.ReactNode;
    sortingHandler: (e:sortingOrderHandlerProps)=>void;
    sortByList: sortListOptions[];
    exportHandler: (e:string)=>void;
    placeholder:string;
    resetPagination : ()=>void;
    loading?: boolean;
    refetch?:(param?:any)=>void;
    OtherButtons ?: React.ReactNode;
}


const FilterBar:React.FC<Props> = ({placeholder,searchHandler,debounceTime,filter,sortingHandler,sortByList,exportHandler, resetPagination,loading,refetch,OtherButtons}) => {
    
  // Debounce the onChange handler
  const debouncedOnChange = debounce((query: string) => {
    if (searchHandler) {
        searchHandler(query);
        resetPagination()
    }
  }, debounceTime);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    debouncedOnChange(query);
  };
  return (
    <div className="flex flex-col justify-between bg-foreground py-4 px-6 rounded-2xl">
    
    {/* SearchInput */}
    <div className="flex items-center justify-between">
      <div className="flex-1 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 "> 
      <Input label='' inputSize='half' Icon={<RiSearch2Line/>} placeholder={placeholder} onChange={handleInputChange} />    
      </div>
    
    {/* Buttons */}
    <div className="flex space-x-2 pt-2 gap-2">
        <button onClick={refetch} className={`flex items-center justify-center text-blue-300 hover:text-primary transition-all ease-in-out ${loading?"animate-spin":""}`}><RiRefreshLine/></button>
        {OtherButtons}
        {filter}
        <SortDropdown label='Sort By' onSelect={sortingHandler} options={sortByList} />
    </div>
    </div>
  </div>
  )
}

export default FilterBar