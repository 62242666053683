import { createSlice } from "@reduxjs/toolkit";
import {
  AdditionalCharges,
  Address,
  DisitributionStatus,
  GodownDistribution,
  InventoryUnitsInterface,
  ShopDistribution,
} from "../../Constants/Interfaces";
import { CalculateBill } from "../../Utils";

interface PurchaseReturn {
  purchaseReturnNumberId: string;
  purchaseId: string;
  orgId: string;
  vendorId: string;
  amount: number;
  refundAmount: number;
  paymentMode: string;
  products: PurchaseReturnItems[];
  termsAndCondition: string;
  additionalCharges: AdditionalCharges[];
  remark: string;
}
interface PurchaseReturnItems {
  productId: string;
  name: string;
  price: number;
  tax: number;
  quantity: number;
  unit?: string;
  maxQuantity?: number;
  batchId: string;
  inventoryUnitId:string|null;
  inventoryUnit?: InventoryUnitsInterface ; 
  godownDistribution?: GodownDistribution[];
  shopDistribution?: ShopDistribution;
  status?: DisitributionStatus;
}

interface InitialState {
  purchaseReturnNumber: string;
  form: PurchaseReturn;
  orignalAmount: number;
  subTotal: number;
  totalTax: number;
  activePurchaseReturnItem: number;
  purchase?: {
    purchaseId: string;
    invoiceId: string;
    orgId: string;
    vendor: {
      id: string;
      name: string;
      billAddress: Address;
    };
    amount: number;
    paidAmount: number;
    refundAmount: number;
    paymentMode: string;
    products: PurchaseReturnItems[];
    termsAndCondition: string;
    additionalCharges: AdditionalCharges[];
  } | null;
  initialProducts: PurchaseReturnItems[];
}

const initialState: InitialState = {
  purchaseReturnNumber: "",
  form: {
    purchaseReturnNumberId: "",
    purchaseId: "",
    amount: 0,
    orgId: "",
    refundAmount: 0,
    paymentMode: "",
    products: [],
    vendorId: "",
    termsAndCondition: "",
    additionalCharges: [],
    remark: "",
  },
  orignalAmount: 0,
  subTotal: 0,
  totalTax: 0,
  activePurchaseReturnItem: -1,
  purchase: {
    vendor: {
      id: "",
      name: "",
      billAddress: {
        addressLine: "",
        city: "",
        pincode: "",
        state: "",
      },
    },
    amount: 0,
    paidAmount: 0,
    additionalCharges: [],
    invoiceId: "",
    orgId: "",
    paymentMode: "",
    products: [],
    refundAmount: 0,
    purchaseId: "",
    termsAndCondition: "",
  },
  initialProducts: [],
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setPurchaseReturnNumber: (state, action) => {
      state.form.purchaseReturnNumberId = action.payload.purchaseReturnNumberId;
      state.purchaseReturnNumber = action.payload.purchaseReturnNumber;
    },
    setPurchaseDetais: (state, action) => {
      state.purchase = action.payload;
    },
    setPurchaseReturnPaymentMode: (state, action) => {
      state.form.paymentMode = action.payload;
    },
    setPurchaseRefundAmount: (state, action) => {
      if (state.form.amount >= action.payload && action.payload >= 0) {
        state.form.refundAmount = action.payload;
      }
    },
    setInitialProductList: (state, action) => {
      state.form.products = action.payload.map((product: any) => {
        console.log({ product });
        return { ...product, maxQuantity: product.quantity };
      });
      state.initialProducts = action.payload;
      const { amount, subTotal, totalTax } = CalculateBill(state.form.products);
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => parseInt(String(acc)) + parseInt(String(value.charges)),
        0
      );
      //Orignal Amount stores calculated purchase amount without any additional charges
      state.orignalAmount = Math.round(amount);
      //add additinal charge to the orignal amount prevents from double adding the same charge
      state.form.amount =
        Math.round(state.orignalAmount) + totalAdditionalCharges;
      state.subTotal = Math.round(subTotal);
      state.totalTax = Math.round(totalTax);
    },
    addReturnProduct: (state, action) => {
      state.activePurchaseReturnItem = -1;
      if (action.payload.quantity > 0) {
        const { exist, index } = itemExists(
          state.form.products,
          action.payload.batchId,
          "batchId"
        );
        if (exist && index !== null) {
          const product = { ...action.payload };
          product.maxQuantity = product.quantity;
          state.form.products[index] = product;
        } else {
          const product = { ...action.payload };
          product.maxQuantity = product.quantity;
          state.form.products.push(product);
        }
        const { amount, subTotal, totalTax } = CalculateBill(
          state.form.products
        );
        const totalAdditionalCharges = state.form.additionalCharges.reduce(
          (acc, value) =>
            parseInt(String(acc)) + parseInt(String(value.charges)),
          0
        );
        //Orignal Amount stores calculated purchase amount without any additional charges
        state.orignalAmount = Math.round(amount);
        //add additinal charge to the orignal amount prevents from double adding the same charge
        state.form.amount =
          Math.round(state.orignalAmount) + totalAdditionalCharges;
        state.subTotal = Math.round(subTotal);
        state.totalTax = Math.round(totalTax);
      }
    },
    addAdditionalCharges: (state, action) => {
      const index = state.form.additionalCharges.findIndex(
        (value) => value.name === action.payload.name
      );
      if (index === -1) {
        state.form.additionalCharges.push(action.payload);
      } else {
        state.form.additionalCharges[index] = action.payload;
      }
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => parseInt(String(acc)) + parseInt(String(value.charges)),
        0
      );
      state.form.amount = state.orignalAmount + totalAdditionalCharges;
    },
    removeAdditionalCharges: (state, action) => {
      state.form.additionalCharges = state.form.additionalCharges.filter(
        (value, i) => i !== action.payload
      );
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => acc + value.charges,
        0
      );
      state.form.amount = state.orignalAmount + totalAdditionalCharges;
    },
    setPurchaseReturnRemark: (state, action) => {
      state.form.remark = action.payload;
    },

    deleteReturnProduct: (state, action) => {
      state.form.products = state.form.products.filter(
        (item) => item.productId !== action.payload
      );
      const { amount, subTotal, totalTax } = CalculateBill(state.form.products);
      state.form.amount = amount;
      state.subTotal = subTotal;
      state.totalTax = totalTax;
    },
    setProductQuantity: (state, action) => {
      const { index, quantity } = action.payload;
      const temp = { ...state.form.products[index] };
      temp.quantity = quantity;
      state.form.products[index] = temp;
      const { amount, subTotal, totalTax } = CalculateBill(state.form.products);
      const totalAdditionalCharges = state.form.additionalCharges.reduce(
        (acc, value) => parseInt(String(acc)) + parseInt(String(value.charges)),
        0
      );
      //Orignal Amount stores calculated purchase amount without any additional charges
      state.orignalAmount = Math.round(amount);
      //add additinal charge to the orignal amount prevents from double adding the same charge
      state.form.amount =
        Math.round(state.orignalAmount) + totalAdditionalCharges;
      state.subTotal = Math.round(subTotal);
      state.totalTax = Math.round(totalTax);
    },
    setProductPrice: (state, action) => {
      const { index, price } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          products: state.form.products.map((product, i) =>
            i === index ? { ...product, price } : product
          ),
        },
      };
    },
    addReturnDistribution: (state, action) => {
      state.form.products[state.activePurchaseReturnItem].shopDistribution =
        action.payload.shopDistribution;
      state.form.products[state.activePurchaseReturnItem].godownDistribution =
        action.payload.godownDistribution;
      state.activePurchaseReturnItem = 1;
    },
    autoAddReturnDistribution: (state, action) => {
      const { index, shopDistribution, godownDistribution, status } =
        action.payload;
      state.form.products[index].shopDistribution = shopDistribution;
      state.form.products[index].godownDistribution = godownDistribution;
      state.form.products[index].status = status;
    },
    setActivePurchaseReturnItem: (state, action) => {
      state.activePurchaseReturnItem = action.payload;
    },
    setPaymentMode: (state, action) => {
      state.form.paymentMode = action.payload;
    },
    setPaidAmount: (state, action) => {
      if (action.payload === "") {
        state.form.refundAmount = 0;
      } else if (action.payload > 0 && action.payload <= state.form.amount) {
        state.form.refundAmount = parseInt(action.payload);
      }
    },
    setPurchaseReturnTerms: (state, action) => {
      state.form.termsAndCondition = action.payload.replace(/\n/g, "\\n");
    },
    resetPurchaseReturnItem: (state, action) => {
      state.form.products = [];
      state.form.amount = 0;
      state.subTotal = 0;
      state.totalTax = 0;
    },
    resetPurchasePaymentDetails: (state, action) => {
      state.form.refundAmount = 0;
      state.form.paymentMode = "";
    },
    resetPurchaseReturnForm: (state, action) => {
      state.purchaseReturnNumber = "";
      state.form = initialState.form;
      state.activePurchaseReturnItem = initialState.activePurchaseReturnItem;
      state.subTotal = initialState.subTotal;
      state.totalTax = initialState.totalTax;
    },
  },
});

export const selectPurchaseForm = (state: any) => state.purchaseReturn.form;
export const selectPurchaseReturnSummary = (state: any) => {
  return {
    products: state.purchaseReturn.form.products.length,
    amount: state.purchaseReturn.form.amount,
    subTotal: state.purchaseReturn.subTotal,
    totalTax: state.purchaseReturn.totalTax,
    totalDiscount: state.purchaseReturn.totalDiscount,
    refundAmount: state.purchaseReturn.form.refundAmount,
  };
};
export const selectPurchaseReturnNumber = (state: any) =>
  state.purchaseReturn.purchaseReturnNumber;
export const selectPurchaseDetails = (state: any) =>
  state.purchaseReturn.purchase;
export const selectPurchaseReturn = (state: any) => state.purchaseReturn.form;
export const selectInitialProductList = (state: any) =>
  state.purchaseReturn.initialProducts;
export const selectPurchaseReturnItem = (state: any) =>
  state.purchaseReturn.form.products;
export const selectActivePurchaseReturnItem = (state: any) =>
  state?.purchaseReturn?.form?.products[
    state.purchaseReturn.activePurchaseReturnItem
  ];
export const selectAdditionalCharges = (state: any) =>
  state.purchaseReturn.form.additionalCharges;
export const selectPurchaseTerms = (state: any) =>
  state.purchaseReturn.form.termsAndCondition;
export const selectPurchaseReturnPaymentMode = (state: any) =>
  state.purchaseReturn.form.paymentMode;
export const selectPurchaseRefundAmount = (state: any) =>
  state.purchaseReturn.form.refundAmount;
export const {
  setPurchaseReturnNumber,
  setPurchaseDetais,
  addReturnDistribution,
  setInitialProductList,
  addReturnProduct,
  addAdditionalCharges,
  removeAdditionalCharges,
  deleteReturnProduct,
  setProductQuantity,
  setProductPrice,
  setActivePurchaseReturnItem,
  setPurchaseReturnPaymentMode,
  resetPurchaseReturnItem,
  setPaymentMode,
  setPaidAmount,
  resetPurchasePaymentDetails,
  resetPurchaseReturnForm,
  setPurchaseReturnTerms,
  autoAddReturnDistribution,
  setPurchaseRefundAmount,
  setPurchaseReturnRemark,
} = purchaseSlice.actions;
export default purchaseSlice.reducer;

function itemExists(arr: any[], value: string, field: string) {
  let flag: {
    exist: boolean;
    index: null | number;
  } = {
    exist: false,
    index: null,
  };
  arr.forEach((element: any, index) => {
    if (element[field] === value) {
      flag.exist = true;
      flag.index = index;
    }
  });
  return flag;
}
