import { RiArrowLeftCircleLine, RiEdit2Line } from "@remixicon/react";
import { SecondaryLinkButton } from "../../../Components";
import { SectionHeading } from "../../../Components/controls/Input";
import { useNavigate, useParams } from "react-router-dom";
import useAPI from "../../../Hooks/useAPI";
import { useEffect } from "react";
import { PlanOrderInterface, RewardTrackerInterface } from "../../../Constants/Interfaces";
import { toast } from "react-toastify";
import moment from "moment";


interface planOrderWithCouponCodeInterface extends PlanOrderInterface {
    couponCode: {
        id:number;
        code: string
    }
}

interface RewardWithPlanOrderAndCouponCodeIntercae extends RewardTrackerInterface {
    planOrder?: planOrderWithCouponCodeInterface,
}

function RewardDeatils() {

    const { id } = useParams();
    const navigate = useNavigate()
    const { data, execute } = useAPI<{ message: string, success: boolean, data: RewardWithPlanOrderAndCouponCodeIntercae }>({
        method: "GET",
        url: `/rewards/${id}`,
        onCompleted: ({ message, success }) => {
            if (!success) {
                toast.error(message)
            }
            toast.success(message)
        },
        onError: (err: any) => {
            toast.error(err)
        }
    });


    useEffect(() => {
        execute();
    }, []);

    const {
        dealer,
        planOrder,
        reward,
        bonus
    } = data?.data || {}
    return (
        <div className='w-11/12 mx-auto rounded-xl bg-foreground flex flex-col space-y-5  p-10 lg:p-5'>
            {/* <div style={{ width: "96%" }} className='mx-auto  '> */}
            {/* Reward Heading*/}
            <div className=' w-full text-center sm:grid sm:grid-cols-3'>
                <button className=' hidden sm:block hover:text-secondary'>
                    <RiArrowLeftCircleLine />
                </button>
                <div className=' flex justify-center items-center space-x-2'>
                    <span className='text-3xl text-primary font-bold'>{"Reward"}</span>
                    <button role='button' className=' sm:hidden text-xm text-disabled hover:text-secondary'><RiEdit2Line /></button>
                </div>
                <span className=' hidden sm:flex w-full justify-end'>
                    <SecondaryLinkButton
                        to={`/rewards/update/${id}`}
                        title="Edit"
                        icon={<RiEdit2Line />}
                    />
                </span>
            </div>
            {/* Reward Deatils  */}
            <div className='flex flex-col space-y-1'>
                <SectionHeading heading="Reward Details" />
                <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Reward:</span>
                        <span className='  '>{reward}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Bonus.:</span>
                        <span className='  '>{bonus}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Coupon Code.:</span>
                        <span className='  '> <button onClick={()=> planOrder?.couponCode?.id ?navigate(`/coupons/details/${planOrder?.couponCode?.id}`) : "#"}>{planOrder?.couponCode?.code || "Not Applied"}</button></span>
                    </div>
                </div>
            </div>
            {/*Dealer*/}
            <div className='flex flex-col space-y-1'>
                <SectionHeading heading="Dealer" />
                <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Name:</span>
                        <span className='  '>{dealer?.user.name}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Mobile No.:</span>
                        <span className='  '>{dealer?.user.mobile}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Email Address:</span>
                        <span className='  '>{dealer?.user.email || "Not Given"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Assign Area:</span>
                        <span className='  '>{dealer?.area || "Not Given"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Organization Name:</span>
                        <span className='  '>{dealer?.organizationName || "Not Given"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>GSTIN:</span>
                        <span className='  '>{dealer?.gstin || "Not Given"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Active:</span>
                        <span className='  '>{dealer?.isActive ? "Active" : "Deactivated"}</span>
                    </div>
                </div>
            </div>
            {/* PlanOrder */}
            <div className=' flex flex-col space-y-1'>
                <SectionHeading heading="Plan Order" />
                <div className=' grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 '>
                    <div className=' flex flex-row sm:sm:space-x-1'>
                        <span className=' text-disabled  '>Order ID:</span>
                        <span className='  '> <button className=" text-primary" onClick={() => navigate(`/orders/details/${planOrder?.id}`)} >{planOrder?.orderId}</button></span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Payment:</span>
                        <span className='  '>{planOrder?.isPaid ? "Done" : "Pending"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Amount:</span>
                        <span className='  '>{planOrder?.amount ? planOrder.amount : "0"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Tax:</span>
                        <span className='  '>{planOrder?.tax ? planOrder.tax : "0"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Discount:</span>
                        <span className='  '>{planOrder?.discount ? planOrder.discount : "0"}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Receipt:</span>
                        <span className='  '>{planOrder?.receipt}</span>
                    </div>
                    <div className=' flex flex-row sm:space-x-1'>
                        <span className=' text-disabled  '>Order Date:</span>
                        <span className='  '>{moment(planOrder?.dateCreated).format('MMMM Do YYYY, h:mm:ss a')}</span>    </div>
                </div>
            </div>
            {/* </div> */}
        </div >
    )

};

export default RewardDeatils;