import axios from "axios";
import { store } from "../Redux/store"; // Import your Redux store

interface APIInterface{
  signal?: any
}
const API = (props?:APIInterface) => {
  const organization:any = store.getState().organization;
  const orgid = organization.organizations?.[organization.selected || 0]?.id
  return axios.create({
    baseURL: process.env.REACT_APP_URI, // Your API base URL
    timeout: 10000, // Request timeout
    headers: {
      "Content-Type": "application/json",
      // You can add other common headers here
      token: "bearer " + store.getState().auth.token,
      orgid: orgid,
      signal: props?.signal
    },
  });
};

export default API;
