import { RiDeleteBin2Line, RiEditLine, RiMoneyRupeeCircleLine, RiUser2Line, RiUserAddLine, RiUserLine } from "@remixicon/react";
import {
  FilterBar,
  Pagination,
  PrimaryButton,
  SecondaryLinkButton,
  StatusTag,
} from "../../../Components";
import ActionButton from "../../../Components/ActionButton";
import { useTable } from "../../../Hooks";
import useAPI from "../../../Hooks/useAPI";
import { FormatAmount, GetFormattedDate } from "../../../Utils";
import { DealerInterface, IUser, Page, PlanOrderInterface, RewardTrackerInterface } from "../../../Constants/Interfaces";
import { useEffect, useState } from "react";
import { PrimaryLinkButton } from "../../../Components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


interface RewardWithPlanOrderAndInterface extends RewardTrackerInterface {
  planOrder?: PlanOrderInterface,
}

interface RewardInput {
  id: number;
  dealerId: number;
  reward: number;
  bonus: number;
  isIssued: boolean;
}

const RewardIndex = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Page>({
    totalRecords: 0,
    skip: 0,
    take: 10,
  });

  const updateReward = useAPI<{
    message: string;
    success: boolean;
  }>({
    method: "PUT",
    url: "/admin/rewards",
    onCompleted: (data) => {
      if (!data.success) {
        toast.error(data.message)
      }
    },
    onError: (err: any) => {
      toast.error(err)
    }
  });

  const { data, error, execute, loading, refetch } = useAPI<{ message: string, success: boolean, data: RewardWithPlanOrderAndInterface[], totalRecords: number }>({
    method: "GET",
    url: "/admin/rewards",
    onCompleted: (data) => {
      if (data.success) {
        setPage({ ...page, totalRecords: data.totalRecords })
      } else {
        toast.error(data.message)
      }
    }
  });

  const markAsPaid = (reward: RewardTrackerInterface) => {

    updateReward.execute({
      data: {
        dealerId: reward.dealer.id,
        reward: reward.reward,
        bonus: reward.bonus,
        isIssued: true
      },
      url:`/admin/rewards/${reward.id}`
    })
    navigate('/rewards')
  }

  const columns = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Plan Order Id",
      accessor: "orderId",
      render: ({ planOrder }: { planOrder: PlanOrderInterface }) => String(planOrder.orderId)
    },
    {
      header: "Dealer",
      accessor: "dealer.user.name",
      render: ({ dealer }: { dealer: DealerInterface }) => String(dealer.user.name),
    },
    {
      header: "Reward",
      accessor: "reward",
    },
    {
      header: "Bonus",
      accessor: "bonus",
    },
    {
      header: "Amount",
      accessor: "planOrder.amount",
      render: ({ planOrder }: { planOrder: PlanOrderInterface }) => String(planOrder.amount || 0)
    },
    {
      header: "Paid",
      accessor: "isIssued",
      render: ({isIssued}: {isIssued:boolean}) => (
        <StatusTag
          label={
            isIssued ? "Paid" : "Not Yet"
          }
          status={
            isIssued
              ? "success"
              : "warning"
          }
        />
      ),
    },
    {
      header: "Action",
      accessor: "action",
      render: (data: any) => {
        return (
          <ActionButton
            align="left"
            options={[
              {
                label: "Update",
                icon: <RiEditLine />,
                callback: () => {
                  navigate(`/rewards/update/${data.id}`)
                },
              },
              {
                label: "Make it Paid",
                icon: <RiMoneyRupeeCircleLine />,
                callback: () => {
                  // updateReward.execute();
                  markAsPaid(data)
                },
              },
              {
                label: "Delete",
                icon: <RiDeleteBin2Line />,
                callback: () => {
                  //setShowDeleteModal(data.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const { Table } = useTable({
    columns,
    onRowClick: (data) => {
      console.log({ data });
      navigate(`/rewards/details/${data.id}`);
    },
  });
  useEffect(() => {
    execute({
      url: `/admin/rewards?query=${query}&skip=${page.skip}&take=${page.take}`,
    })
  }, [query, page.skip, page.take]);

  return (
    <div className="flex-col gap-4 w-full flex">
      <FilterBar
        exportHandler={() => { }}
        filter={<></>}
        placeholder="Search Dealer Name, Mobile,Order ID"
        searchHandler={(e) => setQuery(e)}
        debounceTime={500}
        sortByList={[
          { label: "Name", value: "name" },
          { label: "Mobile", value: "brand" },
          { label: "Email", value: "mrp" },
          { label: "Verified", value: "mobileVerified" },
        ]}
        sortingHandler={({ name, order }) => {
          //setSort({ name, orderby: order });
        }}
        resetPagination={() => {
          //setPage({ ...page, skip: 0 })
        }}
        loading={loading}
        refetch={refetch}
      />
      <Table height="80vh" loading={loading} rows={data?.data || []} />
      <div className="bg-white w-full flex flex-wrap p-2 rounded-lg">
        {/* <PrimaryLinkButton
          to={"/dealers/add"}
          title="Add Dealer"
          icon={<RiUser2Line size={18} />}
        /> */}
        <Pagination page={page} onPageChange={setPage} />
      </div>
    </div>
  );
};

export default RewardIndex;
