interface intervalInterface {
  label: string;
  value: string | number;
}
interface IntervalSelectorProps {
  activeInterval: number;
  setActiveInterval: (e: number) => void;
  intervals: intervalInterface[];
}
const IntervalSelector: React.FC<IntervalSelectorProps> = ({
  intervals,
  activeInterval,
  setActiveInterval,
}) => {
  const handleSelect =
    (value: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      setActiveInterval(value);
    };
  const width = `${Math.round(100 / intervals.length)}%`;
  return (
    <div className="relative w-fit bg-foreground rounded-lg mx-auto p-1 gap-1 flex">
      {intervals.map((interval, index) => (
        <IntervalSelectorButton
          key={interval.label}
          width={width}
          label={interval.label}
          active={(intervals?.[activeInterval]?.value || "") === interval.value}
          onSelect={handleSelect(index)}
        />
      ))}
      <div
        style={{
          width: width,
          transform: ActiveButtonSlider(activeInterval, intervals.length),
        }}
        className={`absolute top-0 left-0  transition-all ease-in-out  h-full p-1`}
      >
        <div className="bg-secondary h-full w-full rounded"></div>
      </div>
    </div>
  );
};

interface IntervalSelectorButtonProps {
  width: string;
  active?: boolean;
  label: string;
  onSelect: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const IntervalSelectorButton: React.FC<IntervalSelectorButtonProps> = ({
  width,
  label,
  active,
  onSelect,
}) => {
  return (
    <button
      onClick={onSelect}
      style={{ width: width }}
      className={`z-10 px-4 py-2  ${
        active ? "text-white" : ""
      } rounded text-base font-medium transition-all ease-in`}
    >
      {label}
    </button>
  );
};
function ActiveButtonSlider(index: number, length: number) {
  return `translateX(${index}00%)`;
}
export default IntervalSelector;
