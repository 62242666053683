interface item{
    price: number,
    quantity: number,
    tax: number,
    discount?:number,
}
interface Result{
    amount: number;
    subTotal: number;
    totalDiscount: number;
    totalTax: number;
}
export default  function CalculateBill(list:item[]):Result{
  return  list.reduce((acc,curr)=>calculation(acc,curr) , {
        amount:0,
        subTotal: 0,
        totalDiscount:0,
        totalTax:0,
    })
}

function calculation({amount,subTotal,totalDiscount,totalTax}:Result,{price,quantity,tax,discount=0}:item){
    return {
        amount : amount + CalculateAmount(price,quantity,discount,tax),
        subTotal : subTotal + price*quantity,
        totalDiscount: totalDiscount +( CalculateDiscount(price,discount)*quantity),
        totalTax:totalTax + CalculateTax(price,tax)*quantity
    }
}

function CalculateTax(price:number,tax:number):number{
    return  price * tax/100
}
function CalculateDiscount(price:number,discount:number=0):number{
    return price * discount/100
}
export function CalculateAmount(price:number,quantity:number,discount:number,tax:number):number{
    return (price - CalculateDiscount(price,discount)  + CalculateTax(price,tax))*quantity
}